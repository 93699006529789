import { computed, Ref, unref } from '@vue/composition-api';
import { MaybeRef } from '@vueuse/core';
import { User } from '@ax/data-services-user/models/user';
import { usePermissionsForUserAndOrg } from '@ax/data-services-authorization/compositions/usePermissions';
import { OrgId } from '@/models/org-id';

/**
 * @deprecated instead use:
 *
 * `import { usePermissionsForUserAndOrg } from '@ax/data-services-authorization/compositions/usePermissions';`
 * @param user `Ref<User>`
 * @param orgId `MaybeRef<OrgId>`
 * @returns `canAccess`
 */
export default function usePermissions(
  user: Ref<User>,
  orgId: MaybeRef<OrgId>,
) {
  const orgIdNumber = computed(() => {
    const currentOrgId = unref(orgId);
    return typeof currentOrgId === 'string'
      ? parseInt(currentOrgId, 10)
      : currentOrgId;
  });
  const { useCanAccess } = usePermissionsForUserAndOrg(orgIdNumber, user);
  return { canAccess: useCanAccess };
}
