import { Request } from '@ax/function-utils';
import { success, failure, Response } from '@ax/type-utils';
import { configClient } from '../clients/config.client';
import { Config, ConfigDTO } from '../models/config';

/**
 * The url to use for requesting the config
 */
const url = `/shared/js/config`;

/**
 * map a `ConfigDTO` into a `Config`
 * @param data `ConfigDTO`
 * @returns `Config`
 */
function toConfig(data: ConfigDTO): Config {
  const {
    AUTOMOX_COUNTRIES,
    AUTOMOX_CONFIG: { environment, services, session },
  } = data;

  const countries = Object.keys(AUTOMOX_COUNTRIES).map((key) => ({
    name: data.AUTOMOX_COUNTRIES[key],
    code: key,
  }));

  return {
    auth0: services.auth0,
    countries,
    environment,
    marketing: services.automox_marketing,
    segment: services.segment,
    stripe: services.stripe,
    launch_darkly: services.launch_darkly,
    datadog: services.datadog,
    remote_control: services.remote_control,
    mdm: services.mdm,
    session,
  };
}

/**
 * Makes a request to get the config and returns a `Promise` that can succeed
 * with type `Config` or fail with type `unknown.
 *
 * @returns `Response<Config, unknown>`
 */
function getConfigAsResponseImpl(): Promise<Response<Config, unknown>> {
  return configClient
    .get<ConfigDTO>(url, {
      params: { json: true },
    })
    .then(({ data }) => success(toConfig(data)))
    .catch((e) => failure(e));
}

/**
 * Caches and deduplicates requests made to `getConfigAsResponseImpl`.
 */
export const getConfigAsResponse = Request.cacheOnSuccess(
  Request.deduplicate(getConfigAsResponseImpl),
);

/**
 * Will throw the error in the response returned from `getConfigAsResponse`, if present.
 * All existing code in the console expects an implementation that does return a `Response` so
 * this was done for backward compatibility.
 */
export const getConfig = Request.extractSuccessUnsafe(getConfigAsResponse);
