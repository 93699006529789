import { Nullable, Response } from '@ax/type-utils';
import { isNonNullable } from '../nullable';
import { Request } from './definition';

/**
 * Use to create a `Request<void, Succ, Err>` that will allow at most one request
 * to be inflight at any given time.
 * @param request
 * @returns `Request<void, Succ, Err>`
 */
export function deduplicate<Succ, Err>(
  request: Request<void, Succ, Err>,
): Request<void, Succ, Err> {
  let outstandingRequest: Nullable<Promise<Response<Succ, Err>>>;
  return (input) => {
    if (isNonNullable(outstandingRequest)) {
      return outstandingRequest;
    }
    outstandingRequest = request(input).finally(() => {
      outstandingRequest = undefined;
    });
    return outstandingRequest;
  };
}
