import { Response } from '@ax/type-utils';
import { Request } from './definition';

/**
 * Use `flatMap` to continue a sequence of requests
 * @param andThen A function to continue the computation with assuming a successful result
 * @returns Promise<Response<A1, E | E1>>
 */
export function flatMap<A, A1, E1>(andThen: Request<A, A1, E1>) {
  return <E>(response: Response<A, E>): Promise<Response<A1, E | E1>> =>
    response.type === 'Success'
      ? andThen(response.success)
      : Promise.resolve(response);
}
