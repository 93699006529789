import {
  DEFAULT_CHECKBOX_DEBOUNCE_TIME_TABLE_FILTER as NEW_DEFAULT_CHECKBOX_DEBOUNCE_TIME_TABLE_FILTER,
  TABLE_ITEMS_PER_PAGE as NEW_TABLE_ITEMS_PER_PAGE,
  DEFAULT_TABLE_PAGINATION as NEW_DEFAULT_TABLE_PAGINATION,
} from '@ax/cosmos/components/Table/table.models';

export const CONFIRM_UNSAVED_CHANGES =
  'You are about to leave with unsaved changes. Are you sure?';

/** @deprecated DON'T USE/CHANGE, instead use:
 * import {DEFAULT_TABLE_PAGINATION} from '@ax/cosmos/components/Table/table.models';
 */
export const DEFAULT_TABLE_PAGINATION = NEW_DEFAULT_TABLE_PAGINATION;
/** @deprecated DON'T USE/CHANGE, instead use:
 * import {TABLE_ITEMS_PER_PAGE} from '@ax/cosmos/components/Table/table.models';
 */
export const TABLE_ITEMS_PER_PAGE = NEW_TABLE_ITEMS_PER_PAGE;
/**
 * @deprecated DON'T USE/CHANGE, instead use:
 * import {DEFAULT_CHECKBOX_DEBOUNCE_TIME_TABLE_FILTER} from '@ax/cosmos/components/Table/table.models';
 * */
export const DEFAULT_CHECKBOX_DEBOUNCE_TIME =
  NEW_DEFAULT_CHECKBOX_DEBOUNCE_TIME_TABLE_FILTER;

const SWATCH_PALETTE_AX = [
  '#CC6666',
  '#F65C23',
  '#D78500',
  '#E9FF03',
  '#059F1D',
  '#BD0FE1',
  '#FFA4C0',
  // '#FFFFFF',
  // '#61666F',
];

export const SWATCH_PALETTE = [
  [
    '#000000',
    '#434343',
    '#61666F',
    '#999999',
    '#b7b7b7',
    '#d9d9d9',
    '#efefef',
    '#f3f3f3',
    '#ffffff',
  ],
  [
    '#cc0000',
    '#e69138',
    '#f1c232',
    '#6aa84f',
    '#45818e',
    '#3c78d8',
    '#3d85c6',
    '#674ea7',
    '#a64d79',
  ],
  [
    '#ff0000',
    '#ff9900',
    '#ffff00',
    '#00ff00',
    '#00ffff',
    '#4a86e8',
    '#0000ff',
    '#9900ff',
    '#ff00ff',
  ],
  [
    '#ea9999',
    '#f9cb9c',
    '#ffe599',
    '#b6d7a8',
    '#a2c4c9',
    '#a4c2f4',
    '#9fc5e8',
    '#b4a7d6',
    '#d5a6bd',
  ],
  [...SWATCH_PALETTE_AX],
];

export const VALID_POLICY_FILE_NAME_PATTERN = /^[a-zA-Z0-9._~-]+$/;

export const activityEventTypes = [
  'system.policy.action',
  'system.patch.applied',
  'system.patch.failed',
];

export const osFamilyList = ['Windows', 'Mac', 'Linux'];

export const defaultMaxNumberOfDeferrals = 3;

export const utcTimes = [
  'UTC-1200',
  'UTC-1100',
  'UTC-1000',
  'UTC-0930',
  'UTC-0900',
  'UTC-0800',
  'UTC-0700',
  'UTC-0600',
  'UTC-0500',
  'UTC-0400',
  'UTC-0330',
  'UTC-0300',
  'UTC-0200',
  'UTC-0100',
  'UTC+0000',
  'UTC+0100',
  'UTC+0200',
  'UTC+0300',
  'UTC+0330',
  'UTC+0400',
  'UTC+0430',
  'UTC+0500',
  'UTC+0530',
  'UTC+0545',
  'UTC+0600',
  'UTC+0630',
  'UTC+0700',
  'UTC+0800',
  'UTC+0845',
  'UTC+0900',
  'UTC+0930',
  'UTC+1000',
  'UTC+1030',
  'UTC+1100',
  'UTC+1200',
  'UTC+1245',
  'UTC+1300',
  'UTC+1400',
];
