import { NavigationGuardNext, RawLocation, Route } from 'vue-router';

import { getFlagValue } from '@ax/feature-flag';
import { SegmentAnalytics } from '@ax/data-services-tracking/analytics';
import { FlagKeys } from '@/models/feature-flags';

/**
 * Guards a route based on the status of a feature flag. This allows for routes to be added which
 * can only be accessed when the flag has been enabled, or for existing routes to be disabled upon
 * deprecation in favor of a different route.
 *
 * @param to The route that's being navigated to.
 * @param next Function to call for setting the next route.
 * @param flagName The feature flag name to check.
 * @param redirectPath The path to navigate to if the flag is not in the desired state.
 * @param disableRouteWhenFlagEnabled If set to true, the route will be redirected when the
 *  flag is enabled (deprecation of a route use case). Otherwise, the route will be redirected
 *  when the flag is disabled.
 */
export function guardRouteWithFlag(
  to: Route,
  next: NavigationGuardNext<Vue>,
  flagName: FlagKeys,
  redirectPath: RawLocation | string,
  disableRouteWhenFlagEnabled = false,
): void {
  getFlagValue(flagName).then((flagEnabled) => {
    if (disableRouteWhenFlagEnabled ? flagEnabled : !flagEnabled) {
      next(redirectPath);
    } else {
      next();
    }
  });
}

/** Detect and track possibly bookmarked routes that contain deprecated routes */
export function trackDeprecatedRoute(to: Route) {
  SegmentAnalytics.track('Deprecated Route Accessed', {
    fullPath: to.fullPath,
  });
}

export function redirectTo404AfterAuth0(
  flagKey: FlagKeys.auth0Milestone1 | FlagKeys.auth0Milestone2,
) {
  return (to: Route, _: Route, next: NavigationGuardNext) => {
    guardRouteWithFlag(to, next, flagKey, { name: '404' }, true);
  };
}

/**
 * Creates a route guard for redirecting to a different page when the org changes.
 * This should be used in beforeRouteUpdate at the component level since beforeEach
 * doesn't evaluate on query parameter changes. This route guard can be used for
 * components that render in AppLayout, since that layout remounts components on orgId
 * changes which prevents watch(orgId) from firing.
 *
 * @param redirectPath The path to redirect to.
 */
export function redirectOnOrgChange(redirectPath: RawLocation) {
  return (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
    if (to.query?.o !== from.query?.o) {
      next(redirectPath);
    }
    next();
  };
}
