import { AxiosResponse, AxiosRequestConfig } from 'axios';
import { consoleEnv } from '@ax/console-env';
import { User } from '@ax/data-services-user/models/user';
import { stringifyUrlQuery } from '@/utils/util';

import { UserTfaStatus } from '@/models/tfa';

import { SamlResponse } from '@/models/user';
import httpClient from './http-client';

const LOGIN_ENDPOINT = '/users/self';
const LOGOUT_ENDPOINT = '/logout';
const SAML_ENDPOINT = '/user/issaml';
const GET_TFA_ENDPOINT = '/user/gettfa';
const TFA_ENDPOINT = '/verifyTfa';

function toSafeOrgId(orgId?: string | number | null) {
  return orgId === undefined || orgId === null || Number.isNaN(+orgId)
    ? null
    : +orgId;
}

export const login = ({
  username,
  password,
  orgId,
}: {
  username: string;
  password: string;
  orgId?: number | null;
}): Promise<User> => {
  const authorization = btoa(`${username.toLowerCase()}:${password}`);

  return httpClient
    .get(LOGIN_ENDPOINT, {
      params: { o: toSafeOrgId(orgId) },
      headers: { Authorization: `Basic ${authorization}` },
    })
    .then((response) => response.data);
};

export const logout = (): Promise<AxiosResponse> => {
  return httpClient.get(`${LOGOUT_ENDPOINT}`).then((response) => response.data);
};

export const checkSaml = (
  email: string,
  orgId: string | null,
  connection: string | null,
): Promise<SamlResponse> => {
  return httpClient
    .get(SAML_ENDPOINT, {
      params: { email, o: toSafeOrgId(orgId), connection },
      loaderEnabled: false,
    })
    .then((response) => response.data);
};

/**
 * @deprecated API will not function after Auth0 Milestone 1 launches
 */
export const getTfa = (email: string): Promise<UserTfaStatus> => {
  return httpClient
    .get(`${GET_TFA_ENDPOINT}?${stringifyUrlQuery({ email })}`, {
      loaderEnabled: false,
    } as AxiosRequestConfig)
    .then((response) => response.data);
};

/**
 * @deprecated API will not function after Auth0 Milestone 1 launches
 */
export const verifyTfa = (tfa_code: string): Promise<AxiosResponse> => {
  return httpClient
    .post(`${TFA_ENDPOINT}`, { tfa_code })
    .then((response) => response.data);
};

/**
 * @deprecated API will not function after Auth0 Milestone 1 launches
 */
export const passwordReset = (email: string): Promise<AxiosResponse> => {
  return httpClient({
    method: 'GET',
    baseURL: consoleEnv.BASE_URL,
    url: `/passwordReset`,
    params: {
      email,
    },
  }).then((response) => response.data);
};

/**
 * @deprecated API will not function after Auth0 Milestone 1 launches
 */
export const postPasswordReset = (payload: {
  email: string;
  token: string;
  password: string;
}): Promise<AxiosResponse> => {
  return httpClient({
    method: 'POST',
    baseURL: consoleEnv.BASE_URL,
    url: '/passwordReset',
    data: payload,
  }).then((response) => response.data);
};
