import {
  ModalState as NewModalState,
  ModalStateWithProps as NewModalStateWithProps,
  useModal as NewUseModal,
  useModals as NewUseModals,
} from '@ax/modal-composition';

/**
 * @deprecated DON'T USE, Instead Use: import { ModalState } from "@ax/modal-composition";
 */
export type ModalState = NewModalState;

/**
 * @deprecated DON'T USE, Instead Use: import { ModalStateWithProps } from "@ax/modal-composition";
 */
export type ModalStateWithProps<P = unknown> = NewModalStateWithProps<P>;

/**
 * @deprecated DON'T USE, Instead Use: import { useModal } from "@ax/modal-composition"; useModal();
 */
export function useModal<P = unknown>(): NewModalStateWithProps<P> {
  return NewUseModal();
}

/**
 * @deprecated DON'T USE, Instead Use: import { useModals } from "@ax/modal-composition"; useModals('my-cool-modal');
 */
export function useModals<Key extends string>(
  modals: Key[],
): { [K in Key]: NewModalState } {
  return NewUseModals(modals);
}
