import { provide, inject, reactive, computed, Ref } from '@vue/composition-api';
import { Route } from 'vue-router';

/**
 * This is the symbol used for injecting the route to child components.
 */
export const RouteSymbol = Symbol.for('@ax/vue-utils/route');

/**
 * This is to align with `vue-router` 4's `useRoute` which does
 * not return a `Ref`. The implementation is based on `vue-router`'s
 * implementation here: https://github.com/vuejs/router/blob/e560f30846d82cc7abc48ae3fe6fdc61a866a534/src/router.ts#L1202
 * @param route `Ref<Route>`
 * @returns a reactive `Route`
 */
function makeReactiveRoute(route: Ref<Route>): Route {
  return reactive({
    path: computed(() => route.value.path),
    name: computed(() => route.value.name),
    query: computed(() => route.value.query),
    fullPath: computed(() => route.value.fullPath),
    /**
     * Vue Router's `$route` does not have `matched` as a field, even
     * though it says it is available on the `Route` type. This is to conform with
     * the type but shouldn't be used.
     */
    // eslint-disable-next-line vue/return-in-computed-property
    matched: computed(() => {
      throw new Error(
        'Vue Router 3 does not have `matched` on `Route`, do not use this field',
      );
    }),
    hash: computed(() => route.value.hash),
    params: computed(() => route.value.params),
    meta: computed(() => route.value.meta),
    redirectedFrom: computed(() => route.value.redirectedFrom),
  });
}

/**
 * Provide a ref of the current route in `App.vue` or some other root component.
 * @param `Ref<Route>`
 */
export function provideRoute(route: Ref<Route>) {
  provide(RouteSymbol, makeReactiveRoute(route));
}

/**
 * use to get access to the route without accessing `context`
 * @returns `Readonly<Ref<Route>>`
 */
export function useRoute() {
  const route = inject<Route>(RouteSymbol);
  if (route === undefined) {
    throw new Error('The route must be provided in a parent component');
  }
  return route;
}
