import { RouteConfig } from 'vue-router';

import { consoleI18n } from '@ax/console-i18n';

import { FlagKeys } from '@/models/feature-flags';
import { redirectTo404AfterAuth0 } from './router-utilities';

const routes: RouteConfig[] = [
  {
    path: '/login',
    name: 'login',
    meta: {
      layout: 'baseline',
      title: 'Login',
    },
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    meta: {
      layout: 'baseline',
      title: 'Forgot Password',
    },
    beforeEnter: redirectTo404AfterAuth0(FlagKeys.auth0Milestone1),
    component: () => import('@/views/ForgotPassword.vue'),
  },
  {
    path: '/password-reset',
    name: 'password-reset',
    meta: {
      layout: 'baseline',
      title: 'Reset Password',
    },
    beforeEnter: redirectTo404AfterAuth0(FlagKeys.auth0Milestone1),
    component: () => import('@/views/ResetPassword.vue'),
  },
  {
    path: '/signup',
    name: 'signup',
    meta: {
      layout: 'baseline',
      title: consoleI18n.t('routeTitles.signup'),
    },
    component: () => import('@/views/Signup.vue'),
  },
  {
    path: '/account-created',
    name: 'account-created',
    component: () => import('@/views/AccountCreated.vue'),
    props: true,
    meta: {
      layout: 'baseline',
      title: consoleI18n.t('routeTitles.accountCreated'),
    },
  },
  {
    path: '/accept-invite',
    name: 'accept-invite',
    meta: {
      layout: 'baseline',
      title: 'Accept Invitation',
    },
    component: () => import('@/views/AcceptInvite.vue'),
  },
];

export default routes.map((route) => {
  const meta = { ...route.meta, public: true };

  return { ...route, meta };
});
