import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { icons, theme } from '@ax/cosmos/utils/config';

Vue.use(Vuetify);

export const config = {
  icons,
  theme,
};

export default new Vuetify(config);
