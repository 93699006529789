// TODO: remove after existing references are changed to check access only by permission and not hardcoded role
export enum RbacRoles {
  ADMIN = 1,
  READ = 2,
  BILLING = 3,
  PATCH = 4,
}

export class Role {
  id?: number;

  name?: string;

  description?: string;

  permissions?: number[];

  constructor(init: Role) {
    Object.assign(this, init);
  }
}

class PermissionTypeName {
  name?: string;
}

export interface Permission {
  id: number;
  name: string;
  description: string;
  type: PermissionTypeName;
}

// "Legacy" Rbac object embedded in user objects
export class RbacRole {
  id?: number;

  name?: string;

  organization_id?: number;

  constructor(init: RbacRole) {
    Object.assign(this, init);
  }
}
