import { format, subDays } from 'date-fns';
import { Overview } from '@/models/reports-overview';
import { stringifyUrlQuery } from '@/utils/util';
import { DATE_FORMAT } from '@/utils/date-time';
import httpClient from './http-client';

const OVERVIEW_ENDPOINT = '/reports/overview';

export function getOverview(orgId: string | number): Promise<Overview> {
  const startDate = format(subDays(new Date(), 10), DATE_FORMAT);
  return httpClient
    .get(
      `${OVERVIEW_ENDPOINT}?${stringifyUrlQuery({
        o: orgId,
        internal: 1,
        startDate,
      })}`,
    )
    .then(({ data }) => data);
}
