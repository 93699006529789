import { computed } from '@vue/composition-api';
import { createSharedComposable } from '@vueuse/core';
import { isNonNullable } from '@ax/function-utils/nullable';
import { updateWhen_ } from '../ref';
import { ignoreDuplicateQueryParams } from './ignore-duplicate-query-params';
import { useRouteQuery } from './use-route-query';
/**
 * @returns `ComputedRef<OrgId | undefined>`
 */
function maybeOrgIdState() {
  const query = useRouteQuery();

  return computed(() => {
    const currentQuery = ignoreDuplicateQueryParams(query.value);

    const queryOrgId = currentQuery.org || currentQuery.o;

    return queryOrgId === undefined ? undefined : parseInt(queryOrgId, 10);
  });
}

/**
 * This creates a shared composable of `orgIdState` which means that if multiple
 * components call `useCurrentOrgId`, they will get back the exact same ref which
 * minimizes memory consumption. If there are no longer any components using
 * `useCurrentOrgId`, the ref will be cleaned up and will stop tracking dependencies.
 */
export const useMaybeCurrentOrgId = createSharedComposable(maybeOrgIdState);

/**
 * Will throw an error if `o` or `org` is not defined in the query params for the current
 * route.
 * @returns `ComputedRef<OrgId>`
 */
function orgIdStateUnsafe() {
  const orgId = updateWhen_(useMaybeCurrentOrgId(), isNonNullable);

  return computed(() => {
    const currentOrgId = orgId.value;
    if (currentOrgId === undefined) {
      throw new Error('Expected OrgId to be defined on the current route');
    }
    return currentOrgId;
  });
}

/**
 * Shared composable for `orgIdStateUnsafe`
 */
export const useCurrentOrgId = createSharedComposable(orgIdStateUnsafe);
