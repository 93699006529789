import { format as tzFormat, utcToZonedTime } from 'date-fns-tz';
import {
  format,
  formatDistanceStrict,
  parseISO,
  differenceInCalendarDays,
} from 'date-fns';

import { userTimeZone } from './user-time-zone';

export const DATE_TIME_ZONE_FORMAT = 'yyyy-MM-dd h:mm a zzz';
export const DATE_FORMAT = 'yyyy-MM-dd';

/**
 * Get a formatted, localized datetime string
 * @param timestamp a UTC datetime string
 * @param timeZone defaults to the browser-platform's current time zone
 * @param dateFormat defaults to `'yyyy-MM-dd h:mm a zzz'
 * @returns
 */
export function formatUTCToZonedTime(
  timestamp?: string,
  timeZone: string = userTimeZone,
  dateFormat: string = DATE_TIME_ZONE_FORMAT,
): string {
  if (!timestamp) {
    return '';
  }
  const zonedDateTime = utcToZonedTime(timestamp, timeZone);

  return tzFormat(zonedDateTime, dateFormat, { timeZone });
}

/**
 * If relying on this for a new feature, consider implementing a more single-responsibility
 * version of this logic that is compatible with i18n translations.
 * @param timestamp the time to compare `baseDate` to.
 * @param baseDate the time to compare `timestamp` to. defaults to now
 * @param options
 * @returns The number of options.units between `timestamp` and `baseDate`
 */
export function formatDateTimeDistance(
  timestamp?: string,
  baseDate = new Date(),
  options: {
    addSuffix?: boolean;
    unit?: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year';
    roundingMethod?: 'floor' | 'ceil' | 'round';
    locale?: Locale;
  } = { addSuffix: true, locale: { formatDistance: formatDistanceLocale } },
): string {
  if (timestamp) {
    const date = parseISO(timestamp);
    return formatDistanceStrict(date, baseDate, options);
  }
  return '';
}

/**
 * @param timestamp the time to compare `baseDate` to.
 * @param baseDate the time to compare `timestamp` to. defaults to now
 */
export function daysExposed(timestamp: string, baseDate = new Date()) {
  const date = parseISO(timestamp);

  const difference = Math.max(differenceInCalendarDays(baseDate, date), 1);

  return pluralize(difference, 'Day', 'Days');
}

export function formatDate(
  timestamp?: string | Date,
  dateFormat: string = DATE_FORMAT,
): string {
  if (timestamp) {
    const date =
      typeof timestamp === 'string' ? parseISO(timestamp) : timestamp;
    return format(date, dateFormat);
  }

  return '';
}

/**
 * Avoid exporting the below helper functions or relying on them for new logic.
 * They support existing feature implementations, but are not compatible with
 * i18n translation.
 */

const DISTANCE_MAP = {
  xYears: 'years',
  xMonths: 'months',
  xDays: 'days',
  xHours: 'hours',
  xMinutes: 'minutes',
  xSeconds: 'seconds',
};

function formatDistanceLocale(
  unit: string,
  value: number,
  { comparison }: { comparison: number },
): string {
  const unitWord = pluralize(
    value,
    DISTANCE_MAP[unit].slice(0, -1),
    DISTANCE_MAP[unit],
  );

  return `${unitWord} ${comparison > 0 ? 'from now' : 'ago'}`;
}

function pluralize(
  count = 0,
  singular: string,
  plural: string,
  includeNumber = true,
): string {
  if (includeNumber) {
    return `${count} ${count === 1 ? singular : plural}`;
  }

  return count === 1 ? singular : plural;
}
