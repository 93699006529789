import {
  QueryStatus,
  Empty,
  Loading,
  Success,
  Refreshing,
  Failure,
} from './definition';

/**
 * The handlers to convert each potential status of a `Query<A, E>` into a new
 * piece of data.
 */
export interface QueryStatusFoldHandlers<A, E, B, C = B, D = B, F = B, G = B> {
  onEmpty: (status: Empty<A>) => B;
  onLoading: (status: Loading<A>) => C;
  onSuccess: (status: Success<A>) => D;
  onRefreshing: (status: Refreshing<A>) => F;
  onFailure: (status: Failure<A, E>) => G;
}

/**
 * Fold a `QueryStatus` into a new value based on the type of status. This is
 * analagous to `reduce` on an `Array` where we are taking a container for
 * data and folding into a new piece of data.
 * @param status
 * @param handlers A handler for each potential state
 * @returns
 */
export function fold_<A, E, B, C = B, D = B, F = B, G = B>(
  status: QueryStatus<A, E>,
  handlers: QueryStatusFoldHandlers<A, E, B, C, D, F, G>,
): B | C | D | F | G {
  /**
   * This could also be done by `handlers[status.type](status as any)` but
   * that loses type information and requires casting `status` to `any`.
   */
  switch (status.type) {
    case 'Empty':
      return handlers.onEmpty(status);
    case 'Failure':
      return handlers.onFailure(status);
    case 'Loading':
      return handlers.onLoading(status);
    case 'Refreshing':
      return handlers.onRefreshing(status);
    case 'Success':
      return handlers.onSuccess(status);
  }
}

/**
 * curried version of fold_
 */
export function fold<A, E, B, C = B, D = B, F = B, G = B>(
  handlers: QueryStatusFoldHandlers<A, E, B, C, D, F, G>,
) {
  return (status: QueryStatus<A, E>) => fold_(status, handlers);
}
/**
 * Folds a `QueryStatus`'s data into something concrete since the data of a status is optional.
 * @param status The status we want to fold
 * @param onNone What to do when the data is not present
 * @param onSome What to do when the data is present
 * @returns folded data
 */
export function foldData_<A, B, C = B>(
  status: QueryStatus<A, unknown>,
  onNone: () => B,
  onSome: (data: A) => C,
): B | C {
  switch (status.type) {
    case 'Success':
    case 'Refreshing':
      return onSome(status.data);
    case 'Empty':
    case 'Failure':
    case 'Loading': {
      const maybeData = status.data;
      return maybeData === undefined ? onNone() : onSome(maybeData);
    }
  }
}

/**
 * Curried version of foldData_
 */
export function foldData<A, B, C = B>(onNone: () => B, onSome: (data: A) => C) {
  return (status: QueryStatus<A, unknown>) => foldData_(status, onNone, onSome);
}
