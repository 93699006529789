const inBrowser = typeof window !== 'undefined';
const UA = inBrowser && window.navigator.userAgent.toLowerCase();

export const detectedBrowsers = {
  IE: UA && /msie|trident/.test(UA),
  IE9: UA && UA.indexOf('msie 9.0') > 0,
  Edge: UA && UA.indexOf('edge/') > 0,
  Android: UA && UA.indexOf('android') > 0,
  IOS: UA && /iphone|ipad|ipod|ios/.test(UA),
  Chrome: UA && /chrome\/\d+/.test(UA) && !(UA.indexOf('edge/') > 0),
  PhantomJS: UA && /phantomjs/.test(UA),
  FF: UA && UA.match(/firefox\/(\d+)/),
};
