import { RbacRole } from '@ax/data-services-authorization/models/rbac';
import {
  ParentOrganization,
  Organization,
} from '@ax/data-services-zone/models/organization';
import { RESET } from '@/store/actions';

import { setItemInStorage } from '@/utils/storage';

export const SET_ORGANIZATION = 'set_organization';
export const SET_PARENT_ORGANIZATION = 'set_parent_organization';
export const SET_ROLE = 'set_role';

const initialState = () => ({
  organization: {},
  parentOrganization: {},
  role: {},
});

const getters = {
  getOrganization: (state) => state.organization || {},
  getParentOrganization: (state) => state.parentOrganization || {},
  getRole: (state): RbacRole => state.role,
};

const actions = {
  [SET_ORGANIZATION]: ({ commit }, organization: Organization) => {
    commit(SET_ORGANIZATION, organization);
    setItemInStorage(organization.id, {
      key: 'current-org',
      store: localStorage,
    });
  },
  [SET_PARENT_ORGANIZATION]: (
    { commit },
    parentOrganization: ParentOrganization,
  ) => {
    commit(SET_PARENT_ORGANIZATION, parentOrganization);
  },
  [SET_ROLE]: ({ commit, state }, role) => {
    if (role.id !== state.role.id) {
      commit(SET_ROLE, role);
    }
  },
  [RESET]: ({ commit }) => {
    commit(RESET);
  },
};

const mutations = {
  [SET_ORGANIZATION]: (state, organization: Organization) => {
    state.organization = new Organization(organization);
  },
  [SET_PARENT_ORGANIZATION]: (
    state,
    parentOrganization: ParentOrganization,
  ) => {
    state.parentOrganization = new ParentOrganization(parentOrganization);
  },
  [SET_ROLE]: (state, role: RbacRole) => {
    state.role = role;
  },
  [RESET]: (state) => {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations,
};
