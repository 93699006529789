import { User } from '@ax/data-services-user/models/user';
import { Organization } from '@ax/data-services-zone/models/organization';
import { env } from '@ax/env';

export class SegmentAnalytics {
  static accountId: string | null;

  static organizationId: string | null;

  static setAccountId(id) {
    this.accountId = id;
  }

  static setOrganizationId(id) {
    this.organizationId = id;
  }

  static load = (key) => {
    window.analytics.load(key);
  };

  static group = (user: User, org?: Organization) => {
    if (user.account_id) {
      const traits = {
        name: user.account_name,
        createdAt: user.account_created_at,
        ...(org && { trialEndTime: org.trial_end_time }),
      };

      window.analytics.group(user.account_id, traits);

      // Additionally log segment groups to the console in dev for testing purposes
      if (env.NODE_ENV === 'development') {
        console.log('Segment Group:', user.account_id, traits);
      }
    }
  };

  static identify = (user?: User, org?: Organization) => {
    if (user) {
      let company;

      if (user.account_id) {
        company = {
          id: user.account_id,
          name: user.account_name,
        };
      }

      window.analytics.identify(user.id, {
        firstName: user.firstname,
        lastName: user.lastname,
        email: user.email,
        ...(company && { company }),
      });

      // Also call `group` to enrich company with more traits
      this.group(user, org);
    } else {
      window.analytics.identify();
    }

    // Additionally log segment identifies to the console in dev for testing purposes
    if (env.NODE_ENV === 'development') {
      console.log('Segment Identify:', user, org);
    }
  };

  static page = () => {
    window.analytics.page();
  };

  static reset = () => {
    this.accountId = null;
    this.organizationId = null;

    window.analytics.reset();
  };

  static track = (name: string, properties: object = {}) => {
    const modifiedProps = {
      ...properties,
      ...(this.organizationId && { organizationId: this.organizationId }),
      ...(this.accountId && { accountId: this.accountId }),
    };

    window.analytics.track(name, modifiedProps);

    // Additionally log segment tracks to the console in dev for testing purposes
    if (env.NODE_ENV === 'development') {
      console.log('Segment Track:', name, modifiedProps);
    }
  };
}
