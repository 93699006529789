import { Ref, shallowRef, watch } from '@vue/composition-api';

const DEFAULT_DEBOUNCE = 200;

/**
 * Debounce the global loading indicator
 * @param isLoading the `Ref<boolean>` to debounce
 * @param debounceTime The amount of time to wait before setting `isLoading` to false in
 * case `isLoading` changes again via a network waterfall
 * @returns `Readonly<Ref<boolean>>`
 */
export function debounceLoadingIndicator(
  isLoading: Ref<boolean>,
  debounceTime = DEFAULT_DEBOUNCE,
): Readonly<Ref<boolean>> {
  const time = debounceTime <= 0 ? DEFAULT_DEBOUNCE : debounceTime;
  const localIsLoading = shallowRef(isLoading.value);

  function setFalse() {
    /**
     * Only set local value to false if source value
     * is still false and local value is true
     */
    if (isLoading.value === false && localIsLoading.value === true) {
      localIsLoading.value = false;
    }
  }

  watch(isLoading, (newValue) => {
    if (newValue) {
      localIsLoading.value = true;
    } else {
      setTimeout(setFalse, time);
    }
  });

  return localIsLoading;
}
