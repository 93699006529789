import { computed, isRef, Ref, unref, watch } from '@vue/composition-api';
import { MaybeRef } from '@vueuse/core';
import { consoleI18n } from '@ax/console-i18n';
import { showErrorNotification } from '@ax/notifications';
import {
  zonesWithPermission,
  canAccess,
  MappedPermission,
} from '../business-logic/permissions';

import {
  GetRolesByOrgId,
  useGetPermissionsQuery,
  useGetRolesByOrgIdQuery,
} from '../queries/rbac.queries';
import { HasOrgId, User } from '../models/user';

function setupData<Org extends HasOrgId>(
  user: Ref<User<Org>>,
  orgId: MaybeRef<number>,
) {
  const { data: roles, error: rolesError } = useGetRolesByOrgIdQuery(
    isRef(orgId)
      ? computed(() => new GetRolesByOrgId({ orgId: orgId.value }))
      : new GetRolesByOrgId({ orgId }),
  );

  watch(rolesError, (newVal) => {
    if (newVal !== undefined) {
      showErrorNotification(
        consoleI18n.t('auth.roles.errors.rolesListFailure:notification'),
      );
    }
  });

  const { data: permissions, error: permissionsError } =
    useGetPermissionsQuery();

  watch(permissionsError, (newVal) => {
    if (newVal !== undefined) {
      showErrorNotification(
        consoleI18n.t(
          'auth.permissions.errors.permissionsListFailure:notification',
        ),
      );
    }
  });

  const roleIdInCurrentOrg = computed(() => {
    const currentOrgIdNum = unref(orgId);
    return user.value.rbac_roles?.find(
      (roleInOrg) => roleInOrg.organization_id === currentOrgIdNum,
    )?.id;
  });

  return {
    roles,
    permissions,
    roleIdInCurrentOrg,
  };
}

/**
 *
 * `usePermissionsForUserAndOrg` returns helper functions:
 * `computedCanAccess` which is used to determine if a `User` has access to a specific resource,
 * and `computedZonesWithPermission` which is used to get an array of Zones a `User` has the input
 * permission for.
 * @param user `Ref<User>`
 * @param orgId `Ref<number>`
 */
export function usePermissionsForUserAndOrg<Org extends HasOrgId>(
  orgId: MaybeRef<number>,
  user: Ref<User<Org>>,
) {
  const { roles, permissions } = setupData(user, orgId);

  function useZonesWithPermission(permission: MappedPermission) {
    return computed(
      () =>
        permissions.value &&
        roles.value &&
        zonesWithPermission(
          user.value,
          permission,
          permissions.value,
          roles.value,
        ),
    );
  }

  function useCanAccess(permission: MappedPermission) {
    return computed(
      () =>
        permissions.value &&
        roles.value &&
        canAccess(
          unref(orgId),
          user.value,
          permission,
          permissions.value,
          roles.value,
        ),
    );
  }

  return { useZonesWithPermission, useCanAccess };
}
