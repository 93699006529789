// https://automox.atlassian.net/wiki/spaces/ENG/pages/1727561892/RFC+REST+API+and+Routing+Specification#Error-Responses

// Not compliant to REST API RFC, but some APIs use this structure for some errors
export interface FieldError {
  readonly field: string;
  readonly errors: string[];
}

export interface ErrorResponseData {
  readonly errors?: string | (string | FieldError)[] | Record<string, unknown>;
  readonly message?: string;
  readonly code?: string;
}

export interface ErrorResponse {
  readonly data: ErrorResponseData;
}

export function parseHttpErrors(
  defaultErrorMessage: string,
  errorPrefixStr: string,
  errorResponse?: ErrorResponse,
): string {
  const { errors, message } = errorResponse?.data ?? {};

  if (!errors) {
    return message ? `${errorPrefixStr} ${message}` : defaultErrorMessage;
  }

  if (typeof errors === 'string') {
    return `${errorPrefixStr} ${errors}`;
  }

  if (Array.isArray(errors)) {
    return `${errorPrefixStr} ${errors
      .flatMap((error) => (typeof error === 'string' ? error : error.errors))
      .join('\n')}`;
  }

  return `${errorPrefixStr} ${Object.values(errors).flat().join('\n')}`;
}
