/**
 * Legacy env using process.env. This is only used in console build script. Once this is migrated
 * to Vite build, this file can be removed.
 */

import { getEnv } from '../../env/src/getEnv';
import { EnvConfig } from '../../env/src/models';
import { envConfig } from './envConfig';

/**
 * Swaps VITE config key prefixes with VUE_APP
 * @param config Incoming env config
 * @returns Re-prefixed env config
 */
function rePrefixConfig(config: EnvConfig): EnvConfig {
  return Object.keys(config).reduce<EnvConfig>(
    (outputConfig: EnvConfig, configKey: string) => ({
      ...outputConfig,
      [configKey]: {
        ...config[configKey],
        env: config[configKey].env.replace('VITE', 'VUE_APP'),
      },
    }),
    config,
  );
}

export const consoleEnv = getEnv(rePrefixConfig(envConfig), process.env);
