import { ConsoleAxiosRequestConfig } from '@ax/api-clients-common';
import { legacyAccountsClient } from './http-client';
import { Role, Permission } from '../models/rbac';

const RBAC_ROLES_ENDPOINT = 'rbacroles';
const PERMISSIONS_ENDPOINT = 'permissions';

export function fetchRoles(
  orgId: string,
  requestConfig: ConsoleAxiosRequestConfig = {},
) {
  return legacyAccountsClient.get<readonly Role[]>(RBAC_ROLES_ENDPOINT, {
    ...requestConfig,
    params: { o: orgId, internal: 1 },
  });
}

export function fetchPermissions(
  requestConfig: ConsoleAxiosRequestConfig = {},
) {
  return legacyAccountsClient.get<readonly Permission[]>(PERMISSIONS_ENDPOINT, {
    ...requestConfig,
    params: { internal: 1 },
  });
}
