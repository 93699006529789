import Vue from 'vue';
import VueCompositionApi from '@vue/composition-api';

/**
 * This file is to add the VueCompositionAPI before all other code. There were
 * some workarounds where certain functions were being called potentially before
 * the plugin was instantiated. This fixes that issue.
 */

Vue.use(VueCompositionApi);
