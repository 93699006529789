import { filter_ } from './filter';

function isNonEmpty(value: unknown) {
  return Array.isArray(value)
    ? value.length > 0
    : !!value || value === 0 || value === false;
}

function makeFilter(forceRemove?: unknown[]) {
  return (key: PropertyKey, value: unknown) => {
    if (forceRemove && forceRemove.includes(key)) {
      return false;
    }
    return isNonEmpty(value);
  };
}

/**
 * Filter `empty` values from an object. Also remove keys inside `forceRemove`.
 * `empty` is considered an empty array or falsy value other than 0 or false.
 * @param input `A`
 * @param forceRemove `Array<keyof A>`
 * @returns `Partial<A>`
 */
export function removeEmptyEntriesFromShallowObject<A extends {}>(
  input: A,
  forceRemove?: Array<keyof A>,
): Partial<A> {
  return filter_(input, makeFilter(forceRemove));
}
