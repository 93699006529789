import { RESET } from '@/store/actions';

const SHOW = 'show';
const HIDE = 'hide';

const initialState = () => ({
  loading: false,
});

const getters = {
  isLoading: (state) => state.loading,
};

const actions = {
  [SHOW]: ({ commit }) => {
    commit(SHOW);
  },
  [HIDE]: ({ commit }) => {
    commit(HIDE);
  },
};

const mutations = {
  [SHOW]: (state) => {
    state.loading = true;
  },
  [HIDE]: (state) => {
    state.loading = false;
  },
  [RESET]: (state) => {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations,
};
