import { Response, Nullable } from '@ax/type-utils';
import { isNonNullable } from '../nullable';
import { Predicate } from '../core';
import { Request } from './definition';

/**
 * Use to cache the result of a request when `shouldCache` returns true
 * @param request The request to perform
 * @param shouldCache a `Predicate<Response<Succ, Err>>` that is used to indicate if the
 * resposne should be cached
 * @returns a function of the same form of `request`
 */
export function cacheWhen_<Succ, Err, Input = void>(
  request: Request<Input, Succ, Err>,
  shouldCache: Predicate<Response<Succ, Err>>,
) {
  let response: Nullable<Response<Succ, Err>>;

  async function run(input: Input) {
    const result = await request(input);

    if (shouldCache(result)) {
      response = result;
    }

    return result;
  }

  return async (input: Input, force = false) => {
    if (force) {
      return run(input);
    }
    if (isNonNullable(response)) {
      return response;
    }
    return run(input);
  };
}

/**
 * Curried version of `cacheWhen_`.
 * @param shouldCache The predicate used to determine if the result of the computation should be cached
 * @returns a function expecting a request of the form `Request<Input, Succ, Err>`
 */
export function cacheWhen<Succ, Err>(
  shouldCache: Predicate<Response<Succ, Err>>,
) {
  return <S extends Succ = Succ, E extends Err = Err, Input = void>(
    request: Request<Input, S, E>,
  ) => cacheWhen_(request, shouldCache);
}
