import { isSuccess } from '@ax/type-utils';
import { Request } from './definition';

/**
 * Convert a `Request<Input, Succ, Err>` into an async function that returns `Succ` and will
 * throw the `Err` if present.
 *
 * @param request `Request<Input, Succ, Err>
 * @returns `(input: Input) => Promise<A>`
 */
export function extractSuccessUnsafe<Succ, Err, Input = void>(
  request: Request<Input, Succ, Err>,
): (input: Input) => Promise<Succ> {
  return (input) =>
    request(input).then((response) => {
      if (isSuccess(response)) {
        return response.success;
      }
      throw response.error;
    });
}
