import { Query as QueryBase } from '@ax/cache-and-dedupe-core/query';

export type {
  ShapeOfSuccess,
  ShapeOfError,
} from '@ax/cache-and-dedupe-core/query';
/**
 * A `Query<Params, Succ, Err>` is a description of a request that
 * requires parameters of type `Params`, can succeed with type `Succ`,
 * and can fail with type `Err`.
 *
 * By default, a query will have a key `showLoader` set to true which can be overridden
 * by an extension class.
 */
export abstract class Query<Params extends object, Succ, Err> extends QueryBase<
  { readonly showLoader?: boolean } & Params,
  Succ,
  Err
> {
  constructor(params: { readonly showLoader?: boolean } & Params) {
    super(params);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyQuery = Query<any, any, any>;
