/**
 * Returns whether a value is a valid domain.
 * @param value Domain value to check
 * @return True if a valid domain, false if not.
 */
export function isValidDomain(value: string): boolean {
  return /^([a-zA-Z0-9-_])+(\.[a-zA-Z0-9-_]{2,24})+\.?$/.test(value);
}

/**
 * Returns whether a value contains a valid host.
 * @param value Value to check.
 * @return True if contains a valid host, false if not.
 */
export function containsHost(value: string): boolean {
  const doesContainHost =
    /^(\\\/)*((.{1,63}\.){1,127}(?!\d*$)[a-z\d-]+\.?)/i.test(value);
  const leadingSlashWithPeriodInParams = /^(\/\w+)+\/?\?[\w=&%-~]*\./.test(
    value,
  );
  return doesContainHost && !leadingSlashWithPeriodInParams;
}

/**
 * Returns whether a value is a root url "/"
 * @param value Value to check.
 * @return True if root URL, false if not.
 */
export function isRootUrl(value: string): boolean {
  return /^\/[?#]?$/.test(value);
}

/**
 * Returns whether a value is an absolute URL
 * @param value Value to check.
 * @return True if absolute URL, false if not.
 */
export function isAbsoluteUrl(value: string): boolean {
  return /^([a-z]+:\/\/|\/\/)/i.test(value);
}

/**
 * Returns whether a value is a relative URL.
 * @param value Value to check.
 * @return True if relative URL, false if not.
 */
export function isRelativeUrl(value: string): boolean {
  if (!value) {
    return false;
  }

  if (isRootUrl(value) || isAbsoluteUrl(value)) {
    return false;
  }

  return !containsHost(value);
}

/**
 * Determines if a value is considered "truthy-like", which includes some string matching.
 * @param value The value of which to determine truthiness.
 * @param arrayStrategy Strategy used to validate arrays. "complete" requires all array items to
 *                      pass validation. "partial" only requires at least 1 array item to pass
 *                      validation. "first" requires only the 1st array item to pass validation.
 * @returns boolean True if truthy-like, false if not.
 */
export function isTruthyLike<
  V extends string | boolean | number | null | undefined,
>(
  value: V | V[],
  arrayStrategy: 'complete' | 'partial' | 'first' = 'complete',
): boolean {
  if (Array.isArray(value)) {
    if (arrayStrategy === 'first') {
      return isTruthyLike(value[0]);
    }
    return value[arrayStrategy === 'complete' ? 'every' : 'some']((val) =>
      isTruthyLike(val),
    );
  }

  if (typeof value === 'number') {
    return value > 0;
  }

  if (typeof value === 'boolean') {
    return value;
  }

  return !['0', 'false', 'no', '', null, undefined].includes(value);
}
