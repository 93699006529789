import { Agents } from '@/models/agents';
import { getAgents } from '@/services/agents.service';
import { RESET } from '@/store/actions';

const AGENTS_REQUEST = 'request';
const AGENTS_RECEIVED = 'eceived';
const AGENTS_ERROR = 'error';

const initialState = () => ({
  error: {},
  agents: null,
  status: '',
});

const getters = {
  getAgents: (state): Agents => state.agents,
  isLoading: (state): boolean => state.status === 'loading',
};
const actions = {
  [AGENTS_REQUEST]: (
    { commit },
    orgId: string | number,
  ): Promise<Agents | void> => {
    commit(AGENTS_REQUEST);

    return getAgents(orgId)
      .then((response) => {
        commit(AGENTS_RECEIVED, response);

        return response;
      })
      .catch((error) => {
        commit(AGENTS_ERROR, error);
      });
  },
};

const mutations = {
  [AGENTS_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [AGENTS_RECEIVED]: (state, data) => {
    state.agents = data;
    state.status = 'success';
  },
  [AGENTS_ERROR]: (state, error) => {
    state.error = error;
    state.status = 'error';
  },
  [RESET]: (state) => {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations,
};
