export enum CosmosDarkColors {
  Primary = '#249FC3', // $ax-primary-blue
  Secondary = '#1D2C44', // $ax-storm-1-l8

  Caution = '#FF5050', // $ax-caution-red
  CautionText = '#FF7272', // $ax-caution-red-text
  Success = '#BAD438', // $ax-success-green
  Warning = '#FFCB00', // $ax-warning-yellow
  Content = '#FAFAFA', // $ax-gray-light
  ContentDark = '#DBDBDB', // $ax-gray-medium
  Teal = '#8FFBF5', // $ax-electric-teal
  Neutral = '#BBBBBB', // $ax-gray-dark
  Chip = '#2D4859', // $ax-chip
  Input = '#102F42', // $ax-input

  LightGray = '#F4F4F4',

  CasperGray = '#AFBCCF',
  CasperGray60 = '#92A3BD',
  CasperGray70 = '#7B91B2',

  /** start: DATA */

  DataPacific = '#1C9EE8', // $ax-data-pacific-50
  DataPacific60 = '#4CBBFB', // $ax-data-pacific-60
  DataPacific70 = '#82CDF8', // $ax-data-pacific-70

  DataPine = '#1EAE9D', // $ax-data-pine-50
  DataPine60 = '#26D2BE', // $ax-data-pine-60
  DataPine70 = '#37EFD9', // $ax-data-pine-70

  DataLime = '#19BA69', // $ax-data-lime-50
  DataLime60 = '#36DD8A', // $ax-data-lime-60
  DataLime70 = '#56FFAB', // $ax-data-lime-70

  DataYellow = '#D7BA49', // $ax-data-yellow-50
  DataYellow60 = '#FFEA84', // $ax-data-yellow-60
  DataYellow70 = '#FFF4BB', // $ax-data-yellow-70

  DataOrange = '#F36435', // $ax-data-orange-50
  DataOrange60 = '#FB7F57', // $ax-data-orange-60
  DataOrange70 = '#FFA183', // $ax-data-orange-70

  DataRed = '#FF5050', // $ax-data-red-50
  DataRed60 = '#FA6F6F', // $ax-data-red-60
  DataRed70 = '#FF8D8D', // $ax-data-red-70

  DataPink = '#F651A3', // $ax-data-royal-pink-50

  /** end: DATA */

  Storm0 = '#101723', // $ax-storm-0
  Storm1 = '#121C2B', // $ax-storm-1
  Storm2 = '#172335', // $ax-storm-2
  Storm3 = '#1F2C40', // $ax-storm-3
  Storm4 = '#28374D', // $ax-storm-4
  Storm5 = '#36455C', // $ax-storm-5
}
