// forked from https://github.com/Effect-TS/core/blob/master/packages/system/src/Case/index.ts

import type { IsEqualTo } from '@ax/type-utils';
import { keys } from './keys';

export interface CopyableBase<A> {
  readonly _args: A;
  readonly _keys: ReadonlyArray<keyof A>;
  copy(args: IsEqualTo<A, {}> extends true ? void : Partial<A>): this;
}

export type CopyableClass<A> = A & CopyableBase<A>;

export interface CopyableConstructor {
  new <A>(args: A): CopyableClass<A>;
}

/**
 * A class that is easy to extend with built in support for copying and assigning
 * all properties onto the class itself. For instance, you can have an extending class
 * like `class Test extends Copyable<{ readonly id: number }> {}` and not have to define the
 * constructor, the `copy` method`, etc. You can then access the defined properties directly.
 * `const id = new Test({ id: 0 }).id` will be inferred correctly as number.
 * @param args The args `A` that we want to be attached onto the class itself
 */
// @ts-expect-error we are trying to create a class that essentially extends itself, hence the error
export const Copyable: CopyableConstructor = class<A> {
  readonly _keys: ReadonlyArray<keyof A>;

  constructor(readonly _args: A) {
    if (typeof _args === 'object' && _args != null) {
      const _keys = keys(_args);
      _keys.forEach((key) => {
        // @ts-expect-error typescript complains that keyof A is not keyof this, but A is this
        this[key] = _args[key];
      });
      this._keys = _keys;
    } else {
      this._keys = [];
    }
    Object.defineProperty(this, '_args', {
      enumerable: false,
      writable: false,
    });
    Object.defineProperty(this, '_keys', {
      enumerable: false,
      writable: false,
    });
  }

  copy(args: IsEqualTo<A, {}> extends true ? void : Partial<A>): this {
    // @ts-expect-error typescript can't infer `this.constructor` because it will be from the extending class
    return new this.constructor({ ...this._args, ...args });
  }
};
