import { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Dispatch } from 'vuex';
import VueRouter from 'vue-router';
import { showStandardHttpErrorNotification } from '@/utils/util';
import { SHOW_LOADER, HIDE_LOADER } from '@/store/actions/loader';
import { getIsAuthenticated } from './user.service';

const isLoaderEnabled = (config: AxiosRequestConfig): boolean => {
  return !(typeof config.loaderEnabled === 'boolean' && !config.loaderEnabled);
};

let requestQueue = 0;

// Set router instance as singleton so that Vuex modules
// using setupInterceptor aren't dependent on the router
let router: VueRouter | undefined;
export function setInterceptorRouter(instance: VueRouter) {
  router = instance;
}

export function setupInterceptors(
  dispatch: Dispatch,
  clients: AxiosInstance[],
) {
  const request = {
    pending() {
      requestQueue += 1;
      dispatch(SHOW_LOADER, null, { root: true });
    },

    done() {
      requestQueue -= 1;

      if (requestQueue <= 0) {
        dispatch(HIDE_LOADER, null, { root: true });
      }
    },
  };

  clients.forEach(addInterceptorsToClient.bind(undefined, request));
}

function addInterceptorsToClient(
  request: { pending(): void; done(): void },
  httpClient: AxiosInstance,
) {
  httpClient.interceptors.request.use(
    (config) => {
      if (isLoaderEnabled(config)) {
        request.pending();
      }
      return config;
    },
    (error) => {
      if (isLoaderEnabled(error.config)) {
        request.done();
      }

      if (error.config.notificationsEnabled) {
        showStandardHttpErrorNotification(error.response);
      }

      return Promise.reject(error.response ? error.response : error);
    },
  );
  httpClient.interceptors.response.use(
    (response) => {
      if (isLoaderEnabled(response.config)) {
        request.done();
      }
      return response;
    },
    (error: AxiosError) => {
      if (isLoaderEnabled(error.config)) {
        request.done();
      }

      if (error.config.notificationsEnabled) {
        showStandardHttpErrorNotification(error.response);
      }

      const {
        response: { status } = {},
        config: { url },
      } = error;
      if (
        router &&
        (status === 401 || status === 403) &&
        !url?.endsWith('/is-authenticated')
      ) {
        getIsAuthenticated().then((isAuthenticated) => {
          if (router && !isAuthenticated) {
            router.push({
              name: 'login',
              query: {
                redirect: router.currentRoute.fullPath,
                err: 'session-expired',
              },
            });
          }
        });
      }

      return Promise.reject(error.response ? error.response : error);
    },
  );
}
