import { AxiosResponse } from 'axios';

import { User, UserPrefs } from '@ax/data-services-user/models/user';
import { getItemInStorage } from '@/utils/storage';
import {
  getUser,
  updateUser,
  deleteUser,
  getUserPrefs,
  postUserPrefs,
  getUsers,
  unlockUserAccount,
} from '@/services/user.service';
import { RESET } from '@/store/actions';

import { AUTH_LOGOUT } from '@/store/actions/auth';

import {
  PREFERENCES_SET_ORGANIZATION,
  PREFERENCES_SET_ROLE,
} from '@/store/actions/preferences';
import { orderObjectsByAlpha } from '@/utils/util';

const REQUEST_IF_STALE = 'request_if_stale';
const REQUEST = 'request';
const RECEIVE = 'receive';
const RECEIVED = 'received';
const UPDATE = 'update';
const UPDATED = 'updated';
const REQUEST_PREFS = 'request_prefs';
const RECEIVED_PREFS = 'received_prefs';
const UPDATE_PREFS = 'update_prefs';
const UPDATED_PREFS = 'updated_prefs';
const UPDATE_ORG = 'update_org';
const REQUEST_ALL = 'request_all';
const RECEIVED_ALL = 'received_all';
const UPDATE_OTHER = 'update_other';
const UPDATED_OTHER = 'updated_other';
const UNLOCK_OTHER = 'unlock_other';
const UNLOCKED_OTHER = 'unlocked_other';
const REMOVE_OTHER = 'remove_other';
const REMOVED_OTHER = 'removed_other';
const ERROR = 'error';

const HALF_HOUR_IN_MS = 30 * 60 * 1000;

const initialState = () => ({
  preferences: null,
  profile: null,
  profilePromise: null,
  profileLoadDate: null,
  status: '',
  users: null,
});

const getters = {
  getProfile: (state) => new User(state.profile || {}),
  getProfileId: (state) => state.profile?.id,
  accountId: (state) => state.profile?.account_id,
  isLoading: (state): boolean => state.status === 'loading',
  getPreferences: (state): UserPrefs => state.preferences,
  getUsers: (state): User[] => state.users,
  isLoadingPrefs: (state): boolean => state.status === 'loading_prefs',
};

const actions = {
  [REQUEST_IF_STALE]: (
    { dispatch, state: { profilePromise, profileLoadDate }, getters },
    orgId?: string,
  ): Promise<User> => {
    if (profilePromise) {
      return profilePromise.then(() => getters.getProfile);
    }
    if (
      profileLoadDate === null ||
      Date.now() - profileLoadDate > HALF_HOUR_IN_MS
    ) {
      return dispatch(REQUEST, orgId);
    }
    return Promise.resolve(getters.getProfile);
  },

  [REQUEST]: (
    { commit, dispatch },
    orgId?: string,
  ): Promise<AxiosResponse | User> => {
    const promise = getUser()
      .then((response) => {
        return dispatch(RECEIVE, { user: response, orgId });
      })
      .catch((error) => {
        commit(ERROR, error);
        dispatch(AUTH_LOGOUT, { shouldRedirect: true }, { root: true });
        return Promise.reject(error);
      });

    commit(REQUEST, promise);
    return promise;
  },
  [RECEIVE]: async (
    { commit, dispatch, getters },
    { user, orgId }: { user: User; orgId?: string },
  ): Promise<User> => {
    commit(RECEIVED, user);
    const userProfile = getters.getProfile;
    await dispatch(UPDATE_ORG, { user: userProfile, orgId });
    return userProfile;
  },
  [UPDATE]: (
    { commit },
    { orgId, user }: { orgId: string | number; user: User },
  ): Promise<AxiosResponse> => {
    const promise = updateUser(orgId, user)
      .then((response) => {
        commit(UPDATED, user);

        return response;
      })
      .catch((error) => {
        commit(ERROR, error);

        return Promise.reject(error);
      });

    commit(UPDATE, promise);
    return promise;
  },
  [REQUEST_PREFS]: (
    { commit },
    orgId: string,
  ): Promise<AxiosResponse | UserPrefs> => {
    commit(REQUEST_PREFS);

    return getUserPrefs(orgId)
      .then((response) => {
        commit(RECEIVED_PREFS, response);

        return response;
      })
      .catch((error) => {
        commit(ERROR, error);

        return Promise.reject(error);
      });
  },
  [UPDATE_PREFS]: (
    { commit },
    { orgId, prefs }: { orgId: string | number; prefs: UserPrefs },
  ): Promise<AxiosResponse> => {
    commit(UPDATE_PREFS);

    return postUserPrefs(orgId, prefs)
      .then((response) => {
        commit(UPDATED_PREFS, prefs);

        return response;
      })
      .catch((error) => {
        commit(ERROR, error);

        return Promise.reject(error);
      });
  },
  [UPDATE_ORG]: (
    { dispatch },
    { user, orgId }: { user: User; orgId?: string | null },
  ) => {
    if (user.orgs) {
      const currentOrgId =
        orgId || getItemInStorage({ key: 'current-org', store: localStorage });
      const orgIdInt = currentOrgId ? parseInt(currentOrgId, 10) : null;

      const organization = orgIdInt
        ? user.orgs.find((org) => org.id === orgIdInt) || user.orgs[0]
        : user.orgs[0];
      const roleInOrg =
        organization &&
        user.rbac_roles &&
        user.rbac_roles.find(
          (role) => role.organization_id === (organization && organization.id),
        );
      return Promise.all([
        dispatch(PREFERENCES_SET_ORGANIZATION, organization, { root: true }),
        dispatch(PREFERENCES_SET_ROLE, roleInOrg, { root: true }),
      ]);
    }
    return Promise.resolve();
  },
  [REQUEST_ALL]: (
    { commit },
    orgId: string,
  ): Promise<AxiosResponse | User[]> => {
    commit(REQUEST_ALL);

    return getUsers(orgId)
      .then((response) => {
        commit(RECEIVED_ALL, response);

        return response;
      })
      .catch((error) => {
        commit(ERROR, error);

        return Promise.reject(error);
      });
  },
  [UPDATE_OTHER]: (
    { commit },
    { orgId, user }: { orgId: string | number; user: User },
  ): Promise<AxiosResponse> => {
    commit(UPDATE_OTHER);

    return updateUser(orgId, user)
      .then((response) => {
        commit(UPDATED_OTHER, { orgId, updatedUser: user });

        return response;
      })
      .catch((error) => {
        commit(ERROR, error);

        return Promise.reject(error);
      });
  },
  [REMOVE_OTHER]: (
    { commit },
    { orgId, userId }: { orgId: string | number; userId: number },
  ): Promise<AxiosResponse> => {
    commit(REMOVE_OTHER);

    return deleteUser(orgId, userId)
      .then((response) => {
        commit(REMOVED_OTHER, userId);

        return response;
      })
      .catch((error) => {
        commit(ERROR, error);

        return Promise.reject(error);
      });
  },
  [UNLOCK_OTHER]: (
    { commit },
    { orgId, userId }: { orgId: string | number; userId: string | number },
  ): Promise<AxiosResponse> => {
    commit(UNLOCK_OTHER);

    return unlockUserAccount(orgId, userId)
      .then((response) => {
        commit(UNLOCKED_OTHER, userId);

        return response;
      })
      .catch((error) => {
        commit(ERROR, error);

        return Promise.reject(error);
      });
  },
};

const mutations = {
  [REQUEST]: (state, promise) => {
    state.profilePromise = promise;
    state.status = 'loading';
  },
  [RECEIVED]: (state, user: User) => {
    state.profile = {
      ...user,
      orgs: orderObjectsByAlpha(user.orgs, 'name'),
    };
    state.profileLoadDate = Date.now();
    state.profilePromise = null;
    state.status = 'success';
  },
  [UPDATE]: (state, promise) => {
    state.profilePromise = promise;
    state.status = 'updating';
  },
  [UPDATED]: (state, user: User) => {
    state.profile = {
      ...user,
      orgs: orderObjectsByAlpha(user.orgs, 'name'),
    };
    state.profileLoadDate = Date.now();
    state.profilePromise = null;
    state.status = 'updated';
  },
  [REQUEST_PREFS]: (state) => {
    state.status = 'loading_prefs';
  },
  [RECEIVED_PREFS]: (state, preferences: UserPrefs) => {
    state.preferences = preferences;
    state.status = 'success';
  },
  [UPDATE_PREFS]: (state) => {
    state.status = 'updating_prefs';
  },
  [UPDATED_PREFS]: (state, preferences: UserPrefs) => {
    state.preferences = preferences;
    state.status = 'success';
  },
  [REQUEST_ALL]: (state) => {
    state.status = 'loading';
  },
  [RECEIVED_ALL]: (state, users: User[]) => {
    state.users = users;
    state.status = 'success';
  },
  [UPDATE_OTHER]: (state) => {
    state.status = 'updating';
  },
  [UPDATED_OTHER]: (
    state,
    { orgId, updatedUser }: { orgId: string | number; updatedUser: User },
  ) => {
    state.status = 'updated';
    const users = state.users.map((user) => {
      if (user.id !== updatedUser.id) {
        return user;
      }
      const rbac_roles = user.rbac_roles
        ? user.rbac_roles.map((role) =>
            role.organization_id === parseInt(orgId as string, 10)
              ? { ...role, name: updatedUser.rbac_role }
              : role,
          )
        : [];

      return { ...user, rbac_roles };
    });
    state.users = users;
  },
  [REMOVE_OTHER]: (state) => {
    state.status = 'removing';
  },
  [REMOVED_OTHER]: (state, userId: number) => {
    state.status = 'removed';
    const users = state.users.filter((user) => user.id !== userId);

    state.users = users;
  },
  [UNLOCK_OTHER]: (state) => {
    state.status = 'unlocking';
  },
  [UNLOCKED_OTHER]: (state, userId: string | number) => {
    state.status = 'unlocked';
    const users = state.users.map((user) =>
      user.id === userId ? { ...user, locked: false } : user,
    );
    state.users = users;
  },
  [ERROR]: (state, error) => {
    state.error = error;
    state.profilePromise = null;
    state.status = 'error';
  },
  [RESET]: (state) => {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations,
};
