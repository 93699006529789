import {
  removeEmptyEntriesFromShallowObject,
  UnknownRecord,
} from '@ax/object-utils';
import {
  showErrorNotification,
  showStandardHttpErrorNotification,
} from '@ax/notifications';
import { IStringifyOptions, stringify } from 'qs';
import { AnyPrimitive } from '@/types/utility-types';
import { DeviceGroupWithHierarchy, DeviceGroup } from '@/models/device-group';

type SortableStringRecord<T> = { [K in keyof T]: T[K] & string };
type ArrayTree<T> = Array<T & { children?: T[] }>;
type FlattenedTree<T> = Array<
  T & { depth: number; parent_labels: Array<keyof T> }
>;

// Utility type to set certain keys as optional
export type Optional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function groupBy(arr: any[] = [], key: string): object {
  return arr.reduce((collection, item) => {
    (collection[item[key]] = collection[item[key]] || []).push(item);

    return collection;
  }, {});
}

export function orderObjectsByAlpha<T>(
  values: T[] = [],
  prop: keyof T,
  exception?: RegExp,
): T[] {
  return values.sort((a, b) => {
    if (exception && exception.test((a as SortableStringRecord<T>)[prop])) {
      return -1;
    }
    if (exception && exception.test((b as SortableStringRecord<T>)[prop])) {
      return 1;
    }
    return (a as SortableStringRecord<T>)[prop].localeCompare(
      (b as SortableStringRecord<T>)[prop],
    );
  });
}

export function orderByAlpha(values: string[]): string[] {
  return values.sort((a, b) => a.localeCompare(b));
}

export function convertToUnit(
  str: string | number | null | undefined,
  unit = 'px',
): string | undefined {
  if (str == null || str === '') {
    return undefined;
  }
  if (Number.isNaN(+str!)) {
    return String(str);
  }
  return `${Number(str)}${unit}`;
}

function sortNodeAndChildren<T extends SortableStringRecord<T>>(
  nodes: ArrayTree<T>,
  key: keyof T & string,
): ArrayTree<T> {
  const sorted = orderObjectsByAlpha(nodes, key);
  sorted.forEach((node) => {
    if (node.children) {
      sortNodeAndChildren(node.children, key);
    }
  });

  return sorted;
}

export function arrayToTree<T extends { id?: number; name?: string }>(
  arr: T[],
  key: keyof T & string,
): ArrayTree<T> {
  type TreeMap = { [K in keyof T]: T & { children: T[] } };
  const map: TreeMap = arr.reduce((mapped, node) => {
    return {
      ...mapped,
      [node.id!]: { ...node, children: [] },
    };
  }, {} as TreeMap);

  const root = Object.keys(map).reduce((tree, id) => {
    const element = map[id];
    if (element[key] !== element.id) {
      map[element[key]].children!.push(element);

      return tree;
    }
    return [...tree, element];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }, [] as any[]);

  return sortNodeAndChildren(root, 'name');
}

export function flatDeep<T>(
  arr: ArrayTree<T> | FlattenedTree<T>,
  labelKey: keyof T,
  depth = 0,
  parent_labels: Array<keyof T> = [],
): FlattenedTree<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (arr as any[]).reduce((acc, item) => {
    let collection = [...acc];
    const next = {
      ...item,
      depth: Math.max(0, depth - 1),
      ...(item.parent_labels
        ? { parent_labels: [...item.parent_labels, ...parent_labels] }
        : { parent_labels: [...parent_labels] }),
      has_subgroups: item.children && item.children.length > 0,
    };
    collection.push(next);

    if (item.children && item.children.length > 0) {
      collection = collection.concat(
        flatDeep(item.children, labelKey, depth + 1, [
          ...next.parent_labels,
          ...(next[labelKey] ? [next[labelKey]] : []),
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ] as any),
      );
    }

    return collection;
  }, [] as FlattenedTree<T>);
}

export function getGroupHierarchy(
  groups: DeviceGroup[],
): DeviceGroupWithHierarchy[] {
  const tree = arrayToTree(groups, 'parent_server_group_id');
  return flatDeep(tree, 'name');
}

// an implementation of https://vuetifyjs.com/en/components/data-tables/#custom-filter
export function useMultiFilterTableSearch(
  val: any, // eslint-disable-line @typescript-eslint/no-explicit-any
  term: string | null,
  target: any, // eslint-disable-line @typescript-eslint/no-explicit-any
  allInclusive = true,
): boolean {
  if (!term || !target) {
    return false;
  }

  // split the value from a string of strings into an array
  const searchTerms = term.split('" "').map((s) => s.replace(/"/g, ''));
  return allInclusive
    ? targetIncludesAllTerms(target, searchTerms)
    : targetIncludesTerms(target, searchTerms);
}

export function arePrimitiveArraysEqual(
  a: Array<AnyPrimitive> = [],
  b: Array<AnyPrimitive> = [],
): boolean {
  if (a === b) {
    return true;
  }

  return a.length === b.length && a.every((_a, index) => b[index] === _a);
}

/**
 * @deprecated DON'T USE, use Axios params instead or URLSearchParams where
 * URL param formatting is needed but Axios isn't used.
 *
 * Use to stringify an object of query params.
 * @param query An object of query params
 * @param options
 * @returns `string`
 */
export function stringifyUrlQuery(
  query: UnknownRecord,
  options?: IStringifyOptions,
): string {
  return stringify(removeEmptyEntriesFromShallowObject(query), options);
}

export {
  /**
   * @deprecated DON'T USE! Instead Use:
   * import { showStandardHttpErrorNotification } from '@ax/notifications';
   */
  showStandardHttpErrorNotification,
  /**
   * @deprecated DON'T USE! Instead Use:
   * import { removeEmptyEntriesFromShallowObject } from '@ax/object-utils';
   */
  removeEmptyEntriesFromShallowObject,
};

export function showStandardCSVExportErrorNotification(
  message = 'Error: This browser does not support downloading CSV files.',
): void {
  showErrorNotification(message);
}

// target must include at least one term from the array
export function targetIncludesTerms(target: string, terms: string[]): boolean {
  return terms.some((term) =>
    target.toLowerCase().includes(term.toLowerCase()),
  );
}

// target must include all terms from the array
export function targetIncludesAllTerms(
  target: string,
  terms: string[],
): boolean {
  return terms.every((term) =>
    target.toLowerCase().includes(term.toLowerCase()),
  );
}
