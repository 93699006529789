import {
  Lazy,
  MaybeLazy,
  TimeoutError,
  unwrapMaybeLazy,
} from '@ax/function-utils';
import { UnknownFetchError } from '../query-fetcher/unknown-interpreter-error';
import { AnyQuery } from '../query/definition';
import { ShapeOfError, ShapeOfSuccess } from '../query';

/**
 * `MaybeDataBase` is a base class for `QueryStatus`. We are utilizing a function for data
 * because we might want to have the status read from a data store vs.
 * supply the data directly from a query response. This will enable more
 * powerful features in the future.
 */
abstract class MaybeDataBase<A> {
  readonly createdAt = Date.now();

  constructor(readonly maybeData: MaybeLazy<A | undefined>) {}

  get data(): A | undefined {
    return unwrapMaybeLazy(this.maybeData);
  }
}

/**
 * Represents an initial state for a `Query`. This means that a `Query` has been
 * created, but it has not been sent yet or otherwise processed.
 */
export class Empty<A> extends MaybeDataBase<A> {
  readonly type = 'Empty';
}

/**
 * Create an `Empty<A>` query status
 * @param maybeData `A`
 * @returns `Empty<A>`
 */
export function empty<A>(maybeData?: MaybeLazy<A | undefined>) {
  return new Empty(maybeData);
}

/**
 * Represents the loading state for a `Query`
 */
export class Loading<A> extends MaybeDataBase<A> {
  readonly type = 'Loading';
}

/**
 * Create a `Loading<A>` query status
 * @param maybeData `A`
 * @returns `Loading<A>`
 */
export function loading<A>(maybeData?: MaybeLazy<A | undefined>) {
  return new Loading(maybeData);
}

/**
 * Represents the failure state for a `Query`. Note that even in a `Failure`
 * state, we might still have data to display from a previously successful
 * `Query`.
 */
export class Failure<A, E> extends MaybeDataBase<A> {
  readonly type = 'Failure';

  constructor(maybeData: MaybeLazy<A | undefined>, readonly error: E) {
    super(maybeData);
  }
}

/**
 * Create a `Failure<A, E>` query status
 * @param error the failure's error
 * @param maybeData the potential data
 * @returns `Failure<A, E>`
 */
export function failure<A, E>(error: E, maybeData?: MaybeLazy<A | undefined>) {
  return new Failure(maybeData, error);
}

/**
 * The base class for `Success<A>` and `Refreshing<A>`. In these states, we have a successful
 * response which means the query status will have data.
 */
abstract class WithDataBase<A> {
  readonly createdAt = Date.now();

  constructor(readonly maybeData: A | Lazy<A | undefined>) {}

  /**
   * If a query is successful or refreshing, the data will exist so we are
   * casting to just A.
   */
  get data(): A {
    const value = unwrapMaybeLazy(this.maybeData);
    if (value === undefined) {
      throw new Error(
        'Data must be defined when in a success or refreshing state',
      );
    }
    return value;
  }
}

/**
 * Represents the refreshing state for a `Query<A>`.
 */
export class Refreshing<A> extends WithDataBase<A> {
  readonly type = 'Refreshing';
}

/**
 * Create a `Refreshing<A>` query status
 * @param maybeData the data for the query status. We use a `Lazy<A | undefined>` to preserve
 * the same API as the other query statuses, although we will enforce that the data exists
 * in the base class.
 * @returns `Refreshing<A>`
 */
export function refreshing<A>(maybeData: A | Lazy<A | undefined>) {
  return new Refreshing(maybeData);
}

/**
 * Represents the successful state for a `Query<A>`.
 */
export class Success<A> extends WithDataBase<A> {
  readonly type = 'Success';
}

/**
 * Create a `Success<A>` query status
 * @param maybeData the data for the query status. We use a `Lazy<A | undefined>` to preserve
 * the same API as the other query statuses, although we will enforce that the data exists
 * in the base class.
 * @returns `Success<A>`
 */
export function success<A>(maybeData: A | Lazy<A | undefined>) {
  return new Success(maybeData);
}

export type QueryStatus<A, E> =
  | Empty<A>
  | Loading<A>
  | Success<A>
  | Refreshing<A>
  | Failure<A, E>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyQueryStatus = QueryStatus<any, any>;

/**
 * We add `TimeoutError` and `UnknownFetchError` as potential errors in addition
 * to those defined on `A`.
 */
export type QueryStatusErrorFromQuery<A extends AnyQuery> =
  | ShapeOfError<A>
  | TimeoutError
  | UnknownFetchError;

/**
 * Type utility to create a `QueryStatus` from a query `A`.
 */
export type QueryStatusFromQuery<A extends AnyQuery> = QueryStatus<
  ShapeOfSuccess<A>,
  QueryStatusErrorFromQuery<A>
>;

/**
 * Check if the `QueryStatus<A, E>` is in an empty state
 */
export function isEmpty<A, E>(status: QueryStatus<A, E>): status is Empty<A> {
  return status.type === 'Empty';
}

/**
 * Check if the `QueryStatus<A, E>` is in a loading state
 */
export function isLoading<A, E>(
  status: QueryStatus<A, E>,
): status is Loading<A> {
  return status.type === 'Loading';
}

/**
 * Check if the `QueryStatus<A, E>` is in a refreshing state
 */
export function isRefreshing<A, E>(
  status: QueryStatus<A, E>,
): status is Refreshing<A> {
  return status.type === 'Refreshing';
}

/**
 * Check if the `QueryStatus<A, E>` is in a failure state
 */
export function isFailure<A, E>(
  status: QueryStatus<A, E>,
): status is Failure<A, E> {
  return status.type === 'Failure';
}

/**
 * Check if the `QueryStatus<A, E>` is in a success state
 */
export function isSuccess<A, E>(
  status: QueryStatus<A, E>,
): status is Success<A> {
  return status.type === 'Success';
}
