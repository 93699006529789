import { MaybeLazy } from '@ax/function-utils';
import * as H from '../hash';
import { AnyQuery } from '../query/definition';
import { QueryStatusFromQuery } from '../query-status';
import { ShapeOfSuccess } from '../query';
import { QueryStatusCacheEntry } from './query-cache-entry';

export interface QueryCacheConfig {
  /**
   * The maximum size of the cache.
   */
  readonly size: number;
}

/**
 * A `QueryCache<A>` is a LRU cache for queries of type `A` bounded by a given
 * size. The primary way in which a consumer will interact with
 * a `QueryCache<A>` is through the `getOrCreate` method which will either
 * return a `QueryStatusCacheEntry<A>` for a given query `A` if the entry already
 * exists or will otherwise create a new entry.
 */
export class QueryCache<A extends AnyQuery> {
  /**
   * The underlying map of `Hash<A> -> QueryStatusCacheEntry<A>`
   */
  readonly #cache = new Map<number, QueryStatusCacheEntry<A>>();

  /**
   * The maximum size the cache will grow to. Entries added that
   * cause the size to grow beyond this threshold will force
   * the deletion of least recently used entries.
   */
  readonly #maxSize: number;

  constructor(config: QueryCacheConfig) {
    this.#maxSize = config.size < 1 ? 1 : config.size;
  }

  static make = <Q extends AnyQuery>(config: QueryCacheConfig) =>
    new QueryCache<Q>(config);

  /**
   * Get the current number of entries in the cache.
   */
  get size() {
    return this.#cache.size;
  }

  /**
   * Remove entries from the cache if over the specified
   * size in `config`
   */
  #checkSize() {
    if (this.#cache.size > this.#maxSize) {
      this.#cache.delete(this.#cache.keys().next().value);
    }
  }

  /**
   * lookup a `Query`'s current status along with hash
   * @param query `Query`
   * @returns [hash, QueryStatus]
   */
  #lookup(query: A) {
    const hash = H.hash(query);
    return [hash, this.#cache.get(hash)] as const;
  }

  /**
   * Get the current entry for a query `A` or `undefined` if one does
   * not exist.
   * @param query
   * @returns `QueryStatusCacheEntry<A> | undefined`
   */
  get(query: A) {
    return this.#cache.get(H.hash(query));
  }

  /**
   * Map keys are stored in insertion order based on the first insertion
   * for a given key. If we delete the key here and then add it back,
   * we can ensure that the first key when you call `this.cache.keys()`
   * will be the oldest in the cache. This allows us to not have to separately
   * keep track of the insertion order and still preserves `O(1)` set time.
   */
  #moveToEndOfQueue(hash: number, currentStatus: QueryStatusCacheEntry<A>) {
    this.#cache.delete(hash);
    this.#cache.set(hash, currentStatus);
  }

  /**
   * Get a `QueryStatusCacheEntry<A>` for a given query `A`. If the entry does
   * not already exist, a new one will be created and added to the cache.
   * @param query
   * @param maybeData used if we have to create a new `QueryStatus`
   * @returns `QueryStatusCacheEntry<A>`
   */
  getOrCreate(query: A, maybeData?: MaybeLazy<ShapeOfSuccess<A> | undefined>) {
    const [hash, currentEntry] = this.#lookup(query);
    if (currentEntry) {
      /**
       * Move to end of insertion order queue
       */
      this.#moveToEndOfQueue(hash, currentEntry);
      return currentEntry;
    }
    const newEntry = QueryStatusCacheEntry.make(maybeData);
    this.#cache.set(hash, newEntry);
    this.#checkSize();
    return newEntry;
  }

  /**
   * Updates the `QueryStatus` for `Query`
   * @param query `Query`
   * @param status new `QueryStatus` for the query
   */
  set(query: A, status: QueryStatusFromQuery<A>) {
    const [hash, currentStatus] = this.#lookup(query);
    if (currentStatus) {
      /**
       * Set value of ref to new status
       */
      currentStatus.status = status;
      this.#moveToEndOfQueue(hash, currentStatus);
    } else {
      this.#cache.set(hash, QueryStatusCacheEntry.fromStatus(status));
    }
    this.#checkSize();
  }

  /**
   * Delete the `Ref<QueryStatusFromQuery<T>>` associated with
   * the given `Query`
   */
  delete(query: A) {
    this.#cache.delete(H.hash(query));
  }

  /**
   * Clear the cache
   */
  clear() {
    this.#cache.clear();
  }
}
