import { AxiosResponse } from 'axios';

import { DeviceGroup } from '@/models/device-group';
import {
  getDeviceGroups,
  postAssignDeviceGroup,
} from '@/services/device-groups.service';
import { RESET } from '@/store/actions';
import { orderObjectsByAlpha } from '@/utils/util';

const REQUEST = 'request';
const RECEIVED = 'received';
const ASSIGN = 'assign';
const ERROR = 'error';

const initialState = () => ({
  error: {},
  groups: [],
  status: 'loading',
});

const getters = {
  getDeviceGroups: (state): DeviceGroup[] => state.groups,
  isLoading: (state): boolean => state.status === 'loading',
};

const actions = {
  [REQUEST]: (
    { commit },
    orgId: string | number,
  ): Promise<DeviceGroup[] | void> => {
    commit(REQUEST);

    return getDeviceGroups(orgId)
      .then((response) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const groups = orderObjectsByAlpha(response as any[], 'name');
        commit(RECEIVED, { groups });

        return groups;
      })
      .catch((error) => {
        commit(ERROR, error);
      });
  },
  [ASSIGN]: (
    { commit },
    {
      orgId,
      deviceGroupId,
      deviceIds,
    }: {
      orgId: string | number;
      deviceGroupId: number;
      deviceIds: string[] | number[];
    },
  ): Promise<AxiosResponse> => {
    return postAssignDeviceGroup(orgId, deviceGroupId, deviceIds)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        commit(ERROR, error.response);

        return Promise.reject(error.response);
      });
  },
};

const mutations = {
  [REQUEST]: (state) => {
    state.status = 'loading';
  },
  [RECEIVED]: (state, groups: DeviceGroup[]) => {
    state.groups = groups;
    state.status = 'success';
  },
  [ERROR]: (state, error) => {
    state.error = error;
    state.status = 'error';
  },
  [RESET]: (state) => {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations,
};
