import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  CancelTokenSource,
} from 'axios';
import {
  Task,
  Job,
  TaskBatch,
  TaskBatchIssue,
  VulnSyncFormatsResponse,
  TaskBatchSingle,
} from '@/models/task';
import { PaginatedResponse } from '@/models/pagination';
import { AxFile } from '@/models/file';
import {
  showStandardHttpErrorNotification,
  stringifyUrlQuery,
} from '@/utils/util';
import httpClient from './http-client';

export const TASKS_ENDPOINT = 'tasks';
export const TASKS_BATCHES_ENDPOINT = 'tasks/batches';

/**
 * @deprecated New code should create a version with a query object instead of string in tasks.service.ts
 */
export function getTasksList(
  orgId: number,
  query: string,
): Promise<PaginatedResponse<Task>> {
  return httpClient
    .get(`/orgs/${orgId}/${TASKS_ENDPOINT}?${query}`)
    .then(({ data }) => data);
}

/**
 * @deprecated New code should create a version with a query object instead of string in tasks.service.ts
 */
export function getSingleTask(orgId: number, taskId: number): Promise<Task> {
  const query = stringifyUrlQuery({ o: orgId });

  return httpClient
    .get(`/orgs/${orgId}/${TASKS_ENDPOINT}/${taskId}?${query}`)
    .then(({ data }) => data);
}

/**
 * @deprecated New code should use initiateTaskAction in tasks.service.ts
 */
export function patchJob(
  orgId: number,
  action: string,
  taskId: number,
): Promise<AxiosResponse> {
  const query = stringifyUrlQuery({ o: orgId });

  return httpClient
    .patch(`/orgs/${orgId}/${TASKS_ENDPOINT}/${taskId}?${query}`, { action })
    .then(({ data }) => data);
}

/**
 * @deprecated New code should create a version with a query object instead of string in tasks.service.ts
 */
export function getJobsPackages(
  orgId: number,
  taskId: number,
  query: string,
): Promise<PaginatedResponse<Job>> {
  return httpClient
    .get(`/orgs/${orgId}/${TASKS_ENDPOINT}/${taskId}/jobs?${query}`)
    .then(({ data }) => data);
}

/**
 * @deprecated New code should use getBatches from tasks.service.ts
 */
export function getTasksBatches(
  orgId: number,
  query: string,
): Promise<PaginatedResponse<TaskBatch>> {
  return httpClient
    .get(`/orgs/${orgId}/${TASKS_BATCHES_ENDPOINT}?${query}`)
    .then(({ data }) => data);
}

/**
 * @deprecated New code should use getBatch from tasks.service.ts
 */
export function getSingleBatch(
  orgId: number,
  batchId: number,
): Promise<TaskBatchSingle> {
  const query = stringifyUrlQuery({ o: orgId });

  return httpClient
    .get(`/orgs/${orgId}/${TASKS_BATCHES_ENDPOINT}/${batchId}?${query}`)
    .then(({ data }) => data);
}

/**
 * @deprecated New code should use acceptBatch from task.service.ts
 */
export function acceptTaskBatch(
  orgId: number,
  batchId: number,
): Promise<PaginatedResponse<Job>> {
  const query = stringifyUrlQuery({ o: orgId });

  return httpClient
    .post(`/orgs/${orgId}/${TASKS_BATCHES_ENDPOINT}/${batchId}/accept?${query}`)
    .then(({ data }) => data);
}

/**
 * @deprecated New code should create a version with a query object instead of string in tasks.service.ts
 */
export function rejectTaskBatch(
  orgId: number,
  batchId: number,
): Promise<PaginatedResponse<Job>> {
  const query = stringifyUrlQuery({ o: orgId });

  return httpClient
    .post(`/orgs/${orgId}/${TASKS_BATCHES_ENDPOINT}/${batchId}/reject?${query}`)
    .then(({ data }) => data);
}

/**
 * @deprecated New code should use getBatchIssues in tasks.service.ts
 */
export function getTaskBatchIssues(
  orgId: number,
  batchId: number,
  query: string,
): Promise<PaginatedResponse<TaskBatchIssue>> {
  return httpClient
    .get(`/orgs/${orgId}/${TASKS_BATCHES_ENDPOINT}/${batchId}/issues?${query}`)
    .then(({ data }) => data);
}

/**
 * @deprecated New code should create a version with a query object instead of string in tasks.service.ts
 */
export function uploadTaskBatch(
  orgId: string,
  file: AxFile,
  source: string,
  onUploadProgress: (event: ProgressEvent) => void,
  taskType = 'patch',
): [CancelTokenSource, Promise<TaskBatch>] {
  const cancelToken = axios.CancelToken.source();

  const formData = new FormData();
  formData.append('file', file.originalFile!);

  const query = stringifyUrlQuery({ o: orgId, source });

  return [
    cancelToken,
    httpClient
      .post<TaskBatch>(
        `/orgs/${orgId}/${TASKS_ENDPOINT}/${taskType}/batches/upload?${query}`,
        formData,
        {
          onUploadProgress,
          loaderEnabled: false,
          cancelToken: cancelToken.token,
        } as AxiosRequestConfig,
      )
      .then(({ data }) => data)
      .catch((error) => {
        if (error) {
          showStandardHttpErrorNotification(error);
        }

        throw error;
      }),
  ];
}

/**
 * @deprecated New code should create a version with a query object instead of string in tasks.service.ts
 */
export function getVulnSyncFormats(
  orgId: string,
  disableLoader?: boolean,
  taskType = 'patch',
): Promise<VulnSyncFormatsResponse> {
  const query = stringifyUrlQuery({ o: orgId });
  return httpClient
    .get(
      `/orgs/${orgId}/${TASKS_ENDPOINT}/${taskType}/batches/formats?${query}`,
      {
        loaderEnabled: !disableLoader,
      } as AxiosRequestConfig,
    )
    .then(({ data }) => data);
}

/**
 * @deprecated New code should create a version with a query object instead of string in tasks.service.ts
 */
export function removeTasks(
  orgId: number,
  taskIds: string[],
): Promise<AxiosResponse> {
  const query = stringifyUrlQuery({ o: orgId });

  return httpClient.delete(`/orgs/${orgId}/${TASKS_ENDPOINT}?${query}`, {
    data: { ids: taskIds },
  });
}
