import {
  AccountRbacRole,
  AccountRoleName,
} from '@ax/api-clients-accounts/models';
import { User } from '../models/user';

// cache object for getRoleNameInOrg: userId -> [ orgId -> roleName ]
const orgRoleMap = new Map<number, Map<number, string | undefined>>();
/**
 * Get the name of a user's role in a given organization
 * @param user the user in question
 * @param orgId the org ID in question
 * @returns the `user`s role name for the given org ID.
 */
export function getRoleNameInOrg(
  user: User,
  orgId: number,
): string | undefined {
  if (!user.id) {
    throw new Error(
      'Cannot get role name for under-defined user object (missing id)',
    );
  }
  if (user.is_global_admin) {
    return AccountRoleName[AccountRbacRole.GlobalAdmin];
  }

  // return cached role name if it exists
  if (orgRoleMap.has(user.id)) {
    return orgRoleMap.get(user.id)!.get(orgId);
  }

  // compute role name and cache it
  orgRoleMap.set(
    user.id,
    user.orgs.reduce((_roleMap, org) => {
      const role = user.rbac_roles?.find(
        (rbacRole) => rbacRole.organization_id === org.id,
      );
      if (role) {
        _roleMap.set(org.id, role.name);
      }
      return _roleMap;
    }, new Map<number, string | undefined>()),
  );
  // return freshly cached role name
  return orgRoleMap.get(user.id)!.get(orgId);
}
