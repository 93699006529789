import { Timestamp } from './types';

/**
 * Convert a timestamp string into a date
 * @param timestamp the string representing a timestamp
 * @returns Date
 */
export function fromTimestamp(timestamp: Timestamp) {
  return new Date(timestamp);
}
