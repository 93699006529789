import auth from '@ax/locales-auth/auth.en-US.json';
import devices from '@ax/locales-devices/devices.en-US.json';
import general from '@ax/locales-general/general.en-US.json';
import integrations from '@ax/locales-integrations/integrations.en-US.json';
import settings from '@ax/locales-settings/settings.en-US.json';
import setup from '@ax/locales-setup/setup.en-US.json';
import reports from '@ax/locales-reporting/reporting.en-US.json';
import configurations from '@ax/locales-configurations/configurations.en-US.json';
import remoteControl from '@ax/locales-remote-control/remote-control.en-US.json';
import tasks from '@ax/locales-tasks/tasks.en-US.json';
import policies from '@ax/locales-policies/policies.en-US.json';
import worklets from '@ax/locales-worklets/worklets.en-US.json';

// TODO: This can be added back/re-imported/keys-generated
// dynamically once the type is extracted out of console, and into a workspace
// import { ProvisionType } from '../models/authentication';

export default {
  // New pattern: import from locales workspaces
  auth,
  devices,
  general,
  integrations,
  settings,
  setup,
  reports,
  configurations,
  remoteControl,
  tasks,
  policies,
  worklets,
  // Legacy pattern
  dashboard: {
    breadcrumb: 'Dashboard',
  },
  global: {
    'accountId:label': 'Account ID',
    'accountName:labelWithValue': 'Account Name: {name}',
    nav: {
      zoneSwitcher: {
        loading: 'Loading Zones...',
        placeholder: 'Select a Zone',
        noItems: 'No results.',
        searchLabel: 'Search',
        error: {
          loadZone: 'Error: Could not load zone',
          loadZones: 'Error: Could not load zones',
        },
        roleName: 'Role: {name}',
      },
      'trialCountdown:notification':
        'day until\ntrial expires | days until\ntrial expires',
      'trialCountdown:button': 'Upgrade Now',
      'trialExpired:notification': 'Trial\nExpired',
      'zoneDisabled:notification': 'Zone disabled',
    },
  },
  onboarding: {
    nav: {
      0: 'Set up your account',
      1: 'Add a device',
      2: 'Scan for updates',
      3: 'Review scan results',
      4: 'Patch your device',
    },
    buttons: {
      quickStart: 'view the quick start guide',
      help: 'Having issues? Click {0} to troubleshoot.',
      helpLink: 'here',
      addADevice: 'add your first device',
      scanForUpdates: 'scan for updates',
      patchYourDevice: 'patch your device',
      runPolicy: 'run policy',
      createAGroup: 'create a group',
      createAPolicy: 'create a policy',
      checkForDevices: 'Check for devices',
      checkingForDevices: 'Checking for devices...',
      dismissal: 'Dismiss Guide',
      viewAll: 'View All',
    },
    step0: {
      heading: 'Let’s add and scan your first device with Automox',
      subheading: 'Welcome to Automox, {name}!',
      body: 'In under 15 minutes, we’ll show you how easy it is to add a device, scan it for available updates, and be ready to patch it. {name} is going to be so happy you did.',
    },
    step1: {
      heading: 'Add a device',
      subheading: 'Install the Automox agent to begin managing your devices',
      body1:
        'Install the Automox agent on each device you want to manage. This is your unique user key. Keep it handy to use during the installation process.',
      body2:
        'After you install this on all of the devices that you want to scan, click NEXT: Scan for Updates to continue.',
      selectOs: 'Choose Your OS',
      selectOsHint: 'OS is pre-selected based on the OS you are using',
      'noDevices:heading': 'No devices found yet',
      'foundDevices:heading': 'We found {n} device | We found {n} devices',
      'checkin:description': 'Once a device checks in we’ll display it here.',
      'checkinSuccess:description':
        'Now that a device has checked in you are able to scan for available updates.',
      'lastCheckedAt:label': 'Last checked at {time}',
      'aboutAgent:heading': 'About the Automox agent',
      'aboutAgentLink:label': 'Learn more',
      'aboutAgent:description':
        'An agent is installed on each managed device. Automox uses a single agent for all Windows, macOS and Linux systems. At under 10 MB, the Automox agent has zero impact on the end user.',
    },
    step2: {
      heading: 'Scanning for updates...',
      subheading:
        'Automox is currently doing magic behind the scenes. This can take up to 6 minutes.',
      body1: 'Scanning is taking longer than usual',
      body2:
        'We’re still scanning but it may take a bit longer so feel free to get a cup of coffee or do another quick task -- we’ll send you an email as soon as we have your results.',
      imgAlt:
        'Trusted by more than 2,000 leading companies and MSPs worldwide.',
    },
    step3: {
      'done:heading': 'Scan results',
      subheading: 'Here are the results of your device scan',
      'heading:description':
        'We have identified the following devices and available updates.',
      'stillScanning:label':
        '{n} device still scanning... | {n} devices still scanning...',
      'macWarning:label':
        'Apple does not provide severity levels for MacOS vulnerabilities',
      'totalDevices:label': 'Devices',
      'vulnerabilities:label': 'Available Patches',
      'vulnerabilities:description':
        'The total number of software instances needing updating',
      'criticalVulnerabilities:label': 'Critical Patches',
      'criticalVulnerabilities:description':
        'The total number of software instances needing updating where severity is critical',
      footer: {
        'found:heading': 'Looks like we found available updates',
        'found:description':
          "Don't worry though, that's what we're here for. Automox can help you effortlessly patch your devices and automate the process to ensure they always stay up-to-date.",
        'congrats:heading': 'Congratulations!',
        'congrats:description':
          'No available updates were discovered during the scan. The next step is to begin organizing your devices and creating patch policies to automate patching and ensure your devices are always up-to-date.',
        'failure:heading': 'Well... this is awkward.',
        'failure:description':
          'We were not able to find any devices with the Automox agent present. This is most likely due to an incorrect installation. You can try to install the agent again, or head over to our Getting Started Guide for in-depth instructions on how to get the agent installed properly.',
        'inProgress:heading': 'So far so good!',
        'inProgress:description':
          "No existing updates have been found, but we haven't finished scanning all devices. If we find any updates, you'll find them from your dashboard. New exploits are discovered every day, so check back often. In fact, only 14% of small businesses rate their ability to mitigate cyber risks, vulnerabilities and attacks as highly effective. With Automox, you can instantly adapt to changing requirements using a cloud-native platform built for a continuously evolving IT landscape.",
      },
    },
    step4: {
      heading: 'Patch your device',
      subheading:
        'If your device has vulnerabilities, Automox is here to help.',
      'main:heading': 'What policy do you want to run?',
      'main:description':
        'Policies are rules to define how, when, and on what systems you want automated patching to run. Many Automox users use "Apply all patches" to make automation simple.',
      policy: {
        'all:heading': 'Apply all patches',
        'all:description':
          'Install all available updates for supported software on your devices',
        'custom:heading': 'Create your own',
        'custom:description':
          'Build a custom policy to apply updates to your devices',
        'critical:heading': 'Apply critical patches',
        'critical:description':
          'Install only critical updates for supported software on your devices',
      },
      banner: {
        patch: 'patch | patches',
        device: 'device | devices',
        'all:heading': 'Ready to apply {0} total {2} on your {1} {3}?',
        'all:description':
          'Automox will patch the software that it natively supports. Note that some updates may require a reboot to apply updates.',
        'custom:heading':
          'Unlock the full power of Automox with a customized policy',
        'custom:description':
          'If you’re feeling ambitious and want to take policies one step further, Automox offers advanced settings to fine-tune patching. Target specific devices using groups and tags, and then utilize powerful setting options to customize scheduling, notifications, reboot options and more.',
        'critical:heading':
          'Ready to apply {0} total critical {2} on your {1} {3}?',
        'critical:description':
          'Automox will patch the software that it natively supports. Note that some updates may require a reboot to apply updates.',
      },
    },
    modal: {
      heading: 'Your devices are being patched',
      body: 'In just a few clicks, you successfully added a device and ran a policy to patch it. Congratulations! If you would like to make sure your devices are patched on a schedule, set up an automated policy.',
      automationButton: 'Schedule a policy',
      devicesButton: 'Add more devices',
      readyText: 'I’m ready to explore the Automox console',
    },
  },
  org: {
    authenticationRequired:
      'The zone you selected requires authentication. Do you wish to continue?',
    'loginRequired:heading': 'Login Required',
    'continueToLogin:button': 'Continue To Login',
    select: 'Select Zone',
    create: 'Create Zone',
    createNew: 'Create New Zone',
    createSuccess: 'Success: Your new zone has been created.',
    settingsSuccess: 'Success: Your zone settings have been updated.',
    enterName: 'Please enter a name for the new zone.',
    upgrade:
      'Configure your systems, set up advanced policies, and report on more information about your zone on demand by upgrading to Manage.',
    provideError: 'Please provide a zone',
    nameInput: {
      label: 'Zone Name',
      placeholder: 'Zone name',
      error: {
        required: 'Zone name is required.',
      },
    },
  },
  policy: {
    clonePolicy: 'Clone Policy',
    type: {
      patch: 'Patch',
      patch_all: 'Patch All Policy',
      patch_all_except: 'Patch All Except Policy',
      patch_only: 'Patch Only Policy',
      patch_manual_approval: 'Manual Approval Policy',
      patch_by_severity: 'By Severity Policy',
      patch_advanced: 'Advanced Policy',
      required_software_policy: 'Required Software Policy',
      worklet: 'Worklet',
      manual_approval: 'Manual Approval',
      required_software: 'Required Software',
      custom: 'Worklet', // https://automox.atlassian.net/browse/APOLLO-251
    },
    packageTargeting: {
      sectionTitle: 'Package Targeting',
      strongDescription:
        'Select a condition, then fill out the needed fields for that condition. You can add multiple conditions by clicking the ({0}) button.',
      description:
        'Only packages that meet all of the criteria will be patched.',
      previewPackages: 'Preview Packages That Would Be Patched',
      addCondition: 'Add Condition',
      conditionPlaceholder: 'Select Condition',
      optionPlaceholder: 'Select Option',
      validations: {
        advancedFiltersRequired: 'Please fill advanced filters.',
      },
      advancedFilters: {
        patchSource: 'Patch Source (Microsoft, Apple, etc)',
        patchOS: 'Patch OS',
        type: 'Type (Windows Only)',
        displayName: 'Display Name',
        patchSeverity: 'Patch Severity',
        patchAge: 'Patch Age',
      },
      conditionals: {
        is: 'Is',
        isNot: 'Is Not',
        lessOrEqual: 'Less Than Or Equal To',
        greaterOrEqual: 'Greater Than Or Equal To',
        contains: 'Contains',
        doesNotContain: 'Does Not Contain',
      },
      sourceOptions: {
        windowsUpdate: 'Microsoft Windows Update',
        microsoft: 'Microsoft (other)',
        apple: 'Apple',
        oracle: 'Oracle (Java)',
        mozilla: 'Mozilla (Firefox)',
        adobe: 'Adobe',
        opera: 'Opera',
      },
      osOptions: {
        windows: 'Microsoft Windows',
        mac: 'macOS',
        linux: 'Linux',
      },
      typeOptions: {
        application: 'Application',
        connectors: 'Connectors',
        criticalUpdates: 'Critical Updates',
        definitionUpdates: 'Definition Updates',
        developerKits: 'Developer Kits',
        featurePacks: 'Feature Packs',
        guidance: 'Guidance',
        securityUpdates: 'Security Updates',
        servicePacks: 'Service Packs',
        tools: 'Tools',
        updateRollups: 'Update Rollups',
        updates: 'Updates',
        upgrades: 'Upgrades',
      },
      errors: {
        attribute: 'Attribute is required',
        option: 'Option is required',
        condition: 'Condition is required',
      },
    },
    userNotifications: {
      defaults: {
        notificationMessage: 'Updates require reboot: Please save your work.',
        customNotificationPatchMessage:
          'System Update: An important update will be installed.',
        customNotificationRebootMessage:
          'An important update will be installed; reboot may be required.',
      },
      patchPolicies: {
        rebootRadioTrue: 'Enable automatic reboot after updates are installed',
        rebootRadioFalse:
          'Do not enable automatic reboot after updates are installed',
      },
      workletPolicies: {
        rebootRadioTrue: 'Force automatic reboot after worklet completion',
        rebootRadioTrueTooltip:
          'The forced reboot will make it so any and all devices will reboot upon successful remediation. Ensure that the remediation script has an exit code of 0 to force a reboot regardless of whether the remediation was successful.',
        rebootRadioFalse:
          'Do not enable automatic reboot after worklet completion',
        rebootRadioFalseTooltip:
          'No devices will be rebooted after remediation regardless of whether reboot is needed or not',
      },
    },
    viewPolicyActivityLog: 'View Policy Activity Log',
    runPolicy: 'Run Policy',
    edit: {
      'cannotEdit:notification':
        'You do not have the required permissions to edit this type of policy.',
      userNotifications: {
        deferral: {
          header: 'Deferral Settings',
          enableDeferrals: 'Enable Deferrals',
          enableAutomaticDeferrals: {
            label: 'Enable Automatic Deferrals',
            tooltip: {
              ariaLabel: 'About automatic deferrals',
              text: 'If automatic deferrals are enabled, any notification that is present for longer than the specified duration will be automatically deferred for the maximum deferral time option. This will also count as one of the allowed number of deferrals.',
            },
          },
          hourlyDeferralOptions: {
            errors: {
              between: 'Deferral option in between 1 and 24.',
              required: 'At least one deferral option is required.',
              unique: 'Deferral options should be unique.',
            },
            label: 'Hourly Deferral Options',
            suffix: 'Hours',
            tooltip:
              'Configure up to 3 deferral times for end-users to select from before the system will update. By default, the deferral time options are 1 hour, 4 hours, and 8 hours. Leave the hour blank to have fewer than 3 deferral time options. Depending on your unique business environment, these defaults can be updated to any whole number between 1 and 24.',
          },
          maxNumberOfDeferrals: {
            errors: {
              between:
                'The number of deferrals allowed must be between 1 and 10.',
            },
            label: 'Max Number of Deferrals',
            tooltip:
              'The number of times a reboot or update can be deferred is configurable. The default is 6 and the max is 10.',
          },
        },
      },
      previewImpactedWarning:
        'This is a preview of the devices the device targeting filter will currently apply to. This device targeting filter only applies to devices that are in groups associated with this policy. Ensure you have the correct groups associated with this policy.',
    },
    validation: {
      deviceFilterIncomplete: 'Device filter is incomplete.',
      fieldsHaveErrors:
        'One or more fields have an error. Please check and try again.',
      nameRequired: 'Policy name is required.',
      nameMaxLength: 'The name cannot exceed {max} characters.',
      notesMaxLength: 'Notes text cannot exceed {max} characters.',
    },
  },
  'reloadApp:heading': 'Something Went Wrong - Please Reload',
  'reloadApp:button': 'Reload Now',
  'reloadApp:prompt':
    'Something went wrong in navigating to a new page. Please reload the current page and try again. If the issue persists, reach out to support.',
  routeTitles: {
    accountCreated: 'Account Created',
    addUser: 'Add User',
    connectionEditor: 'Edit SAML Connection',
    connectionList: 'Authentication',
    createConnection: 'Add SAML Connection',
    createZone: 'Create Zone',
    domainList: 'Domains',
    editUser: 'Edit User',
    globalSettings: 'Settings',
    needsAttention: 'Needs Attention Report',
    onboarding: 'Onboarding',
    policyResults: 'Policy Results',
    policyResultDetails: 'Policy Result Details',
    policyResultDetailsActivityLog: 'Policy Result Details Activity Log',
    policyRunResults: 'Policy Run Results',
    reportsOverview: 'Overview Report',
    reportsPrepatch: 'Pre-Patch Report',
    rolesAndPermissions: 'Roles & Permissions',
    signup: 'Signup',
    titleFormat: '{0} · Automox',
    users: 'Users',
    workletCatalog: 'Worklet Catalog',
    workletDetails: 'Worklet Details',
    zoneEditor: 'Zone Editor',
    zones: 'Zones',
    configurations: 'Configurations',
    macOsGetStarted: 'Get Started',
    configurationsDashboard: 'All Configurations',
    configurationsApns: 'APNS Certificate',
    configurationsAppleBusinessManager: 'Apple Business Manager',
    remediations: 'Remediations',
    remediationDetails: 'Remediation Details',
    remediationSolutions: 'Remediation Solutions',
    remediationUnknownDevices: 'Remediation Unknown Devices',
  },
  saml: {
    editEmailDisabled:
      'A zone to which you belong has enabled SAML-based Single Sign-On.  Email editing is disabled.',
  },
  software: {
    breadcrumb: 'Software',
  },
  suffixes: {
    daysOld: 'Days Old',
  },
  tfa: {
    availability:
      'Two Factor Authentication (2FA) is available via Email, Google Authenticator, Authy, or other mobile app authenticators.',
    enforce: 'Enforce Zone-Wide Two-Factor Authentication',
    explanation:
      'Two-factor authentication (2FA) adds another layer of security to user accounts by requiring a password along with an email code or a mobile authenticator app for each user in a zone.',
    success:
      "Success: Your zone's two factor authentication setting has been updated.",
    tfa: '2FA',
    required: 'Your company requires 2-factor authentication',
    resetUser: {
      email:
        'If the user is a member of any zone that requires 2FA, resetting this user will default them to email 2FA.',
      disable:
        'If the user is not a member of a zone that requires 2FA, resetting this user will disable 2FA.',
    },
  },
  users: {
    removeAccess: {
      prompt:
        'Are you sure you want to remove access to this zone for the following user: | Are you sure you want to remove access to this zone for the following users:',
      success:
        'Success: 1 user has been removed from this zone. | Success: {n} users have been removed from this zone.',
    },
  },
  manage: {
    'manage:breadcrumb': 'Manage',
    policies: {
      'policies:breadcrumb': 'Policies',
      'policyDetails:breadcrumb': 'Policy Details',
      'create:breadcrumb': 'Create Policy',
    },
    groups: {
      'groups:breadcrumb': 'Groups',
      'create:breadcrumb': 'Create Group',
      'edit:breadcrumb': 'Edit Group',
    },
    tasks: {
      'tasks:breadcrumb': 'Tasks',
      'taskDetails:breadcrumb': 'Task Details',
      'batches:breadcrumb': 'Batches',
      'batchDetails:breadcrumb': 'Batch Details',
      'potentialIssues:breadcrumb': 'Potential Issues',
      'remediations:breadcrumb': 'Remediations',
      'remediationDetails:breadcrumb': 'Details',
    },
    worklets: {
      backToCatalog: 'Back to Catalog',
      browseCatalog: 'Browse Catalog',
      browseCommunityLibrary: 'Browse the community library',
      browseWorkletCatalog: 'Browse worklet catalog',
      catalog: 'Worklet Catalog',
      communityWorklets: 'Community Worklets',
      'workletDetails:breadcrumb': 'Worklet Details',
    },
  },
  // Deprecated (moved to general.en-US.json)
  actions: 'Actions',
  add: 'Add',
  back: 'Back',
  cancel: 'Cancel',
  clickHere: 'Click here',
  close: 'Close',
  created: 'Created',
  createdBy: 'Created by',
  done: 'Done',
  edit: 'Edit',
  email: 'Email',
  errorMsgPrefix: 'Error:',
  exportCSV: 'Export CSV',
  finish: 'Finish',
  firstName: 'First Name',
  genericErrorMsg:
    'An unexpected error occurred. Please try again. If the issue persists, contact support.',
  lastName: 'Last Name',
  lists: {
    deleteItem: 'Delete Item',
  },
  loading: 'Loading...',
  modified: 'Modified',
  name: 'Name',
  next: 'Next',
  new: 'New',
  no: 'No',
  plusNMore: '+ {n} More',
  role: 'Role',
  save: 'Save',
  selectAll: 'Select All',
  slideXofY: 'Slide {x} of {y}',
  status: 'Status',
  validations: {
    email: 'Must be a valid email.',
    isRequired: '{0} is required',
    itemRequired: 'At least one item is required',
    required: 'This field is required.',
    numberBetween: `Value must be a number between {min} and {max}.`,
    minLength: `Value must be at least {minLength} characters.`,
  },
  yes: 'Yes',
  zone: 'Zone',
};
