/* eslint-disable */
import { getOverview } from '@/services/reports-overview.service';

import { RESET } from '@/store/actions';

const REPORTS_OVERVIEW_REQUEST = 'reports_overview_request';
const OVERVIEW_RECEIVED = 'overview_received';

const initialState = () => ({});

const state = initialState;

const getters = {};

const actions = {
  [REPORTS_OVERVIEW_REQUEST]: ({ commit }, queryString) => new Promise(async (resolve, reject) => {
    try {
      const response = await getOverview(queryString);
      commit(OVERVIEW_RECEIVED, { queryString, response });

      resolve(response);
    } catch (error) {
      reject(error);
    }
  }),
  [RESET]: ({ commit }) => {
    commit(RESET);
  },
};

const mutations = {
  [OVERVIEW_RECEIVED]: (state, data) => {
    state = data.response;
  },
  [RESET]: (state) => {
    state = initialState();
    return state;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
