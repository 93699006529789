import { Nullable } from '@ax/type-utils';
import { isNullable } from './is-nullable';

/**
 * Use `map` and `map_` to take a pure function `A` to `B` and have
 * it work with potentially nullable values. This function performs null checks so
 * authors can simplify their functions!
 *
 * @example
 * import { pipe } from '@ax/function-utils';
 * import { map_, map } from '@ax/function-utils/nullable';
 *
 * const addOne = (num: number) => num + 1;
 *
 * const timesTwo = (num: number) => num * 2;
 *
 * // without pipe
 * const isNull = map_(map_(null, addOne), addTwo);
 * const isTwo = map_(map_(0, addOne), addTwo);
 *
 * // with pipe
 * const isNull = pipe(null, addOne, addTwo);
 * const isTwo = pipe(0, addOne, addTwo);
 *
 * @param self `Nullable<A>`
 * @param to maps a value `A` to `B`
 * @returns `Nullable<B>`
 */
export function map_<A, B>(self: Nullable<A>, to: (a: A) => B): Nullable<B> {
  return isNullable(self) ? self : to(self);
}

/**
 * Curried version of `map_`. Another way to think of `map` is that it converts
 * a function `A -> B` into a function `Nullable<A> -> Nullable<B>`
 *
 *
 * @example
 * import { pipe } from '@ax/function-utils';
 * import { map_, map } from '@ax/function-utils/nullable';
 *
 * const addOne = (num: number) => num + 1;
 *
 * const timesTwo = (num: number) => num * 2;
 *
 * // without pipe
 * const isNull = map_(map_(null, addOne), addTwo);
 * const isTwo = map_(map_(0, addOne), addTwo);
 *
 * // with pipe
 * const isNull = pipe(null, addOne, addTwo);
 * const isTwo = pipe(0, addOne, addTwo);
 *
 * @param to maps a value `A` to `B`
 */
export function map<A, B>(to: (a: A) => B) {
  return (self: Nullable<A>) => map_(self, to);
}
