/**
 * This is a catch all error so that when we run a `QueryFetcher<A>`,
 * if the interpreter fails to catch all errors we will return this. If this error
 * is created, it should indicate a bug in the `QueryFetcher<A>`
 */
export class UnknownFetchError extends Error {
  readonly type = 'UnknownFetchError';

  constructor(readonly error: unknown) {
    super();
  }

  static make = (error: unknown) => new UnknownFetchError(error);
}
