import { RouteConfig } from 'vue-router';

export enum GlobalViewDevicesRouteNames {
  ListDevices = 'ListDevices',
}

export const globalViewDevicesRoutes: RouteConfig[] = [
  {
    path: '/global/devices',
    name: GlobalViewDevicesRouteNames.ListDevices,
    component: () => import('../pages/GlobalViewDevicesList.vue'),
    meta: {
      layout: 'app',
      title: 'Global Devices',
    },
  },
];
