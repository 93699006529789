import { AxiosResponse } from 'axios';
import { stringifyUrlQuery } from '@/utils/util';

import { OrganizationPrefs, Saml } from '@/models/organization';
import httpClient from './http-client';

const ORG_ENDPOINT = '/orgs/self';

export const createOrg = (
  currentOrgId: string | number,
  name: string,
): Promise<AxiosResponse> => {
  return httpClient
    .post(`/orgs?${stringifyUrlQuery({ o: currentOrgId, internal: 1 })}`, {
      name,
    })
    .then((response) => response.data);
};

export const getOrgPrefs = (
  orgId: string | number,
): Promise<OrganizationPrefs> => {
  return httpClient
    .get(
      `${ORG_ENDPOINT}/prefs?${stringifyUrlQuery({ o: orgId, internal: 1 })}`,
    )
    .then((response) => response.data);
};

export const postOrgPrefs = (
  orgId: string | number,
  payload: OrganizationPrefs,
): Promise<AxiosResponse> => {
  return httpClient.post(
    `${ORG_ENDPOINT}/prefs?${stringifyUrlQuery({ o: orgId, internal: 1 })}`,
    payload,
  );
};

export const getOrgSaml = (orgId: string | number): Promise<Saml> => {
  return httpClient
    .get(`${ORG_ENDPOINT}/saml?${stringifyUrlQuery({ o: orgId, internal: 1 })}`)
    .then((response) => response.data);
};

export const postOrgSaml = (
  orgId: string | number,
  payload: Saml,
): Promise<AxiosResponse> => {
  return httpClient
    .post(
      `${ORG_ENDPOINT}/saml?${stringifyUrlQuery({ o: orgId, internal: 1 })}`,
      payload,
    )
    .then((response) => response.data);
};
