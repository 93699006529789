export class Failure<E> {
  readonly type = 'Failure';

  constructor(readonly error: E) {}
}

export function failure<E>(error: E) {
  return new Failure(error);
}

export class Success<A> {
  readonly type = 'Success';

  constructor(readonly success: A) {}
}

export function success(): Success<void>;
export function success<A>(value: A): Success<A>;
export function success<A>(value?: A) {
  return new Success(value);
}

export type Response<A, E> = Failure<E> | Success<A>;

export function isFailure<E>(
  response: Response<unknown, E>,
): response is Failure<E> {
  return response.type === 'Failure';
}

export function isSuccess<A>(
  response: Response<A, unknown>,
): response is Success<A> {
  return response.type === 'Success';
}
