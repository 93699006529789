import { RawLocation } from 'vue-router';

export interface FileUploadComponentPublicAPI {
  /**
   * Call this method when an upload HTTP request has been canceled
   */
  cancelUpload: () => void;
  /**
   * pass `fileUploadTemplateRef.handleProgress` to an Axios HTTP invocation to have
   * this component render progress updates from the request
   */
  handleProgress: (event: ProgressEvent) => void;
  acceptReadable: string;
  fileSizeLimitReadable: string;
}

/**
 * The string values of this enum must resolve to one of prism's languages.{value}
 * Supported Languages: https://prismjs.com/#supported-languages
 */
export enum EditorLanguages {
  Shell = 'bash',
  PowerShell = 'powershell',
}

export const PrismLanguages: {
  [K in keyof typeof EditorLanguages]: () => Promise<unknown>;
} = {
  Shell: () => import(`prismjs/components/prism-bash`),
  PowerShell: () => import(`prismjs/components/prism-powershell`),
};

export interface ProgressBarConfiguration {
  label: string;
  color: string;
  percentage: number;
  count: number;
}

export interface SidebarNavItemType {
  active?: boolean;
  children?: SidebarNavItemType[];
  current?: boolean;
  dataTestId?: string;
  href?: string;
  icon?: string;
  isVisible?: boolean;
  label?: string;
  routeTo?: RawLocation;
  slotName?: string;
  target?: string;
}

export type SelectItems = {
  text: string;
  value: string | number;
  disabled?: boolean;
}[];

export type Direction = 'top' | 'left' | 'right' | 'bottom';

export function isDirection(input: string): input is Direction {
  return ['left', 'right', 'top', 'bottom'].includes(input);
}
