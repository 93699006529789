export type DefinedPrimitive = string | number | boolean;
export type AnyPrimitive = DefinedPrimitive | null | undefined | symbol;

export interface StorageConfig {
  key: string;
  store: Storage;
}

type SerializableArray = Array<object | AnyPrimitive>;
type Serializable =
  | Record<string | number, AnyPrimitive | object | SerializableArray>
  | SerializableArray;
export type Storable = DefinedPrimitive | Serializable;

/**
 * Use this as a generic utility for setting/merging storage states with a Serializable (key/value) or primitive value
 * @param state what to persist
 * @param storageConfig
 * @param merge set this to `true` to merge new state properties with already existing
 * state properties.  If this is not passed as `true` and all object properties are not present
 * in `state`, previously-stored object properties will be lost
 */
export function setItemInWebStorage(
  state: Storable,
  storageConfig: StorageConfig,
  merge = false,
) {
  try {
    const { key, store } = storageConfig;
    const toStore = merge
      ? { ...getItemInWebStorage(storageConfig), ...(state as object) }
      : state;
    store.setItem(key, JSON.stringify(toStore));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(e);
  }
}

/**
 * A generic storage retrieval method
 * @param storageConfig
 */
export function getItemInWebStorage(storageConfig: StorageConfig) {
  const { key, store } = storageConfig;

  try {
    return JSON.parse(store.getItem(key)!);
  } catch {
    store.removeItem(key);
    return null;
  }
}

/**
 * A generic storage removal method
 * @param storageConfig
 */
export function removeItemInWebStorage(storageConfig: StorageConfig) {
  const { key, store } = storageConfig;

  store.removeItem(key);
}
