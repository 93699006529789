import { PlanName } from '@ax/data-services-account/models/billing';
import { ZoneUUID } from '@ax/api-clients-accounts/models';

export abstract class BaseOrganization {
  id = 0;

  zone_id: ZoneUUID = '';

  plan: `${PlanName}` = PlanName.manage;

  trial_end_time: string | null = null;

  trial_expired = false;
}

export class ParentOrganization extends BaseOrganization {
  constructor(init?: Partial<ParentOrganization>) {
    super();
    if (init) {
      Object.assign(this, init);
    }
  }
}

export class Organization extends BaseOrganization {
  access_key = '';

  create_time = '';

  metadata: object = {};

  name = '';

  parent_id: Number | null = null;

  saml: { enabled: boolean; redirect: string | null } = {
    enabled: false,
    redirect: null,
  };

  constructor(init?: Partial<Organization>) {
    super();
    if (init) {
      Object.assign(this, init);
    }
  }
}
