import { AccountRbacRole, UserUUID } from '@ax/api-clients-accounts/models';
import {
  ParentOrganization,
  Organization,
} from '@ax/data-services-zone/models/organization';
import {
  RbacRole,
  RbacRoles,
} from '@ax/data-services-authorization/models/rbac';

export class UserFeatures {
  // This indicates if the user can be "Multi-Org"
  mo?: boolean;

  constructor(init: UserFeatures) {
    Object.assign(this, init);
  }
}

export class UserMetadata {
  overviewReportRun?: boolean;

  prepatchReportRun?: boolean;

  constructor(init: UserMetadata) {
    Object.assign(this, init);
  }
}

export class UserPref {
  user_id?: number;

  pref_name?: string;

  value?: string;

  constructor(init: UserPref) {
    Object.assign(this, init);
  }
}

export class UserPrefs {
  'display.devices.pagesize'?: boolean;

  'display.tutorial'?: boolean;

  'notify.system.add'?: boolean;

  'notify.system.delete'?: boolean;

  'notify.system.patch.applied'?: boolean;

  'notify.system.patch.failed'?: boolean;

  'notify.weeklydigest'?: boolean;

  'user.tfa'?: string | boolean;

  constructor(init: UserPrefs) {
    Object.assign(this, init);
  }
}

export class User {
  email?: string;

  features?: UserFeatures;

  firstname?: string;

  id?: number;

  uuid?: UserUUID;

  lastname?: string;

  // Apparently, this field exists (according to backbone, not so much in webserver)
  locked?: boolean;

  metadata?: UserMetadata;

  orgs: Organization[];

  // There’s the slim possibility (at least until GZM migration is fully enforced)
  // that a user is assigned to organizations with different parent organizations.
  // We are assuming this array will hold a single entry in the majority of cases
  // and we generally only look at the first entry.
  parent_org: ParentOrganization[];

  prefs?: UserPref[];

  account_rbac_role = AccountRbacRole.NoGlobalAccess;

  // For user accounts tab and updating
  rbac_role?: string;

  rbac_roles?: RbacRole[];

  saml_enabled?: boolean;

  // For user accounts tab
  tfa_enabled?: boolean;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  server_groups?: any[];

  tags?: string[];

  account_id?: string;

  account_name?: string;

  account_completed_qsg?: boolean;

  account_created_at?: string;

  sso_enabled?: boolean;

  ext_auth0_skip_mfa?: boolean;

  constructor(init: Partial<User>) {
    this.features = new UserFeatures({});
    this.metadata = new UserMetadata({});
    this.orgs = [new Organization()];
    this.parent_org = [new ParentOrganization()];
    this.prefs = [new UserPref({})];
    this.rbac_roles = [new RbacRole({})];
    Object.assign(this, init);
  }

  get is_global_admin() {
    return this.account_rbac_role === AccountRbacRole.GlobalAdmin;
  }
}

export function isFullAdmin(user: User, orgId: string): boolean {
  const orgIdNumber = parseInt(orgId, 10);
  return (
    user?.rbac_roles?.some(
      (role) =>
        role.id === RbacRoles.ADMIN && role.organization_id === orgIdNumber,
    ) ?? false
  );
}
