import { defineAsyncComponent } from '@vue/composition-api';
import { prop } from '@ax/object-utils';
import AxAutocomplete from './Autocomplete.vue';
import AxCheckbox from './Checkbox.vue';
import AxCombobox from './Combobox.vue';
import AxDateField from './DateField.vue';
import AxRadio from './Radio.vue';
import AxRadioGroup from './RadioGroup.vue';
import AxSelect from './Select.vue';
import AxTextarea from './Textarea.vue';
import AxTextField from './TextField.vue';
import AxTimeField from './TimeField.vue';
import AxToggle from './Toggle.vue';

/**
 * Prismjs is relatively large so we are going to lazy load this component wherever it is used.
 */
const AxCodeEditor = defineAsyncComponent(() =>
  import('./CodeEditor.vue').then(prop('default')),
);

export {
  AxAutocomplete,
  AxCheckbox,
  AxCodeEditor,
  AxCombobox,
  AxDateField,
  AxRadio,
  AxRadioGroup,
  AxSelect,
  AxTextarea,
  AxTextField,
  AxTimeField,
  AxToggle,
};
