/* eslint-disable import/no-cycle */
import { User } from '@ax/data-services-user/models/user';
import { login, logout } from '@/services/auth.service';
import { RESET } from '@/store/actions';
import { USER_RECEIVE } from '@/store/actions/user';
import { getItemInStorage } from '@/utils/storage';

const REQUEST = 'request';
const SUCCESS = 'success';
const ERROR = 'error';
const LOGOUT = 'logout';

const initialState = () => ({
  status: '',
});

const getters = {
  isLoading: (state): boolean => state.status === 'loading',
};

const actions = {
  [REQUEST]: async ({ commit, dispatch }, user): Promise<User> => {
    commit(REQUEST);
    try {
      const response = await login(user);
      commit(SUCCESS);
      return dispatch(
        USER_RECEIVE,
        { user: response, orgId: user.orgId },
        { root: true },
      );
    } catch (error) {
      commit(ERROR, error);
      return Promise.reject(error);
    }
  },
  [LOGOUT]: (
    { commit },
    {
      shouldRedirect,
      sessionExpired,
    }: { shouldRedirect?: boolean; sessionExpired?: boolean } = {},
  ) => {
    commit(LOGOUT);

    return logout().then(() => {
      const redirect = getItemInStorage({
        key: 'ax-last-route',
        store: localStorage,
      });

      if (shouldRedirect) {
        const searchParams = new URLSearchParams({ redirect });
        if (sessionExpired) {
          searchParams.append('err', 'session-expired');
        }
        /**
         * This href-assignment forces the browser to reload the application and all of its state.
         * It doesn't use the vue router because that would let objects with private data linger in memory
         * after a user's session has been invalidated
         */
        window.location.href = `${window.location.origin}/login?${searchParams}`;
      }
    });
  },
};

const mutations = {
  [REQUEST]: (state) => {
    state.status = 'loading';
  },
  [SUCCESS]: (state) => {
    state.status = 'success';
  },
  [ERROR]: (state, error) => {
    state.error = error;
    state.status = 'error';
  },
  [LOGOUT]: (state) => {
    state.status = '';
  },
  [RESET]: (state) => {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations,
};
