import Vue from 'vue';
import { consoleI18n } from '@ax/console-i18n';
import { Path, Values } from 'vue-i18n';

// Vue.use(VueI18n) is done within I18nFactory

// Maintain backward compatibility with `$ts`
Vue.mixin({
  methods: {
    $ts(key: Path, values?: Values): string {
      return this.$t(key, values).toString();
    },
  },
});

declare module 'vue/types/vue' {
  interface Vue {
    $ts: (key: Path, values?: Values) => string;
  }
}

/* This was provided by vue-cli-plugin-i18n but we don't need it yet
 * and we might want a different implementation that doesn't rely on `require`
function loadLocaleMessages(): LocaleMessages {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.js$/i);
  const messages: LocaleMessages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key).default;
    }
  });
  return messages;
}
 */
/**
 * @deprecated instead use: import { consoleI18n } from '@ax/console-i18n';
 */
const i18n = consoleI18n;

/**
 * @deprecated instead use: import { consoleI18n } from '@ax/console-i18n';
 */
export default i18n;
