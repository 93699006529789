import {
  mdiMinusBoxOutline,
  mdiCheckboxOutline,
  mdiCloseCircle,
} from '@mdi/js';
import { CosmosDarkColors } from './colors';

export const darkThemeColors: Record<string, CosmosDarkColors> = {
  primary: CosmosDarkColors.Primary,
  secondary: CosmosDarkColors.Secondary,
  accent: CosmosDarkColors.Primary,
  anchor: CosmosDarkColors.Teal,
  aside: CosmosDarkColors.Storm5,
  background: CosmosDarkColors.Storm1,
  casper: CosmosDarkColors.CasperGray,
  component: CosmosDarkColors.Storm0,
  content: CosmosDarkColors.Content,
  contentDark: CosmosDarkColors.ContentDark,
  dataLime: CosmosDarkColors.DataLime,
  dataOrange: CosmosDarkColors.DataOrange,
  dataRed: CosmosDarkColors.DataRed,
  dataRoyalPink: CosmosDarkColors.DataPink,
  dataYellow: CosmosDarkColors.DataYellow,
  error: CosmosDarkColors.Caution,
  info: CosmosDarkColors.Primary,
  input: CosmosDarkColors.Input,
  neutral: CosmosDarkColors.Neutral,
  success: CosmosDarkColors.Success,
  teal: CosmosDarkColors.Teal,
  warning: CosmosDarkColors.Warning,
  'storm-0': CosmosDarkColors.Storm0,
  'storm-1': CosmosDarkColors.Storm1,
  'storm-2': CosmosDarkColors.Storm2,
  'storm-3': CosmosDarkColors.Storm3,
  'storm-4': CosmosDarkColors.Storm4,
  'storm-5': CosmosDarkColors.Storm5,
};

export const icons = {
  iconfont: 'mdiSvg' as const,
  values: {
    checkboxIndeterminate: mdiMinusBoxOutline,
    checkboxOn: mdiCheckboxOutline,
    delete: mdiCloseCircle,
  },
};

export const theme = {
  dark: true,
  themes: {
    dark: darkThemeColors,
  },
};

export default {
  icons,
};
