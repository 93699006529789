export const envConfig = {
  API_BASE_URL: {
    default: '/api',
    env: 'VITE_API_BASE_URL',
  },
  API_IS_PROXY: {
    default: false,
    env: 'VITE_API_IS_PROXY',
  },
  BASE_URL: {
    default: '',
    env: 'VITE_BASE_URL',
  },
  BESPOKE_API_BASE_URL: {
    default: '/bespoke',
    env: 'VITE_BESPOKE_API_BASE_URL',
  },
  CONFIG_URL: {
    default: '',
    env: 'VITE_CONFIG_URL',
  },
  DATADOG_CONSOLE_APP_ID: {
    default: '',
    env: 'VITE_DATADOG_CONSOLE_APP_ID',
  },
  DATADOG_CONSOLE_CLIENT_TOKEN: {
    default: '',
    env: 'VITE_DATADOG_CONSOLE_CLIENT_TOKEN',
  },
  DATADOG_ENABLED: {
    default: false,
    env: 'VITE_DATADOG_ENABLED',
  },
  I18N_LOCALE: {
    default: 'en-US',
    env: 'VITE_I18N_LOCALE',
  },
  I18N_FALLBACK_LOCALE: {
    default: 'en-US',
    env: 'VITE_I18N_FALLBACK_LOCALE',
  },
  RELEASE_VERSION: {
    default: '',
    env: 'VITE_RELEASE_VERSION',
  },
  ROUTES_LIVE_ONLY: {
    default: false,
    env: 'VITE_ROUTES_LIVE_ONLY',
  },
  SEGMENT_DEV_TOKEN: {
    default: '',
    env: 'VITE_SEGMENT_DEV_TOKEN',
  },
};
