import { provide, inject } from '@vue/composition-api';
import VueRouter from 'vue-router';

/**
 * This is the symbol used for injecting the router to child components.
 */
export const RouterSymbol = Symbol.for('@ax/vue-utils/router');

/**
 * Provide the current router to all child components, should be
 * utilized in App.vue or some other root component
 * @param router `VueRouter`
 */
export function provideRouter(router: VueRouter) {
  provide(RouterSymbol, router);
}

/**
 * use to get access to the router without accessing `context`
 * @returns `VueRouter`
 */
export function useRouter() {
  const router = inject<VueRouter>(RouterSymbol);
  /**
   * The router should always be provided, but in case someone deletes it in App.vue ,
   * an error will be thrown
   */
  if (router === undefined) {
    throw new Error('The router must be provided in a parent component');
  }
  return router;
}
