import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { createClient } from '@ax/api-clients-common/http-client-factory';

export const ACCOUNTS_ENDPOINT = '/accounts';

let currentAccountId;
let publicClient;
let bespokeClient;

interface HttpClientConfig {
  API_BASE_URL: string;
  BESPOKE_API_BASE_URL: string;
}

export const createAccountHttpClients = (
  accountId: string,
  config: HttpClientConfig,
  axiosConfig: Partial<AxiosRequestConfig> = {},
): [AxiosInstance, AxiosInstance] | undefined => {
  if (accountId !== currentAccountId) {
    currentAccountId = accountId;
    publicClient = createClient({
      baseURL: `${config.API_BASE_URL}${ACCOUNTS_ENDPOINT}/${accountId}`,
      ...axiosConfig,
    });
    bespokeClient = createClient({
      baseURL: `${config.BESPOKE_API_BASE_URL}${ACCOUNTS_ENDPOINT}/${accountId}`,
      ...axiosConfig,
    });
    return [publicClient, bespokeClient];
  }
  return undefined;
};

export const getPublicAccountHttpClient = (): AxiosInstance => {
  if (publicClient) {
    return publicClient;
  }
  throw new Error(
    'HTTP Client for Public Account endpoints has not been initialized.',
  );
};

export const getBespokeAccountHttpClient = (): AxiosInstance => {
  if (bespokeClient) {
    return bespokeClient;
  }
  throw new Error(
    'HTTP Client for Bespoke Account endpoints has not been initialized.',
  );
};
