import { RESET } from '@/store/actions';

const UNSAVED_CHANGES = 'unsavedChanges';

const initialState = () => ({
  unsavedChanges: () => false,
});

const getters = {
  getUnsavedChanges: (state): boolean => state.unsavedChanges,
};

const actions = {
  [UNSAVED_CHANGES]: ({ commit }, exist: boolean): void => {
    commit(UNSAVED_CHANGES, exist);
  },
};

const mutations = {
  [UNSAVED_CHANGES]: (state, exist: boolean) => {
    state.unsavedChanges = exist;
  },
  [RESET]: (state) => {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations,
};
