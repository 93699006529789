const dateTimeFormat = {
  default: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  },
  long: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
  longDate: {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  },
};

export default dateTimeFormat;
