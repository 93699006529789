import { Mutation, State, Action, Getter } from 'vuex-simple';
import { Policy } from '@/models/policy';
import { TaskBatch, TaskBatchSingle } from '@/models/task';

export class SystemManagementStateModule {
  @State()
  private _policiesListData: Policy[] = [];

  @Mutation()
  public setPoliciesListData(policies: Policy[]) {
    this._policiesListData = policies;
  }

  @Mutation()
  public setPolicyInListData(policy: Policy) {
    this._policiesListData = this.policiesListData.map((p) =>
      p.id === policy.id ? policy : p,
    );
  }

  @Mutation()
  public removePolicyFromList(policy: Policy) {
    this._policiesListData = this.policiesListData.filter(
      ({ id }) => id !== policy.id,
    );
  }

  @Action()
  public dispatchPoliciesListRequest(fetcher: () => Promise<Policy[]>): void {
    fetcher().then((response) => this.setPoliciesListData(response));
  }

  @Getter()
  public get policiesListData(): Policy[] {
    return this._policiesListData;
  }

  @State()
  private _taskAddModalOpened = false;

  @Mutation()
  public toggleTaskAddModal(opened: boolean) {
    this._taskAddModalOpened = opened;
  }

  @Getter()
  public get taskAddModalOpened(): boolean {
    return this._taskAddModalOpened;
  }

  @State()
  private _batch: TaskBatchSingle | null = null;

  @Getter()
  public get batch(): TaskBatchSingle | null {
    return this._batch;
  }

  @Mutation()
  private setBatch(batch: TaskBatchSingle) {
    this._batch = batch;
  }

  @Action()
  public dispatchSingleBatchRequest(
    fetcher: () => Promise<TaskBatchSingle>,
  ): Promise<TaskBatchSingle> {
    const promise = fetcher();
    promise.then((response) => this.setBatch(response));
    return promise;
  }

  @State()
  private _taskBatches: TaskBatch[] = [];

  @Getter()
  public get taskBatches(): TaskBatch[] {
    return this._taskBatches;
  }

  @Mutation()
  public setTaskBatches(batches: TaskBatch[]) {
    this._taskBatches = batches;
  }

  @Action()
  public addTaskBatch(batch: TaskBatch) {
    this.setTaskBatches([...this._taskBatches, batch]);
  }
}
