import { UnknownRecord } from './unknown-record';

export interface ObjectFilter<A extends UnknownRecord> {
  (key: keyof A, value: A[keyof A]): boolean;
}

/**
 * Use `filter_` or `filter` to filter an object `A` into a `Partial<A>`
 * based on a `ObjectFilter<A>`. The behavior is such that if the
 * key value pair for an associated entry on `A` results in a `true` from the
 * `ObjectFilter<A>`, it will be included on the `Partial<A>`.
 *
 * @example
 * import { pipe } from '@ax/function-utils';
 * import { filter, filter_ } from '@ax/object-utils';
 *
 * const isNumber = (key: PropertyKey, val: unknown): val is number => typeof val === 'number';
 *
 * // without pipe
 * const onlyNumbers = filter_({ a: 1, b: '1' }, isNumber);
 *
 * // with pipe
 * const onlyNumbers = pipe({ a: 1, b: '1' }, filter(isNumber));
 *
 * @param self `A`
 * @param predicate
 * @returns `Partial<A>`
 */
export function filter_<A extends UnknownRecord>(
  self: A,
  predicate: ObjectFilter<A>,
): Partial<A> {
  const partial: Partial<A> = {};
  Object.keys(self).forEach((key: keyof A) => {
    const value = self[key];
    if (predicate(key, value)) {
      partial[key] = value;
    }
  });
  return partial;
}

/**
 * Curried version of `filter_`
 *
 * Use `filter_` or `filter` to filter an object `A` into a `Partial<A>`
 * based on a `ObjectFilter<A>`. The behavior is such that if the
 * key value pair for an associated entry on `A` results in a `true` from the
 * `ObjectFilter<A>`, it will be included on the `Partial<A>`.
 *
 * @example
 * import { pipe } from '@ax/function-utils';
 * import { filter, filter_ } from '@ax/object-utils';
 *
 * const isNumber = (key: PropertyKey, val: unknown): val is number => typeof val === 'number';
 *
 * // without pipe
 * const onlyNumbers = filter_({ a: 1, b: '1' }, isNumber);
 *
 * // with pipe
 * const onlyNumbers = pipe({ a: 1, b: '1' }, filter(isNumber));
 *
 */
export function filter<A>(predicate: ObjectFilter<Record<PropertyKey, A>>) {
  return <T extends Record<PropertyKey, A>>(self: T) =>
    filter_(self, predicate);
}
