import { RouteConfig } from 'vue-router';
import { trackDeprecatedRoute } from '../router-utilities';
import { groupsRoutes } from './groups';
import { automatedVulnerabilityRemediationRoutes } from './automated-vulnerability-remediation';
import { deprecatedPoliciesRoutes, policiesRoutes } from './policies';
import { vulnerabilitySyncRoutes } from './vulnerability-sync';
import { workletCatalogRoutes } from './worklet-catalog';

export const systemManagementRoutes: RouteConfig[] = [
  ...deprecatedPoliciesRoutes,
  ...groupsRoutes,
  {
    path: '/manage',
    component: () => import('@/views/System/ManageNav.vue'),
    meta: {
      layout: 'app',
      title: 'System',
    },
    children: [
      {
        path: '',
        name: 'manageHome',
        component: () => import('@/views/System/ManageHome.vue'),
        meta: {
          hasBackground: true,
          layout: 'app',
          title: 'System',
        },
      },
      ...policiesRoutes,
      {
        path: 'groups',
        name: 'groups',
        component: () => import('@/views/System/GroupsTab.vue'),
        meta: {
          layout: 'app',
          title: 'Groups',
        },
      },
      ...vulnerabilitySyncRoutes,
      ...automatedVulnerabilityRemediationRoutes,
      ...workletCatalogRoutes,
    ],
  },
  {
    path: '/system-management',
    name: 'system',
    redirect: (to) => {
      trackDeprecatedRoute(to);
      const { query } = { ...to };
      const path =
        query.tab === 'groups' ? '/manage/groups' : '/manage/policies';
      if (query.tab) {
        delete query.tab;
      }
      return { path, query };
    },
    meta: {
      layout: 'app',
      title: 'System',
    },
  },
];
