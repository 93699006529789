import { SetupContext, ref, computed, watch } from '@vue/composition-api';
import { format } from 'date-fns';
import { useModule } from 'vuex-simple';
import { darkThemeColors } from '@ax/cosmos/utils/config';
import { consoleEnv } from '@ax/console-env';
import { NavigationGuardNext, Route } from 'vue-router';
import { DATE_TIME_ZONE_FORMAT } from '@ax/date-time-utils';
import {
  Task,
  TaskStatuses,
  TaskDeviceStatuses,
  TaskBatch,
  TaskBatchIssue,
} from '@/models/task';
import {
  getTasksList,
  TASKS_ENDPOINT,
  getTasksBatches,
  getTaskBatchIssues,
} from '@/services/tasks.deprecated.service';
import {
  DataTableState,
  DataTablePageState,
  DataTableSynchronizationOptions,
} from '@/utils/data-table-state';
import {
  DEFAULT_TABLE_PAGINATION,
  TABLE_ITEMS_PER_PAGE,
} from '@/utils/constants';
import { QueryStateParametersConfiguration } from '@/utils/query-state';
import { QueryStateStorageConfig } from '@/utils/storage';
import { TableHeader } from '@/types/table-options';
import { showStandardHttpErrorNotification } from '@/utils/util';
import { StateModules } from '@/store';
import { SystemManagementStateModule } from '@/store/system-management.module';
import { FlagKeys } from '@/models/feature-flags';
import { guardRouteWithFlag } from '@/router/routes/router-utilities';

export interface TasksQueryState extends DataTablePageState<Task> {
  status: string;
  severity: string;
  source: string;
}

interface TaskBatchDeviceDisplay extends TaskBatchIssue {
  ui_key: string;
}
interface TaskBatchQueryState extends DataTablePageState<TaskBatch> {
  'status:not_in': string[];
  'type:equals': string;
}

interface TaskBatchIssuesQueryState extends DataTablePageState<TaskBatchIssue> {
  'has_issue:equals': boolean;
  columns: undefined;
}

export function getTasksQueryStateConfiguration(
  orgId: number,
): QueryStateParametersConfiguration<TasksQueryState> {
  return {
    o: { defaultValue: orgId },
    limit: { defaultValue: DEFAULT_TABLE_PAGINATION, storeInBrowser: true },
    page: { defaultValue: 1 },
    columns: {
      defaultValue: [],
      storeInBrowser: true,
      excludeFromApiUrl: true,
    },
    sort: { defaultValue: 'status:desc' },
    status: { defaultValue: '', apiKeyTranslation: 'status:equals' },
    severity: { defaultValue: '', apiKeyTranslation: 'severity:equals' },
    source: { defaultValue: '', apiKeyTranslation: 'source:equals' },
    filter_panel_open: {
      defaultValue: true,
      storeInBrowser: true,
      excludeFromBrowserUrl: true,
      excludeFromApiUrl: true,
    },
  };
}

export function getTaskExportUrl(orgId: number, task: Task): string | null {
  return task.status !== TaskStatuses.Building
    ? `${consoleEnv.API_BASE_URL}/orgs/${orgId}/${TASKS_ENDPOINT}/${task.id}/csv?o=${orgId}`
    : null;
}

export function removeActionIsApplicable(status: TaskStatuses): boolean {
  return (
    status === TaskStatuses.Executed ||
    status === TaskStatuses.Pending ||
    status === TaskStatuses.Canceled
  );
}

export function useTasksTable(
  context: SetupContext,
  queryStateParametersConfiguration?: QueryStateParametersConfiguration<TasksQueryState>,
  synchronizeOptions: DataTableSynchronizationOptions<TasksQueryState> = {},
) {
  const tasks = ref<Task[]>([]);
  const totalServerTasks = ref<number>();
  const orgId = computed(() => Number(context.root.$route.query.o));
  const queryStateConfig =
    queryStateParametersConfiguration ||
    getTasksQueryStateConfiguration(orgId.value);

  let lastApiQuery: string | null = null;

  function getTasks(query: string): Promise<void> {
    lastApiQuery = query;
    return getTasksList(orgId.value, query)
      .then(({ data, metadata }) => {
        tasks.value = data;
        totalServerTasks.value = metadata.total;
      })
      .catch(showStandardHttpErrorNotification);
  }

  const { tableState, queryState, updateQueryState } =
    DataTableState.synchronize<Task, TasksQueryState>(
      context.root.$route,
      context.root.$router,
      queryStateConfig,
      {
        callback: ({ apiQuery }) => getTasks(apiQuery),
        onBeforeApiUrlGeneration: (state) => {
          let { sort } = state;
          // add secondary sort to order by severity
          if (sort.startsWith('status')) {
            sort += ',severity:desc';
          }
          return { ...state, sort: sort.replace('payload.', '') };
        },
        ...synchronizeOptions,
      },
    );

  watch(
    orgId,
    (next, prev) => {
      if (prev && next !== prev) {
        updateQueryState({
          o: next,
          limit: queryStateConfig.limit?.defaultValue,
          page: 1,
        });
      }
    },
    {
      immediate: true,
    },
  );

  // TODO: Change the approach here to use vuex store instead of refresh whole page.
  // Tracked by https://automox.atlassian.net/browse/IN-74
  const refreshResultsAfterRemoval = () => {
    if (lastApiQuery) {
      getTasks(lastApiQuery).then(() => {
        // If the last item on the page was removed, go back a page.
        if (
          (!tasks.value || tasks.value.length === 0) &&
          tableState.value.page > 1
        ) {
          updateQueryState({ page: tableState.value.page - 1 });
        }
      });
    }
  };

  return {
    tasks,
    totalServerTasks,
    orgId,
    tableState,
    queryState,
    updateQueryState,
    refreshResultsAfterRemoval,
  };
}

export function useTaskBatchesTable(context: SetupContext) {
  const systemManagementState = useModule<SystemManagementStateModule>(
    context.root.$store,
    [StateModules.systemManagement],
  )!;
  const batches = computed(() => systemManagementState.taskBatches);
  const orgId = computed(() => Number(context.root.$route.query.o));
  const loadTimestamp = ref<string>('');
  const serverItemsLength = ref<number>();

  const storageConfig: QueryStateStorageConfig = {
    key: 'ax-task-batches-prefs',
    store: localStorage,
  };

  const batchesHeader: TableHeader[] = [
    { text: 'CSV Name', divider: true, sortable: true, value: 'source' },
    {
      text: 'Queued By',
      divider: true,
      sortable: false,
      value: 'created_by_user.email',
    },
    { text: 'Source', divider: true, sortable: false, value: 'report_source' },
    {
      text: 'Impacted Devices',
      divider: true,
      sortable: true,
      value: 'impacted_device_count',
    },
    { text: 'Status', divider: true, sortable: true, value: 'status' },
  ];

  const batchesFooter = {
    'items-per-page-options': TABLE_ITEMS_PER_PAGE,
    'items-per-page-text': 'Batches per page:',
  };

  function getBatches(query: string): Promise<void> {
    return getTasksBatches(orgId.value, query)
      .then((response) => {
        systemManagementState.setTaskBatches(response.data);
        serverItemsLength.value = response.metadata.total;
      })
      .catch(showStandardHttpErrorNotification);
  }

  const { tableState, queryState, updateQueryState } =
    DataTableState.synchronize<TaskBatch, TaskBatchQueryState>(
      context.root.$route,
      context.root.$router,
      {
        o: { defaultValue: orgId.value },
        limit: { defaultValue: DEFAULT_TABLE_PAGINATION, storeInBrowser: true },
        page: { defaultValue: 1 },
        sort: { defaultValue: 'status:desc' },
        'status:not_in': {
          defaultValue: ['approved'],
          excludeFromBrowserUrl: true,
        },
        'type:equals': { defaultValue: 'patch', excludeFromBrowserUrl: true },
      },
      {
        callback: ({ apiQuery }) => {
          loadTimestamp.value = format(new Date(), DATE_TIME_ZONE_FORMAT);
          return getBatches(apiQuery);
        },
        storageConfig,
      },
    );

  watch(
    orgId,
    (next, prev) => {
      if (prev && next !== prev) {
        updateQueryState({ o: next, limit: DEFAULT_TABLE_PAGINATION, page: 1 });
      }
    },
    {
      immediate: true,
    },
  );

  return {
    batches,
    batchesFooter,
    batchesHeader,
    orgId,
    tableState,
    queryState,
    updateQueryState,
    loadTimestamp,
    serverItemsLength,
  };
}

export function useTaskPotentialIssuesTable(context: SetupContext) {
  const issues = ref<TaskBatchDeviceDisplay[]>([]);
  const orgId = computed(() => Number(context.root.$route.query.o));
  const batchId = computed(() => Number(context.root.$route.params.batchId));
  const serverItemsLength = ref<number>();

  const storageConfig: QueryStateStorageConfig = {
    key: 'ax-potential-issues-prefs',
    store: localStorage,
  };

  const issuesHeader: TableHeader[] = [
    { text: 'Device', divider: true, sortable: false, value: 'device' },
    { text: 'Hostname', divider: true, sortable: false, value: 'hostname' },
    {
      text: 'Private IP',
      divider: true,
      sortable: false,
      value: 'ip_addrs_private',
    },
    { text: 'CVE', divider: true, sortable: false, value: 'patch_id' },
    { text: 'Issue Type', divider: true, sortable: false, value: 'issue' },
  ];

  const issuesFooter = {
    'items-per-page-options': TABLE_ITEMS_PER_PAGE,
    'items-per-page-text': 'Issues per page:',
  };

  function getIssues(apiQuery: string): Promise<void> {
    return getTaskBatchIssues(orgId.value, batchId.value, apiQuery)
      .then((response) => {
        issues.value = response.data.map((device, index) => ({
          ...device,
          ui_key: `${device.id}-${index}`,
        }));
        serverItemsLength.value = response.metadata.total;
      })
      .catch(showStandardHttpErrorNotification);
  }

  const { tableState, queryState, updateQueryState } =
    DataTableState.synchronize<TaskBatch, TaskBatchIssuesQueryState>(
      context.root.$route,
      context.root.$router,
      {
        o: { defaultValue: orgId.value },
        limit: { defaultValue: DEFAULT_TABLE_PAGINATION, storeInBrowser: true },
        page: { defaultValue: 1 },
        sort: { defaultValue: undefined, excludeFromApiUrl: true },
      },
      {
        callback: ({ apiQuery }) => {
          return getIssues(apiQuery);
        },
        storageConfig,
      },
    );

  return {
    issues,
    issuesFooter,
    issuesHeader,
    orgId,
    batchId,
    tableState,
    queryState,
    updateQueryState,
    serverItemsLength,
  };
}

export const DefaultTaskDeviceTableHeaders: TableHeader[] = [
  {
    text: 'Device',
    value: 'custom_name',
    sortable: false,
  },
  {
    text: 'Hostname',
    value: 'hostname',
    sortable: false,
  },
  {
    text: 'IP Address',
    value: 'private_ips',
    sortable: false,
  },
];

export const TaskStatusColorMap = {
  [TaskDeviceStatuses.Success]: darkThemeColors.accent,
  [TaskDeviceStatuses.In_Progress]: darkThemeColors.dataYellow,
  [TaskDeviceStatuses.Failed]: darkThemeColors.dataRed,
  [TaskDeviceStatuses.Pending]: darkThemeColors.contentDark,
  [TaskDeviceStatuses.Timed_Out]: darkThemeColors.dataOrange,
};

export function makeAVRRouteGuard(isAVRPage: boolean) {
  return (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
    guardRouteWithFlag(
      to,
      next,
      FlagKeys.automatedVulnerabilityRemediation,
      {
        name: isAVRPage ? 'tasksList' : 'remediations',
        query: { o: to.query.o },
      },
      !isAVRPage,
    );
  };
}
