/* eslint-disable import/no-cycle */
import Vue from 'vue';
import Router from 'vue-router';
import routes from '@/router/routes';
import FourOFour from '@/views/404.vue';
import { globalBeforeEach, globalAfterEach } from '@/router/router';

export { LS_LAST_ROUTE } from '@/router/router';

Vue.use(Router);

/**
 * Route Level Code Splitting
 * This will generate a separate chunk for this route
 *
 * Example:
 * {
 *  path: '/somePath'
 *  name: 'someName',
 *  component: () => import('@/views/SomePage.vue'),
 * }
 *
 * Output:
 * webpackChunkName = someName
 * generated chunk: someName.[hash].js
 */

export const router: Router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/login',
    },
    ...routes,
    {
      path: '*',
      name: '404',
      component: FourOFour,
      meta: {
        layout: 'baseline',
        title: '404',
      },
    },
  ],
  /**
   * By default, scroll to the top-left of the page when the path changes.
   * Otherwise, emulate "native" behavior.
   * https://router.vuejs.org/guide/advanced/scroll-behavior.html#scroll-behavior
   * */
  scrollBehavior(from, to, savedPosition): { x: number; y: number } | null {
    if (to.path === from.path || !!savedPosition) {
      return null;
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach(globalBeforeEach);

router.afterEach(globalAfterEach);
