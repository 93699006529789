/**
 * A private function taken from Vue library code that outputs a string
 * descriptor for a component; e.g. given the component Dashboard.vue, we get
 * "<Dashboard> at src/views/Dashboard/Dashboard.vue" as the output
 * @param vm a Vue component instance
 * @param includeFile whether or not to return the filepath
 * @returns string descriptor for the given component
 */
export const formatComponentName = (vm, includeFile = true): string => {
  if (vm.$root === vm) {
    return '<Root>';
  }
  /* eslint-disable no-nested-ternary */
  const options =
    typeof vm === 'function' && vm.cid != null
      ? vm.options
      : vm._isVue
      ? vm.$options || vm.constructor.options
      : vm;
  /* eslint-enable no-nested-ternary */
  let name = options.name || options._componentTag;
  const file = options.__file;
  if (!name && file) {
    const match = file.match(/([^/\\]+)\.vue$/);
    name = match && match[1];
  }

  return (
    (name ? `<${classify(name)}>` : `<Anonymous>`) +
    (file && includeFile !== false ? ` at ${file}` : '')
  );
};

const classifyRE = /(?:^|[-_])(\w)/g;
const classify = (str) => {
  return str
    .replace(classifyRE, (c) => {
      return c.toUpperCase();
    })
    .replace(/[-_]/g, '');
};

export const getComponentTree = (vm): Array<string> => {
  const tree: [string] = [formatComponentName(vm)];
  const findParent = (vm) => {
    if (vm.$parent) {
      tree.push(formatComponentName(vm.$parent));
      findParent(vm.$parent);
    }
  };
  findParent(vm);
  return tree;
};

/** Removes sensitive data from URLs so that they are not stored in DataDog */
export function redactUrl(url: string) {
  return url
    .replace(/email=[^&]*/, 'email=REDACTED')
    .replace(/firstname=[^&]*/, 'firstname=REDACTED')
    .replace(/lastname=[^&]*/, 'lastname=REDACTED')
    .replace(/token=[^&]*/, 'token=REDACTED');
}
