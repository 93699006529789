/* eslint-disable max-classes-per-file */
export class ActivityLogResponse {
  id?: number;

  name?: string;

  user_id?: number | null;

  server_id?: number;

  organization_id?: number;

  policy_id?: number | null;

  data?: ActivityLogResponseData | string;

  server_name?: string;

  policy_name?: string;

  policy_type_name?: string;

  create_time?: string;

  filterValue?: string;

  status?: number;

  constructor(init: Partial<ActivityLogResponse>) {
    Object.assign(this, init);
  }
}

export class ActivityLogResponseData {
  text?: string;

  status?: number;

  constructor(init: Partial<ActivityLogResponseData>) {
    Object.assign(this, init);
  }
}

export enum DateRanges {
  Last30Days = 'last30days',
  Last7Days = 'last7days',
  Yesterday = 'yesterday',
  Today = 'today',
  Custom = 'custom',
}
