import { RouteConfig } from 'vue-router';
import i18n from '@/i18n';
import { trackDeprecatedRoute } from '../router-utilities';

export const workletCatalogRoutes: RouteConfig[] = [
  {
    path: 'community-worklets',
    name: 'communityWorklets',
    redirect: (to) => {
      trackDeprecatedRoute(to);
      const { query } = { ...to };
      const name = 'workletCatalog';
      return { name, query };
    },
    meta: {
      layout: 'app',
      title: i18n.t('routeTitles.workletCatalog'),
    },
  },
  {
    path: 'worklet-catalog',
    name: 'workletCatalog',
    component: () => import('@/views/System/WorkletsCatalog.vue'),
    meta: {
      layout: 'app',
      title: i18n.t('routeTitles.workletCatalog'),
    },
  },
  {
    path: 'community-worklets/:wid',
    name: 'communityWorkletDetails',
    redirect: (to) => {
      trackDeprecatedRoute(to);
      const { query } = { ...to };
      const name = 'workletDetails';
      return { name, query };
    },
    meta: {
      layout: 'app',
      title: i18n.t('routeTitles.workletDetails'),
    },
  },
  {
    path: 'worklet-catalog/:wid',
    name: 'workletDetails',
    component: () => import('@/views/System/Worklet/WorkletDetails.vue'),
    meta: {
      layout: 'app',
      title: i18n.t('routeTitles.workletDetails'),
    },
  },
];
