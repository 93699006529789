import { RouteConfig } from 'vue-router';

import { getFlagValue } from '@ax/feature-flag';
import { consoleI18n } from '@ax/console-i18n';
import { PlanName } from '@ax/data-services-account/models/billing';
import { AppStore } from '@/store';
import { FlagKeys } from '@/models/feature-flags';
import { GlobalRoutes } from '@/views/Global/global-routes';
import { USER_GET_PROFILE } from '@/store/actions/user';
import { PREFERENCES_GET_ORGANIZATION } from '@/store/actions/preferences';
import { orgHasTierOfAtLeast } from '@/compositions/useOrganizationTier';
import {
  trackDeprecatedRoute,
  guardRouteWithFlag,
  redirectTo404AfterAuth0,
} from './router-utilities';
import { systemManagementRoutes } from './system-management';
import { policyResultsRoutes } from './policy-results';
import { configurationsRoutes, macOsSettingsRoutes } from './configurations';

const routes: RouteConfig[] = [
  ...GlobalRoutes,
  ...systemManagementRoutes,
  ...configurationsRoutes,
  ...policyResultsRoutes,
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/Dashboard/Dashboard.vue'),
    beforeEnter: (to, _, next) => {
      const user = AppStore.getters[USER_GET_PROFILE];

      // If account has not completed QSG
      // and it is not skipped via param
      // and they have access to it
      // Route them there instead of dashboard
      if (
        user.account_id &&
        !user.account_completed_qsg &&
        to.query.onboarding !== '0'
      ) {
        getFlagValue(FlagKeys.onboardingZeroState).then((canView) => {
          if (canView) {
            next({
              path: '/onboarding',
              query: { o: to.query.o },
            });
          }
        });
      }

      next();
    },
    meta: {
      layout: 'app',
      title: 'Dashboard',
    },
  },
  {
    path: '/onboarding',
    name: 'onboarding',
    component: () => import('@/views/Onboarding/Onboarding.vue'),
    beforeEnter: (to, _, next) => {
      guardRouteWithFlag(to, next, FlagKeys.onboardingZeroState, {
        path: '/dashboard',
        query: { o: to.query.o },
      });
    },
    meta: {
      layout: 'app',
      title: consoleI18n.t('routeTitles.onboarding'),
    },
  },
  {
    path: '/devices',
    name: 'devices',
    component: () => import('@/views/Devices/Devices.vue'),
    meta: {
      layout: 'app',
      title: 'Devices',
    },
  },
  {
    path: '/device-detail',
    redirect: (to) => ({
      path: '/devices/:deviceId',
      query: { ...to.query, s: undefined },
      params: { deviceId: to.query.s.toString() },
    }),
  },
  {
    path: '/devices/device-detail',
    redirect: (to) => ({
      path: '/devices/:deviceId',
      query: { ...to.query, s: undefined },
      params: { deviceId: to.query.s.toString() },
    }),
  },
  {
    path: '/devices/:deviceId',
    name: 'deviceDetails',
    component: () => import('@/views/DeviceDetails/DeviceDetails.vue'),
    meta: {
      topLevelParent: 'Devices',
      layout: 'app',
      title: 'Device',
    },
  },
  {
    path: '/devices/:deviceId/remote-control',
    name: 'remoteControl',
    props: true,
    beforeEnter: (to, from, next) => {
      Promise.all([getFlagValue(FlagKeys.remoteControl)]).then(
        ([isRemoteControlFlagSet]) => {
          if (
            !isRemoteControlFlagSet ||
            !orgHasTierOfAtLeast(
              AppStore.getters[PREFERENCES_GET_ORGANIZATION],
              PlanName.tier3,
            )
          ) {
            next({ name: '404', query: to.query });
          }
          next({
            query: { o: to.query.o },
            params: { deviceId: to.params.deviceId },
          });
        },
      );
    },
    component: () => import('@/views/DeviceDetails/RemoteControlWrapper.vue'),
    meta: {
      layout: 'baseline',
      title: 'Remote Control',
      discardAsLastRoute: true,
      hideBanners: true,
    },
  },
  {
    path: '/software',
    name: 'software',
    component: () => import('@/views/Software/Software.vue'),
    meta: {
      layout: 'app',
      title: 'Software',
    },
  },
  {
    path: '/policy-manual-approval',
    name: 'policyManualApproval',
    component: () => import('@/views/System/Policy/PolicyManualApproval.vue'),
    meta: {
      layout: 'app',
      title: 'Policy Manual Approval',
      topLevelParent: 'Manage',
    },
  },
  {
    path: '/reports',
    name: 'reportsHome',
    component: () => import('@/views/Reports/Reports.vue'),
    meta: {
      layout: 'app',
      title: 'Reports',
    },
  },
  {
    path: '/data-extracts',
    redirect: '/reports/data-extracts',
  },
  {
    path: '/reports/data-extracts',
    name: 'dataExtracts',
    component: () => import('@/views/Reports/DataExtract/DataExtract.vue'),
    meta: {
      layout: 'app',
      title: 'Data Extracts',
      topLevelParent: 'Reports',
    },
  },
  {
    path: '/activity-log',
    redirect: '/reports/activity-log',
  },
  {
    path: '/reports/activity-log',
    name: 'activityLog',
    component: () =>
      import('@/views/Reports/ActivityLog/ActivityLogReport.vue'),
    meta: {
      layout: 'app',
      title: 'Activity Log',
      topLevelParent: 'Reports',
    },
  },
  {
    path: '/reports/overview',
    name: 'overview',
    component: () => import('@/views/Reports/Overview/Overview.vue'),
    meta: {
      layout: 'app',
      title: consoleI18n.t('routeTitles.reportsOverview'),
      topLevelParent: 'Reports',
    },
  },
  {
    path: '/reports-overview',
    name: 'reportsOverview',
    redirect: (to) => {
      trackDeprecatedRoute(to);
      const { query } = { ...to };
      const path = '/reports/overview';
      return { path, query };
    },
    meta: {
      layout: 'app',
      title: consoleI18n.t('routeTitles.reportsOverview'),
      topLevelParent: 'Reports',
    },
  },
  {
    path: '/reports-prepatch',
    name: 'reportsPrepatch',
    redirect: (to) => {
      trackDeprecatedRoute(to);
      const { query } = { ...to };
      const path = '/reports/prepatch';
      return { path, query };
    },
    meta: {
      layout: 'app',
      title: consoleI18n.t('routeTitles.reportsPrepatch'),
      topLevelParent: 'Reports',
    },
  },
  {
    path: '/reports/prepatch',
    name: 'prepatchReport',
    component: () => import('@/views/Reports/Prepatch/Prepatch.vue'),
    meta: {
      layout: 'app',
      title: consoleI18n.t('routeTitles.reportsPrepatch'),
      topLevelParent: 'Reports',
    },
  },
  {
    path: '/reports-noncompliant',
    name: 'nonCompliant',
    redirect: (to) => {
      trackDeprecatedRoute(to);
      const { query } = { ...to };
      const path = '/reports/needs-attention';
      return { path, query };
    },
    meta: {
      layout: 'app',
      title: consoleI18n.t('routeTitles.needsAttention'),
      topLevelParent: 'Reports',
    },
  },
  {
    path: '/reports/needs-attention',
    name: 'needsAttention',
    component: () =>
      import('@/views/Reports/NeedsAttention/NeedsAttention.vue'),
    meta: {
      layout: 'app',
      title: consoleI18n.t('routeTitles.needsAttention'),
      topLevelParent: 'Reports',
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/Settings/Settings.vue'),
    beforeEnter: (to, _, next) => {
      const { query } = { ...to };
      if (query.tab) {
        trackDeprecatedRoute(to);
        const path = `/settings/${query.tab === 'api' ? 'keys' : query.tab}`;
        delete query.tab;
        next({ path, query });
      }
      if (to.path === '/settings') {
        next({ path: '/settings/profile', query: to.query });
      }
      next();
    },
    meta: {
      layout: 'app',
      title: 'Settings',
    },
    children: [
      {
        path: 'profile',
        name: 'profile',
        component: () => import('@/views/Settings/ProfileTab.vue'),
        meta: {
          layout: 'app',
          title: 'User Profile',
        },
      },
      {
        path: 'billing',
        name: 'billing',
        component: () => import('@/views/Settings/BillingTab.vue'),
        meta: {
          layout: 'app',
          title: 'Billing',
        },
      },
      {
        path: 'users',
        name: 'users',
        component: () => import('@/views/Settings/UserAccountsTab.vue'),
        meta: {
          layout: 'app',
          title: 'User Accounts',
        },
      },
      {
        path: 'keys',
        name: 'keys',
        component: () => import('@/views/Settings/ApiTab.vue'),
        meta: {
          layout: 'app',
          title: 'Keys',
        },
      },
      {
        path: 'security',
        name: 'security',
        beforeEnter: redirectTo404AfterAuth0(FlagKeys.auth0Milestone2),
        component: () => import('@/views/Settings/SecurityTab.vue'),
        meta: {
          layout: 'app',
          title: 'Security',
        },
      },
      ...macOsSettingsRoutes,
    ],
  },
];

export default routes.map((route) => {
  const meta = { ...route.meta, public: false };

  return { ...route, meta };
});
