import Vue from 'vue';
import {
  datadogRum,
  RumErrorEvent,
  RumInitConfiguration,
} from '@datadog/browser-rum';
import { env } from '@ax/env';
import { getComponentTree, redactUrl } from './errorReporterUtils';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    FS: any; // FullStory
  }
}

// We'll populate this within Vue.config.errorHandler
// and send it along with DataDog error events
// to give us context about the Vue component tree
interface lastVueError {
  message: string;
  context: {
    componentTree?: Array<string>;
  };
}
let lastVueError: lastVueError | null = null;

/**
 * Extends the Vue error handler (https://vuejs.org/api/application.html#app-config-errorhandler)
 * for uncaught errors propagating from within the application and allows us to capture the component tree
 * @returns void
 */
function trackVueExceptions() {
  // quit if Vue isn't on the page
  if (!Vue || !Vue.config) {
    return;
  }

  const prevHandler = Vue.config.errorHandler;
  Vue.config.errorHandler = function VueErrorHandler(error, vm) {
    try {
      const componentTree = getComponentTree(vm);
      // Store the component tree to send to DataDog as extra context
      lastVueError = {
        message: error.message,
        context: {
          componentTree,
        },
      };
    } catch (e) {
      // Won't record the component tree
    }

    if (typeof console !== 'undefined' && typeof console.error === 'function') {
      console.error(error);
    }

    if (typeof prevHandler === 'function') {
      prevHandler.call(this, error, vm, '');
    }
  };
}

/**
 * Initializes the datadogRum client with some hardcoded configuration options
 * while exposing certain configuration options for the host application to set
 *
 * Full documentation of all the configuration options is available here:
 * https://docs.datadoghq.com/real_user_monitoring/browser/#configuration
 *
 * @param initConfiguration.applicationId the ID provided by DataDog for the application
 * @param initConfiguration.clientToken the token provided by DataDog for the application
 * @param initConfiguration.service the name of the application in DataDog
 * @param initConfiguration.trackInteractions disable user interaction tracking by setting this to false
 * @param initConfiguration.defaultPrivacyLevel https://docs.datadoghq.com/real_user_monitoring/session_replay/privacy_options
 * @param initConfiguration.beforeSend function to call on the event before it is sent to DataDog
 * @returns The initialized datadogRum client
 */
export const initErrorReporter = ({
  applicationId,
  clientToken,
  service,
  version,
  trackInteractions = true,
  defaultPrivacyLevel = 'mask-user-input',
  beforeSend,
}: RumInitConfiguration) => {
  datadogRum.init({
    applicationId,
    clientToken,
    service,
    version,
    site: 'datadoghq.com',
    env: env.NODE_ENV === 'production' ? 'legacy-prod' : 'legacy-dev',
    sampleRate: 100,
    trackInteractions,
    defaultPrivacyLevel,
    allowedTracingOrigins: [
      /.*localhost:\d*/,
      /https:\/\/.*\.automox-dev\.com/,
      /https:\/\/.*\.automox\.com/,
    ],
    beforeSend: (event, context) => {
      if (event.view?.url) {
        event.view.url = redactUrl(event.view.url);
      }

      if (event.type === 'resource' && event.resource?.url) {
        event.resource.url = redactUrl(event.resource.url);
      }

      if (event.type === 'action') {
        // Scrub likely email addresses
        if (event.action.target?.name) {
          event.action.target.name = event.action.target.name.replace(
            /\S+@\S+\.\S+/g,
            '[email address]',
          );
        }
      }

      if (event.type === 'error') {
        if (event.error.resource?.url) {
          event.error.resource.url = redactUrl(event.error.resource.url);
        }
        // Add Vue component tree context
        if (
          lastVueError &&
          (event as RumErrorEvent)?.error.message.includes(lastVueError.message)
        ) {
          event.context = {
            ...event.context,
            ...lastVueError.context,
          };
          lastVueError = null;
        }

        // Optionally attach a FullStory Session URL
        if (window.FS?.getCurrentSessionURL) {
          try {
            const fullStorySessionUrl = window.FS.getCurrentSessionURL(true);
            event.context = {
              ...event.context,
              fullstory: fullStorySessionUrl,
            };
          } catch {
            // Won't add FullStory session URL
          }
        }
      }

      // Any additional beforeSend modifications
      if (beforeSend) {
        beforeSend(event, context);
      }
    },
  });

  trackVueExceptions();

  return datadogRum;
};

export const errorReporter = datadogRum;
