import { Ref, shallowRef, watch } from '@vue/composition-api';
import { WatchOptions } from 'vue';

/**
 * A FilterFn<A> indicates whether the ref should be updated
 * with the new value `A`. If it returns `true`, it means
 * the ref should be updatef
 */
export interface FilterFn<A> {
  (newValue: A, previousValue?: A | undefined): boolean;
}

/**
 * Use `updateWhen_` or `updateWhen` to filter updates to a `Ref<A>` by use of a `FilterFn<A>`. You
 * can think of `updateWhen` as creating a filtered stream of values `A`.
 *
 * @example
 * import { pipe } from '@ax/function-utils'
 * import { updateWhen, updateWhen_, map, map_ } from '@ax/vue-utils/ref'
 *
 * // make sure that only new values trigger a ref update
 * function isUniq(newNumber: number, oldNumber: number | undefined) {
 *  return newNumber !== oldNumber
 * }
 *
 * function addOne(num: number) {
 *  num + 1
 * }
 *
 * const source = shallowRef(0);
 *
 * // without pipe
 * const uniqAddOne = map_(updateWhen_(source, isUniq), addOne);
 *
 * // with pipe
 * const uniqAddOne = pipe(source, updateWhen(isUniq), map(addOne));
 *
 * @param source The source `Ref<A>` to be filtered
 * @param filterFn The function used to filter `source`
 * @param options Additional watch options
 * @returns `Ref<A | undefined>`
 */
export function updateWhen_<A>(
  source: Ref<A>,
  filterFn: FilterFn<A>,
  options: Omit<WatchOptions, 'immediate'> = {},
): Ref<A | undefined> {
  const filtered = shallowRef<A | undefined>();
  watch(
    source,
    (newVal) => {
      if (filterFn(newVal, filtered.value)) {
        filtered.value = newVal;
      }
    },
    {
      ...options,
      immediate: true,
    },
  );
  return filtered;
}

/**
 * Curried version of `updateWhen_`
 *
 * Use `updateWhen_` or `updateWhen` to filter updates to a `Ref<A>` by use of a `FilterFn<A>`. You
 * can think of `updateWhen` as creating a filtered stream of values `A`.
 *
 * @example
 * import { pipe } from '@ax/function-utils'
 * import { updateWhen, updateWhen_, map, map_ } from '@ax/vue-utils/ref'
 *
 * // make sure that only new values trigger a ref update
 * function isUniq(newNumber: number, oldNumber: number | undefined) {
 *  return newNumber !== oldNumber
 * }
 *
 * function addOne(num: number) {
 *  num + 1
 * }
 *
 * const source = shallowRef(0);
 *
 * // without pipe
 * const uniqAddOne = map_(updateWhen_(source, isUniq), addOne);
 *
 * // with pipe
 * const uniqAddOne = pipe(source, updateWhen(isUniq), map(addOne));
 */
export function updateWhen<A>(
  filterFn: FilterFn<A>,
  options: Omit<WatchOptions, 'immediate'> = {},
) {
  return (source: Ref<A>) => updateWhen_(source, filterFn, options);
}
