import { Ref, computed } from '@vue/composition-api';

/**
 * Used to map a `Ref<A>` into a `Ref<B>`
 *
 * @example
 * import { pipe } from '@ax/function-utils'
 * import { map, map_ } from '@ax/vue-utils/ref'
 *
 *
 * function addOne(num: number) {
 *  num + 1
 * }
 *
 * const source = shallowRef(0);
 *
 * // without pipe
 * const uniqAddOne = map_(source, addOne);
 *
 * // with pipe
 * const uniqAddOne = pipe(source, map(addOne));
 *
 * @param self `Ref<A>`
 * @param f a function that maps `A` to `B`
 * @returns `Ref<B>`
 */
export function map_<A, B>(self: Ref<A>, f: (a: A) => B) {
  return computed(() => f(self.value));
}

/**
 * Curried version of `map_`
 * @example
 * import { pipe } from '@ax/function-utils'
 * import { map, map_ } from '@ax/vue-utils/ref'
 *
 *
 * function addOne(num: number) {
 *  num + 1
 * }
 *
 * const source = shallowRef(0);
 *
 * // without pipe
 * const uniqAddOne = map_(source, addOne);
 *
 * // with pipe
 * const uniqAddOne = pipe(source, map(addOne));
 *
 * @param f maps `A` to `B`
 */
export function map<A, B>(f: (a: A) => B) {
  return (self: Ref<A>) => map_(self, f);
}
