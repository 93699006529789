import { CacheFirst, makeQueryable } from '@ax/cache-and-dedupe-vue';
import { success } from '@ax/type-utils';
import {
  AxiosQuery,
  defaultCatchHandler,
  QueryByOrgId,
} from '@ax/data-services-common/queries';
import { fetchRoles, fetchPermissions } from '../clients/rbac.client';

import { Permission, Role } from '../models/rbac';

/**
 * The query for getting an organization's available `Role`s
 */
export class GetRolesByOrgId extends QueryByOrgId<readonly Role[]> {
  type = 'GetRolesByOrgId';
}

export const {
  runQuery: runGetRolesByOrgId,
  useQuery: useGetRolesByOrgIdQuery,
} = makeQueryable(
  (query: GetRolesByOrgId) => {
    return fetchRoles(String(query.orgId), {
      loaderEnabled: query.showLoader,
    })
      .then(({ data }) => success(data))
      .catch(defaultCatchHandler);
  },
  {
    networkPolicy: CacheFirst,
    /**
     * As of 04/18/2022 https://app.datadoghq.com/dash/integration/rum_resources?tpl_var_resourcePath=%22%2Fapi%2Frbacroles%22&from_ts=1647726414834&to_ts=1650404814834&live=true
     * The P90 reponse time was 5.1 seconds. Outside of two incidents however, it never eclipsed
     * 1 second.
     */
    timeout: 6_000,
  },
);

/**
 * The query for getting available Permissions
 */
export class GetPermissions extends AxiosQuery<{}, readonly Permission[]> {
  type = 'GetPermissions';
}

export const {
  runQuery: _runGetPermissions,
  useQuery: _useGetPermissionsQuery,
} = makeQueryable(
  ({ showLoader }: GetPermissions) => {
    return fetchPermissions({ loaderEnabled: showLoader })
      .then(({ data }) => success(data))
      .catch(defaultCatchHandler);
  },
  {
    /**
     * This data is very stable, it only changes when a role's permissions have
     * changed or if a new role has been added.
     */
    networkPolicy: CacheFirst,
    /**
     * P90 response time as of 04/19/2022 is 4.35 seconds https://app.datadoghq.com/dash/integration/rum_resources?tpl_var_resourcePath=%22%2Fapi%2Fpermissions%22&from_ts=1647726414834&to_ts=1650404814834&live=true
     * 5 seconds is a little tight, but the slow responses occurred during incidents which is a situation
     * in which we want to capture the error.
     */
    timeout: 5_000,
  },
);

/**
 * Since `GetPermissions` does not have any dynamic query params, the below
 * are helper functions so you don't have to supply `new GetPermissions({})`
 */
export function runGetPermissions() {
  return _runGetPermissions(new GetPermissions({}));
}

export function useGetPermissionsQuery() {
  return _useGetPermissionsQuery(new GetPermissions({}));
}
