export const DEFAULT_TABLE_PAGINATION = 25;
export const TABLE_ITEMS_PER_PAGE = [10, 25, 50, 100, 500];
export const DEFAULT_CHECKBOX_DEBOUNCE_TIME_TABLE_FILTER = 700;

export interface ExportColumn {
  id: string;
  name: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class TableOptions<Row = any> {
  page = 1;

  itemsPerPage: number = DEFAULT_TABLE_PAGINATION;

  sortBy: Array<keyof Row> = [];

  sortDesc: boolean[] = [];

  groupBy: string[] = [];

  groupDesc: boolean[] = [];

  multiSort = false;

  mustSort = false;

  constructor(init: Partial<TableOptions<Row>>) {
    Object.assign(this, init);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface TableHeader<T = any> {
  text: string;
  value: string & keyof T;
  align?: 'start' | 'center' | 'end';
  sortable?: boolean;
  filterable?: boolean;
  divider?: boolean;
  class?: string | string[];
  width?: string | number;
  filter?: (value: T, search: string, item: T) => boolean;
  sort?: (a: T, b: T) => number;
}
