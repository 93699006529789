/**
 * Coverts a quantity of minutes into hours and minutes (90 minutes into 1 hour, 30 minutes)
 * @param minutes The number of minutes, or string that can be parsed into an number
 * @returns An object with hours and minutes properties, both numbers. May be NaN for invalid inputs.
 */
export function parseToHoursMinutes(minutes: string | number) {
  const numMinutes = Number(minutes);
  return {
    hours: Math[numMinutes < 0 ? 'ceil' : 'floor'](numMinutes / 60),
    minutes: numMinutes % 60,
  };
}

/**
 * Converts hours and minutes into a sum of minutes (1 hour, 30 minutes into 90 minutes)
 * @param hours The number of hours, or string that can be parsed into an number
 * @param minutes The number of minutes, or string that can be parsed into an number
 * @returns The total number of minutes. May be NaN for invalid inputs.
 */
export function asMinutes(hours: string | number, minutes: string | number) {
  const numHours = Number(hours);
  const numMinutes = Number(minutes);
  return numHours * 60 + numMinutes;
}
