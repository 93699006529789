/**
 * Pagination Models
 *
 * Provides structure for the responses returned from paginated backend endpoints.
 *
 * The general REST API and routing specs:
 * {@link https://automox.atlassian.net/wiki/spaces/ENG/pages/1727561892/RFC%3A+REST+API+and+Routing+Specification}
 *
 * The java-rest-api-library used in a lot of our backend services
 * {@link https://github.com/PatchSimple/java-rest-api-library}
 */

import { AxiosResponse } from 'axios';
import { ErrorResponseData } from './src/error-handling';

export interface PaginationMetadata {
  page: number;
  limit: number;
  total: number;
  total_pages?: number;
  current_page?: number;
}

export interface PaginatedResponse<T, M = PaginationMetadata> {
  metadata: M;
  data: T[];
}

/**
 * @interface GlobalPaginationMetadata
 *
 * The metadata included with paginated api responses to help let the UI know what parameters
 * were used to generate the returned data.
 *
 * @member current_page The current page index. As of 2021/07/1, 0 is the starting page index.
 * @member limit The max number of entries to be returned (per page).
 * @member total_count The total number of entries.
 * @member total_pages The total number of pages of entries, based on the limit of entries per page.
 */
export interface GlobalPaginationMetadata {
  current_page: number;
  limit: number;
  total_count: number;
  total_pages: number;
}

/**
 * @interface GlobalPaginationResponse
 *
 * The structure of paginated responses from the backend. Includes the (pagination) metadata
 * and the array of entries.
 *
 * @member metadata The additional information about the response (pagination and number of results)
 * @member data The actual data from the api response for the given pagination range.
 */
export interface GlobalPaginationResponse<T, M = GlobalPaginationMetadata> {
  metadata: M;
  data: T[];
}

export type AxiosResponseError = AxiosResponse<ErrorResponseData>;

export class ApiClientError<E> {
  readonly type = 'ApiClientError';

  constructor(readonly error: E) {}
}
