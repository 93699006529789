import { isSuccess } from '@ax/type-utils';
import { cacheWhen } from './cache-when';

/**
 * Cache the successful completion of a request so that it is not repeated
 * on future invocations.
 * @param request The request to perform
 * @returns `Promise<Response<Succ, Err>>`
 */
export const cacheOnSuccess = cacheWhen(isSuccess);
