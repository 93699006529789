// eslint-disable-next-line max-classes-per-file
export class Worklet {
  id?: number;

  name?: string;

  category?: string;

  description?: string;

  keywords?: string[] = [];

  os_family?: string;

  creator?: string;

  is_verified?: boolean;

  evaluation_code?: string;

  remediation_code?: string;

  update_time?: string;

  constructor(init: Partial<Worklet>) {
    Object.assign(this, init);
  }
}

export class WorkletsResponse {
  size: number;

  results: Worklet[];

  constructor(results: Worklet[], size: number) {
    this.size = size;
    this.results = results;
  }
}
