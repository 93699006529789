import {
  LDUser,
  LDClient,
  initialize as initializeLDClient,
  LDFlagSet,
} from 'launchdarkly-js-client-sdk';

import VueRouter, { RawLocation, Route } from 'vue-router';
import { ref, Ref, watch, computed, SetupContext } from '@vue/composition-api';
import { env } from '@ax/env';

export interface FeatureFlagUser extends LDUser {
  custom?: {
    account: string;
    [key: string]: string | boolean | number | Array<string | boolean | number>;
  };
}

export type FlagValue = boolean | string;

export interface FlagConfig {
  key: string;
  defaultValue: FlagValue;
  updateOnPageNavigation?: boolean | ((to: Route, from: Route) => boolean); // defaults to true if omitted
  redirectToIfOff?: {
    from: string;
    to: (currentRoute: Route) => RawLocation | undefined;
  };
}

export type FlagKey = number;

export interface FeatureFlagConfig {
  getClientId: () => Promise<string>;

  getUser: () => FeatureFlagUser;

  watchUser: (
    cb: (user: FeatureFlagUser, oldUser?: FeatureFlagUser) => void,
  ) => void;

  flags: {
    [K in FlagKey]: FlagConfig;
  };
}

let ffConfig: FeatureFlagConfig | undefined;

export function configureFeatureFlag(config: FeatureFlagConfig) {
  if (ffConfig) {
    throw new Error('Feature Flag config cannot be set more than once.');
  }
  ffConfig = config;
}

function getFeatureFlagConfig(): FeatureFlagConfig {
  if (ffConfig === undefined) {
    throw new Error('Feature Flag must be configured before using it.');
  }
  return ffConfig;
}

// Disable Realtime flag updates to avoid changing the UI while the user is interacting with the application
// The flag refs will update only when the code below explicitly requests new variations
// This happens when the User or Current Org changes or before Page Navigation
// If enabled, this would provide more of a guarantee that the user immediately sees the "correct" variations,
// but this could cause bad UX in some edge cases. For example, if the flag forcibly redirects the user away
// while they were filling out a complicated form with lots of inputs. Ideally, there would be a UI promt for
// the user to opt-in to the updated flags instead of immediately applying them.
export const REALTIME_FLAGS = false;

let identifyPromise: Promise<LDFlagSet> | undefined;
let clientPromise: Promise<LDClient | undefined> | undefined;
let client: LDClient | undefined;
let clientReady: Ref<boolean> | undefined;
let clientError: Ref<boolean> | undefined;

// singleton store for all flags queried during the app's lifecycle
const queriedFlags: { [K in FlagKey]?: Ref<FlagValue> } = {};

// Object deep equality checking
// Used for comparing FeatureFlagUser objects, so makes some assumptions about absence of functions/methods
function flagUserDeepEqual(objA?: FeatureFlagUser, objB?: FeatureFlagUser) {
  if (objA === objB) {
    return true;
  }

  if (
    typeof objA !== 'object' ||
    typeof objB !== 'object' ||
    objA == null ||
    objB == null
  ) {
    return false;
  }

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) {
    return false;
  }

  return keysA.every((key) => {
    if (!keysB.includes(key)) {
      return false;
    }

    return flagUserDeepEqual(objA[key], objB[key]);
  });
}

function subscribeToFlag(flagKey: FlagKey) {
  if (REALTIME_FLAGS && client) {
    const { key } = getFeatureFlagConfig().flags[flagKey];
    client.on(`change:${key}`, (newValue) => {
      const flag = queriedFlags[flagKey];
      if (flag) {
        flag.value = newValue;
      }
    });
  }
}

function getQueriedFlagEntries(): [FlagKey, FlagConfig, Ref<FlagValue>][] {
  return Object.entries(queriedFlags).map(([flagKeyString, flagRef]) => {
    const flagKey = Number(flagKeyString) as FlagKey;
    return [flagKey, getFeatureFlagConfig().flags[flagKey], flagRef!];
  });
}

function getVariation({ key, defaultValue }: FlagConfig) {
  // Uncomment the code below to hardcode feature flag for local dev
  // DO NOT COMMIT WITH THIS CODE UNCOMMENTED!
  // if (key === 'example-feature-flag') {
  //   return true;
  // }

  return client ? client.variation(key, defaultValue) : defaultValue;
}

function evaluateFlags(
  flags: [FlagKey, FlagConfig, Ref<FlagValue>][] = getQueriedFlagEntries(),
) {
  flags.forEach(([flagKey, flag, flagRef]) => {
    const newValue = getVariation(flag);
    if (flagRef.value !== newValue) {
      flagRef.value = newValue;
    }

    subscribeToFlag(flagKey);
  });
}

// Called by the router when the current page changes, re-evaluate all flags for updates
export function evaluateFlagsOnPageNavigation(to: Route, from: Route) {
  // This is redundant if flags are updated in realtime before navigation
  // If the client is not yet ready, the flags will be updated later when it finishes loading
  // Do not re-evaluate if only query params are changed
  if (
    !REALTIME_FLAGS &&
    clientReady &&
    clientReady.value &&
    to.path !== from.path
  ) {
    evaluateFlags(
      getQueriedFlagEntries().filter(([flagKey]) => {
        // Assume flags should be updated unless updateOnPageNavigation is defined otherwise
        const { updateOnPageNavigation = true } =
          getFeatureFlagConfig().flags[flagKey];
        if (typeof updateOnPageNavigation === 'boolean') {
          return updateOnPageNavigation;
        }
        return updateOnPageNavigation(to, from);
      }),
    );
  }
}

async function getLDClient(): Promise<LDClient | undefined> {
  if (clientPromise) {
    return clientPromise;
  }

  const config = getFeatureFlagConfig();

  if (!client) {
    try {
      const clientId = await config.getClientId();
      if (!clientId) {
        return undefined;
      }

      let hasSetStreaming = false;
      client = initializeLDClient(clientId, config.getUser(), {
        streaming: REALTIME_FLAGS,
        // EVG-254: Uses REPORT http method instead of GET in order to accomodate large user objects
        useReport: true,
      });

      // When the current user or custom attributes change, update the LD client
      // If the identified user was previously anonymous, the client automatically aliases it to the new one
      // The feature flags values are re-evaluated with the new user based on any targeting
      config.watchUser((newUser, oldUser) => {
        // Compare the new and old users and only update LD and flags when something relevant changes
        if (!flagUserDeepEqual(newUser, oldUser)) {
          identifyPromise = client!.identify(newUser);
          if (!REALTIME_FLAGS) {
            identifyPromise.then(() => {
              evaluateFlags();
              identifyPromise = undefined;

              // Enable streaming even if REALTIME_FLAGS is off, so that calls to client.variation
              // will always return the current value of each flag.
              // We turn this on only after we identify our authenticated user to avoid
              // a problematic race condition (TTB-76)
              if (!hasSetStreaming) {
                client!.setStreaming(true);
                hasSetStreaming = true;
              }
            });
          }
        }
      });

      await client.waitForInitialization();
      evaluateFlags();
      clientReady!.value = true;
      clientError!.value = false;
    } catch (error) {
      if (client) {
        client.close();
      }

      identifyPromise = undefined;
      clientPromise = undefined;
      client = undefined;
      clientError!.value = true;
      clientReady!.value = false;

      throw error;
    }
  }

  return client;
}

/**
 * Before the flagsLoaded ref evaluates to true, the Flag values are set to their defaults
 * @returns Object with flagsLoaded ref that is true after the flags are evaluated
 * Used to avoid any undesirable flash of the "default" content before
 * LD flag values are loaded:
 * @example
 * <div v-if="!flagsLoaded">Placeholder Content</div>
 * <div v-else-if="myFlag">Flagged Content</div>
 * <div v-else>Default Content</div>
 * @desc
 * The returned object also includes separate flagsReady and flagsError refs
 * if it is necessary to determine if the flags were defaulted due to LD error or not
 */
export function getFlagsLoaded() {
  if (!clientReady || !clientError) {
    clientReady = ref(false);
    clientError = ref(false);
  }
  return {
    flagsLoaded: computed(() => clientReady!.value || clientError!.value),
    flagsReady: clientReady,
    flagsError: clientError,
  };
}

/**
 * Setup Refs for a list of feature flags the app should keep track of
 * If the flag is new and the client is ready, subscribe to changes of the flag
 * @param flags a list of flags a component cares about
 */
function initializeFlags(flagKeys: FlagKey[]): void {
  const { flagsReady } = getFlagsLoaded();

  flagKeys.forEach((flagKey) => {
    const flag = getFeatureFlagConfig().flags[flagKey];
    if (!queriedFlags[flagKey]) {
      if (client && flagsReady.value) {
        queriedFlags[flagKey] = ref(getVariation(flag));
        subscribeToFlag(flagKey);
      } else {
        queriedFlags[flagKey] = ref(flag.defaultValue);
      }
    }
  });
}

/**
 * If a flag value changes to false (because a user switched organizations or similar action)
 * Redirect to the configured path since the current route is no longer supported in the user's new context.
 * @returns true iff a redirect occurs
 */
function runRedirects(
  router: VueRouter,
  flags: FlagConfig[],
  flagRefs: Ref<FlagValue>[],
) {
  return flags.some((flag, i) => {
    const flagRef = flagRefs[i];
    const { redirectToIfOff } = flag;
    if (redirectToIfOff && flagRef && flagRef.value === false) {
      const { from, to } = redirectToIfOff;
      if (from && router.currentRoute.path.startsWith(from)) {
        const location = to(router.currentRoute);
        if (location) {
          router.push(location);
          return true; // break
        }
      }
    }
    return false;
  });
}

/**
 * For use within components -
 * Flag Ref values will update when a user logs in or the current org is switched
 * @param {VueRouter} router the app's router instance, passing in context is deprecated
 * @param flags a list of flags a component cares about
 * @returns {Ref<FlagValue>[]} Refs containing flag values that will update when queried
 */
export function useFeatureFlags(
  routerContext: VueRouter | SetupContext,
  flagKeys: FlagKey[],
): Ref<FlagValue>[] {
  initializeFlags(flagKeys);
  clientPromise = getLDClient();

  const flagRefs = flagKeys.map((flagKey) => queriedFlags[flagKey]!);

  const flags = flagKeys.map(
    (flagKey) => getFeatureFlagConfig().flags[flagKey],
  );
  const keysHaveRedirects = flags.some(
    ({ redirectToIfOff }) => redirectToIfOff !== undefined,
  );
  if (keysHaveRedirects) {
    const router =
      routerContext instanceof VueRouter
        ? routerContext
        : routerContext.root.$router;
    // Watch refs for changes from any source, and redirect away if necessary
    watch(
      flagRefs,
      () => {
        runRedirects(router, flags, flagRefs);
      },
      {
        immediate: true,
      },
    );
  }

  return flagRefs;
}

/**
 * Same as {@link useFeatureFlags}, but for a single flag
 * @param {VueRouter} router the app's router instance, passing in context is deprecated
 * @param {FlagKeys} flagKey a flag a component cares about
 * @returns {Ref<FlagValue>} Ref containing flag value that will update when queried
 */
export function useFeatureFlag(
  routerContext: VueRouter | SetupContext,
  flagKey: FlagKey,
): Ref<FlagValue> {
  return useFeatureFlags(routerContext, [flagKey])[0];
}

/**
 * For use outside of components -
 * Flag Ref values will update when this function is called
 * @param flag single flag object to evaluate
 * @returns {Promise<FlagValue>}
 */
export async function getFlagValue(flagKey: FlagKey): Promise<FlagValue> {
  initializeFlags([flagKey]);
  clientPromise = getLDClient();
  await clientPromise;
  if (identifyPromise) {
    await identifyPromise;
  }
  return queriedFlags[flagKey]!.value;
}

// For use only by unit tests to reset internal state back to initial conditions
export function unitTestReset() {
  if (env.NODE_ENV === 'test') {
    ffConfig = undefined;
    identifyPromise = undefined;
    clientPromise = undefined;
    client = undefined;
    clientReady = undefined;
    clientError = undefined;
    Object.keys(queriedFlags).forEach((key) => {
      delete queriedFlags[key];
    });
  }
}
