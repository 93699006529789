import { computed, watch, SetupContext, Ref } from '@vue/composition-api';

import { User } from '@ax/data-services-user/models/user';
import { RbacRole } from '@ax/data-services-authorization/models/rbac';
import { Organization } from '@ax/data-services-zone/models/organization';
import {
  USER_REQUEST,
  USER_GET_PROFILE,
  USER_IS_LOADING,
} from '@/store/actions/user';
import {
  PREFERENCES_GET_ORGANIZATION,
  PREFERENCES_SET_ORGANIZATION,
  PREFERENCES_GET_ROLE,
  PREFERENCES_SET_ROLE,
} from '@/store/actions/preferences';

interface ComposedPreferences {
  currentOrganization: Ref<Organization>;
  currentRole: Ref<RbacRole>;
  loading: Ref<boolean>;
  selectOrganization: (organization: Organization) => Promise<void>;
}

export default function usePreferences(
  context: SetupContext,
): ComposedPreferences {
  let queryOrgWatch;
  const query = computed(() => context.root.$route.query);
  const user = computed<User>(
    () => context.root.$store.getters[USER_GET_PROFILE],
  );
  const currentOrganization = computed<Organization>(
    () => context.root.$store.getters[PREFERENCES_GET_ORGANIZATION],
  );
  const currentRole = computed<RbacRole>(
    () => context.root.$store.getters[PREFERENCES_GET_ROLE],
  );
  const loading = computed<boolean>(
    () => context.root.$store.getters[USER_IS_LOADING],
  );

  function changeOrganization(organization: Organization) {
    context.root.$store.dispatch(PREFERENCES_SET_ORGANIZATION, organization);
  }

  function changeRole(role: RbacRole) {
    context.root.$store.dispatch(PREFERENCES_SET_ROLE, role);
  }

  function selectOrganization(organization: Organization): Promise<void> {
    return new Promise((resolve) => {
      context.root.$router
        .replace({
          query: { ...context.root.$route.query, o: String(organization.id) },
        })
        .catch(() => {
          return null;
        });
      resolve();
    });
  }

  if (!queryOrgWatch) {
    queryOrgWatch = watch(
      [user, query],
      (next, prev) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const [nextUser, nextQuery]: (User | any)[] = next;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const [prevUser, prevQuery]: (User | any)[] = prev || [];
        if (
          !nextUser &&
          !prevUser &&
          (!nextQuery.org || !nextQuery.o) &&
          !loading.value
        ) {
          context.root.$store.dispatch(USER_REQUEST);
        }

        if (nextUser) {
          const orgId = parseInt((nextQuery.org || nextQuery.o) as string, 10);
          const prevOrgId =
            prevQuery && parseInt((prevQuery.org || prevQuery.o) as string, 10);

          if (orgId && orgId !== prevOrgId) {
            /**
             * If we can't find an `Organization` in `user.orgs` that matches
             * `orgId`, it means we are trying to go to an `Organization` the user does not
             * have access to. In this case, we will redirect to the first `Organization`
             * available.
             */
            const nextOrg: Organization | undefined = nextUser.orgs.find(
              (org) => org.id === orgId,
            );
            if (nextOrg) {
              /**
               * The user has access to the `orgId` specified, we can continue with the updates
               */
              const nextRole: RbacRole = nextUser.rbac_roles.find(
                (role) => role.organization_id === orgId,
              );
              changeOrganization(nextOrg);
              changeRole(nextRole);
            } else {
              /**
               * User does not have access to `orgId`, we should redirect to an org the user
               * has access to.
               */
              selectOrganization(nextUser.orgs[0]);
            }
          }

          if (!orgId) {
            const nextOrg: Organization =
              context.root.$store.getters[PREFERENCES_GET_ORGANIZATION] ||
              nextUser.orgs[0];
            selectOrganization(nextOrg);
          }
        }
      },
      {
        immediate: true,
      },
    );
  }

  if (context.root.$route.query.throwTestError) {
    context.root.$nextTick().then(() => {
      throw new Error('This is a test error.  It can be ignored.');
    });
  }

  return {
    currentOrganization,
    currentRole,
    loading,
    selectOrganization,
  };
}
