import { isNullable } from './is-nullable';
import { not_ } from '../core';

/**
 * Use to check if `A>` is `NonNullable<A>`
 * @param value
 * @returns `value` is `NonNullable<A>`
 */
export function isNonNullable<A>(val: A): val is NonNullable<A> {
  return not_(val, isNullable);
}
