// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isKey<W extends string, A extends Record<W, any>>(obj: A) {
  return (key: string): key is keyof A & W =>
    Object.prototype.hasOwnProperty.call(obj, key);
}

/**
 * extract the keys out of an object
 * @param obj The object you want to extract the keys out of
 * @returns a sorted array of `object`'s keys
 */
export function keys<A>(obj: A): ReadonlyArray<keyof A> {
  return Object.keys(obj).filter(isKey(obj)).sort();
}
