import { EnvConfig, EnvValue, InputEnv, OutputEnv } from './models';

/**
 * Typecasts and sets default for env from an env config.
 *
 * @param envConfig Configuration for the output env.
 * @param env Incoming env (from process.env or import.meta.env)
 * @returns Output env with defaults set
 */
export function getEnv<Config extends EnvConfig>(
  envConfig: Config,
  env: InputEnv,
): OutputEnv<Config> {
  return Object.keys(envConfig).reduce(
    (outputEnv, envConfigKey: keyof Config) => {
      const envConfigProperty = envConfig[envConfigKey];
      const envValue = env[envConfigProperty.env];
      let outValue: EnvValue;
      if (envValue === undefined) {
        outValue = envConfigProperty.default;
      } else if (
        typeof envValue === 'string' &&
        ['true', 'false'].includes(envValue)
      ) {
        // LEGACY VUE_APP_ BOOLEAN STRING SUPPORT
        // Can be removed once Vite is fully adopted for build
        outValue = envValue === 'true';
      } else {
        outValue = envValue;
      }
      return { ...outputEnv, [envConfigKey]: outValue };
    },
    {} as OutputEnv<Config>,
  );
}
