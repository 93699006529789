import { NavigationGuardNext, Route, RouteConfig } from 'vue-router';
import { globalViewDevicesRoutes } from '@ax/features-devices/router/global-view';
import { getFlagValue } from '@ax/feature-flag';
import { PlanName } from '@ax/data-services-account/models/billing';
import i18n from '@/i18n';
import { getAccountManagementAccess } from '@/compositions/useAccount';
import { getAuth0Milestone1Flag } from '@/compositions/useAuthentication';
import { getCurrentUser } from '@/compositions/useUser';
import { FlagKeys } from '@/models/feature-flags';

import { useOrganizationTier } from '@/compositions/useOrganizationTier';

function restrictToGlobalAdmins(
  to: Route,
  _: Route,
  next: NavigationGuardNext<Vue>,
) {
  getAccountManagementAccess().then((canAccess) => {
    if (!canAccess) {
      next({ name: '404', query: to.query });
    }
    next();
  });
}

function restrictToAuth0Milestone1Flag(
  to: Route,
  _: Route,
  next: NavigationGuardNext<Vue>,
) {
  Promise.all([getAccountManagementAccess(), getAuth0Milestone1Flag()]).then(
    ([hasAccountManagementAccess, auth0Milestone1FlagEnabled]) => {
      if (!hasAccountManagementAccess || !auth0Milestone1FlagEnabled) {
        next({ name: '404', query: to.query });
      }
      next();
    },
  );
}

/**
 * Redirect to the dashboard if a best-guess org ID can be resolved.
 * If an orgId cannot be resolved, display the 404 page.
 * @param to the route pending activation
 * @param next
 */
function redirectToDashboard(to: Route, next: NavigationGuardNext<Vue>) {
  getCurrentUser()
    .then((user) => {
      const targetOrg = to.query.o
        ? user.orgs.find(({ id }) => String(id) === to.query.o)
        : undefined;
      if (targetOrg?.id) {
        next({ name: 'dashboard', query: { o: String(targetOrg.id) } });
      } else if (user.orgs[0].id) {
        next({ name: 'dashboard', query: { o: String(user.orgs[0].id) } });
      } else {
        next({ name: '404', query: to.query });
      }
    })
    .catch(() => {
      next({ name: '404', query: to.query });
    });
}

export const GlobalRoutes: RouteConfig[] = [
  {
    path: '/global',
    name: 'global',
    redirect: { name: 'setupAndConfiguration' },
  },
  ...globalViewDevicesRoutes.map((topLevelFeatureRoute) => ({
    ...topLevelFeatureRoute,
    beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
      Promise.all([
        getAccountManagementAccess(),
        getFlagValue(FlagKeys.globalViewDevicesTable),
      ]).then(([hasPermission, flagOn]) => {
        if (hasPermission && flagOn) {
          next();
        } else {
          redirectToDashboard(to, next);
        }
      });
    },
  })),
  {
    path: '/global/setup',
    beforeEnter: restrictToGlobalAdmins,
    component: () => import('@/views/Global/Setup/Setup.vue'),
    meta: {
      layout: 'app',
      title: i18n.t('routeTitles.zones'),
    },
    children: [
      {
        path: '/',
        name: 'setupAndConfiguration',
        redirect: { name: 'zoneList' },
      },
      {
        path: 'zones',
        name: 'zoneList',
        component: () => import('@/views/Global/Setup/ZonesTab.vue'),
        meta: {
          layout: 'app',
          title: i18n.t('routeTitles.zones'),
        },
      },
      {
        path: 'roles-and-permissions',
        name: 'rolesAndPermissionsList',
        component: () =>
          import('@/views/Global/Setup/RolesAndPermissionsTab.vue'),
        meta: {
          layout: 'app',
          title: i18n.t('routeTitles.rolesAndPermissions'),
        },
      },
      {
        path: 'users',
        name: 'userList',
        component: () => import('@/views/Global/Setup/UsersTab.vue'),
        meta: {
          layout: 'app',
          title: i18n.t('routeTitles.users'),
        },
      },
      {
        path: 'authentication',
        beforeEnter: restrictToAuth0Milestone1Flag,
        name: 'connectionList',
        component: () => import('@/views/Global/Setup/AuthenticationTab.vue'),
        meta: {
          layout: 'app',
          title: i18n.t('routeTitles.connectionList'),
        },
      },
      {
        path: 'domains',
        beforeEnter: restrictToAuth0Milestone1Flag,
        name: 'domainList',
        component: () => import('@/views/Global/Setup/DomainsTab.vue'),
        meta: {
          layout: 'app',
          title: i18n.t('routeTitles.domainList'),
        },
      },
      {
        path: 'settings',
        name: 'globalSettings',
        component: () => import('@/views/Global/Setup/SettingsTab.vue'),
        meta: {
          layout: 'app',
          title: i18n.t('routeTitles.globalSettings'),
        },
      },
    ],
  },
  {
    path: '/global/setup/authentication/create-connection',
    beforeEnter: restrictToAuth0Milestone1Flag,
    name: 'createConnection',
    component: () =>
      import('@/views/Global/Setup/Authentication/AddConnection.vue'),
    meta: {
      layout: 'app',
      title: i18n.t('routeTitles.createConnection'),
    },
  },
  {
    path: '/global/setup/authentication/:connectionId',
    beforeEnter: restrictToAuth0Milestone1Flag,
    name: 'connectionEditor',
    component: () =>
      import('@/views/Global/Setup/Authentication/EditConnection.vue'),
    meta: {
      layout: 'app',
      title: i18n.t('routeTitles.connectionEditor'),
    },
  },
  {
    path: '/global/setup/create-zone',
    redirect: {
      name: 'createZone',
    },
  },
  {
    path: '/global/setup/zones/create-zone',
    beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
      Promise.all([getAccountManagementAccess()]).then(([hasPermission]) => {
        if (hasPermission) {
          const { parentOrgHasTierOfAtLeast } = useOrganizationTier();
          if (parentOrgHasTierOfAtLeast(PlanName.tier3).value) {
            next();
          } else {
            next({ name: '404', query: to.query });
          }
        } else {
          next({ name: '404', query: to.query });
        }
      });
    },
    name: 'createZone',
    component: () => import('@/views/Global/Setup/Zones/AddZone.vue'),
    meta: {
      layout: 'app',
      title: i18n.t('routeTitles.createZone'),
    },
  },
  {
    path: '/global/setup/zone-editor',
    redirect: (to) => ({
      name: 'zoneEditor',
      query: { ...to.query, zid: undefined },
      params: { zoneId: to.query.zid.toString() },
    }),
  },
  {
    path: '/global/setup/zones/:zoneId',
    beforeEnter: restrictToGlobalAdmins,
    name: 'zoneEditor',
    component: () => import('@/views/Global/Setup/Zones/EditZone.vue'),
    meta: {
      layout: 'app',
      title: i18n.t('routeTitles.zoneEditor'),
    },
  },
  {
    path: '/global/setup/create-user',
    redirect: {
      name: 'createUser',
    },
  },
  {
    path: '/global/setup/users/create-user',
    beforeEnter: restrictToGlobalAdmins,
    name: 'createUser',
    component: () => import('@/views/Global/Setup/Users/AddUser.vue'),
    meta: {
      layout: 'app',
      title: i18n.t('routeTitles.addUser'),
    },
  },
  {
    path: '/global/setup/user-editor',
    redirect: (to) => ({
      name: 'editUser',
      query: { ...to.query, uid: undefined },
      params: {
        userId: to.query.uid.toString(),
      },
    }),
  },
  {
    path: '/global/setup/users/:userId',
    beforeEnter: restrictToGlobalAdmins,
    name: 'editUser',
    component: () => import('@/views/Global/Setup/Users/EditUser.vue'),
    meta: {
      layout: 'app',
      title: i18n.t('routeTitles.editUser'),
    },
  },
];
