import { ref, toRef } from '@vue/composition-api';
import VInput from 'vuetify/lib/components/VInput';

export const useFormField = (props) => {
  /* Show the hint even if there are errors */
  const error = toRef(props, 'error');
  const errorState = ref(error.value); // whether the field is in the "error" state
  const showHint = ref(false); // enables us to always show hint, even if there are errors
  const hasFocusVisible = ref(false); // stubbing the :focus-visible functionality for better focus handling

  let preventFocusVisible = false; // used to prevent focus visible on external label click
  const errorIconOffset = ref(14);

  const checkForError = (field: VInput, value) => {
    showHint.value = !!(
      (field.internalErrorMessages.length ||
        field.errorBucket.length ||
        field.errorMessages.length) &&
      field.shouldValidate &&
      field.hasError
    );
    errorState.value = (error.value || value) && field.hasState;
  };

  const checkFocus = (field: VInput) => {
    hasFocusVisible.value = stubFocusVisible(field);
  };

  const stubFocusVisible = (field) => {
    if (preventFocusVisible) {
      preventFocusVisible = false;
      return false;
    }
    return field && field.isFocused && !field.hasMouseDown;
  };

  /* prevent :focus-visible state stub when external label is clicked, put in mounted hook */
  const preventLabelFocus = () => {
    const fieldName = props.id || props.name;
    const labelEl =
      fieldName && document.querySelector(`label[for="${fieldName}"]`);
    if (props.label === undefined && labelEl) {
      labelEl.addEventListener('click', () => {
        preventFocusVisible = true;
      });
    }
  };

  /* position error icon after stuff renders, need to account for possible append-outer */
  const initErrorIconPos = (field) => {
    const parent = field.$el;
    const child = field.$el.querySelector('.v-input__slot');
    if (child) {
      errorIconOffset.value =
        parent.getBoundingClientRect().right -
        child.getBoundingClientRect().right +
        14;
    }
  };

  return {
    checkFocus,
    checkForError,
    errorIconOffset,
    errorState,
    hasFocusVisible,
    initErrorIconPos,
    preventLabelFocus,
    showHint,
  };
};
