import { DataOptions } from 'vuetify';
import VueRouter, { Route } from 'vue-router';
import { stringify } from 'qs';
import { Ref, ref, watch } from '@vue/composition-api';

import { DEFAULT_TABLE_PAGINATION } from '@/utils/constants';
// eslint-disable-next-line import/no-cycle
import {
  QueryState,
  QueryStateParametersConfiguration,
  QueryStateParameterConfiguration,
  QueryStateManagementOptions,
} from '@/utils/query-state';
import { setItemInStorage, Storable } from '@/utils/storage';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface PaginatedAPIQuery<Row = any> {
  sort: keyof Row | Array<keyof Row>;
  dir: string;
  p: number;
  l: number;
}

/**
 * @deprecated instead use -> import { DataTableSynchronizationOptions } from '@ax/data-table-state';
 */
export interface DataTableSynchronizationOptions<State>
  extends QueryStateManagementOptions<State> {
  synchronizeApiOnly?: boolean;
  // Some API endpoints (software) pagination starts at 0, while others (history) start at 1.
  // This allows us to set the starting index to 1 instead of the default of 0.
  pageIndexStartingValue?: 0 | 1;
}

/**
 * @deprecated instead use -> import { VuetifyDataTableConfiguration } from '@ax/data-table-state';
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class VuetifyDataTableConfiguration<Row = any> implements DataOptions {
  page = 1;

  itemsPerPage: number = DEFAULT_TABLE_PAGINATION;

  sortBy: Array<string & keyof Row> = [];

  sortDesc: boolean[] = [];

  groupBy: string[] = [];

  groupDesc: boolean[] = [];

  multiSort = false;

  mustSort = false;

  constructor(init: Partial<VuetifyDataTableConfiguration<Row>>) {
    Object.assign(this, init);
  }

  public static fromQueryState<Row>(
    state: DataTableUiUrlParams,
  ): VuetifyDataTableConfiguration<Row> {
    const base = { page: state.page, itemsPerPage: state.limit };

    if (state.sort) {
      const [field, direction] = state.sort.split(':');
      return new VuetifyDataTableConfiguration({
        ...base,
        sortBy: [field] as Array<keyof Row & string>,
        sortDesc: [direction === 'desc'],
      });
    }

    return new VuetifyDataTableConfiguration({
      ...base,
      sortBy: [state.sort_by] as Array<keyof Row & string>,
      sortDesc: [state.sort_dir === 'desc'],
    });
  }
}

/**
 * @deprecated instead use -> import { DataTableUiUrlParams } from '@ax/data-table-state';
 */
export class DataTableUiUrlParams {
  page = 1;

  limit: number = DEFAULT_TABLE_PAGINATION;

  sort_by?: string;

  sort_dir?: string;

  sort?: string;

  public static fromTableState<Row, State extends DataTableUiUrlParams>(
    options: VuetifyDataTableConfiguration<Row>,
    configuration: QueryStateParametersConfiguration<State>,
  ): DataTableUiUrlParams {
    const base = { page: options.page, limit: options.itemsPerPage };

    if (configuration.sort) {
      return {
        ...base,
        sort: `${options.sortBy[0]}:${options.sortDesc[0] ? 'desc' : 'asc'}`,
      };
    }

    if (configuration.sort_by) {
      return {
        ...base,
        sort_by: options.sortBy[0],
        sort_dir: options.sortDesc[0] ? 'desc' : 'asc',
      };
    }

    return base;
  }
}
/**
 * @deprecated instead use -> import { DataTablePageState } from '@ax/data-table-state';
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class DataTablePageState<Row = any> extends DataTableUiUrlParams {
  filter_panel_open? = true;

  columns?: Array<Partial<keyof Row>> = [];

  o?: number;

  q?: string[];

  zone_id?: string;
}

/**
 * @deprecated instead use -> import { DataTableState } from '@ax/data-table-state';
 */
export class DataTableState<
  Row = any, // eslint-disable-line @typescript-eslint/no-explicit-any
  State extends DataTablePageState<Row> = DataTablePageState<Row>,
> {
  ignoreFirstTableUpdate = true;

  ignorePaginationMutation = false;

  bypassNextApiUrlUpdate = false;

  bypassNextUiUrlUpdate = false;

  debounceTimer: number | null = null;

  ignoreForDebounce = false;

  ignoredForDebouncePrev: DataTablePageState | null = null;

  ignoredForDebounceNext: DataTablePageState | null = null;

  tableState: Ref<VuetifyDataTableConfiguration<Row>> | null = null;

  queryState: Ref<State> | null = null;

  onStateChange: ((next: State, prev: State | undefined) => void) | null = null;

  configuration: QueryStateParametersConfiguration<State>;

  constructor(configuration: QueryStateParametersConfiguration<State>) {
    this.configuration = configuration;
  }

  /**
   * watch changes to vuetify data table props and update the containing query state object accordingly
   * (such as page, limit, sort...)
   */
  private watchTableState(): void {
    watch(
      this.tableState!,
      (next, prev) => {
        if (prev && this.ignoreFirstTableUpdate) {
          this.ignoreFirstTableUpdate = false;
        } else if (prev && !this.ignorePaginationMutation) {
          this.queryState!.value = {
            ...this.queryState!.value,
            ...DataTableUiUrlParams.fromTableState(
              next as VuetifyDataTableConfiguration<Row>,
              this.configuration!,
            ),
          };
        } else if (this.ignorePaginationMutation) {
          this.ignorePaginationMutation = false;
        }
      },
      {
        immediate: true,
      },
    );
  }

  /**
   * Returns a function to update browser storage, the browser URL, and the API URL upon state changes
   * (scoped to this instance's management variables)
   * (internal -- not intended for usage by clients of this class)
   */
  private generateOnStateChange(
    options: DataTableSynchronizationOptions<State>,
    router?: VueRouter,
    route?: Route,
  ): (next: State, prev: State | undefined) => void {
    const { storageConfig, mergeStore, callback, synchronizeApiOnly } = options;

    return (next: State, prev: State | undefined) => {
      if (this.ignoreForDebounce) {
        this.ignoredForDebounceNext = next;
        this.ignoredForDebouncePrev = prev as State | null;
        return;
      }
      if (prev && prev.page === next.page && next.page !== 1) {
        next.page = 1;
        this.tableState!.value.page = 1;
        this.ignorePaginationMutation = true;
      } else if (next.page !== this.tableState!.value.page) {
        // Need to update the page on tableState or footer won't be updated.
        // TODO: Should we be updating the entire tableState based on updated query?
        this.tableState!.value.page = next.page;
        this.ignorePaginationMutation = true;
      }

      if (storageConfig) {
        const toStore = Object.entries<QueryStateParameterConfiguration<State>>(
          this.configuration as {
            [s: string]: QueryStateParameterConfiguration<State>;
          },
        ).reduce((acc, [key, config]) => {
          if (config.storeInBrowser) {
            acc[key] = next[key];
          }
          return acc;
        }, {} as Partial<State> & Storable);

        setItemInStorage(toStore, storageConfig, mergeStore || false);
      }

      if (
        !synchronizeApiOnly &&
        route &&
        router &&
        this.bypassNextUiUrlUpdate
      ) {
        this.bypassNextUiUrlUpdate = false;
      } else if (!synchronizeApiOnly && route && router) {
        const uiQuery = DataTableState.toUiUrlQueryString<Row, State>(
          next,
          this.configuration!,
          options,
        );
        router.replace(`${route.path}?${uiQuery}`).catch((error) => {
          if (error.name !== 'NavigationDuplicated') {
            throw error;
          }
        });
      }

      if (this.bypassNextApiUrlUpdate) {
        this.bypassNextApiUrlUpdate = false;
      } else if (callback) {
        const apiQuery = DataTableState.toApiUrlQueryString<Row, State>(
          next,
          this.configuration!,
          options,
        );
        callback({ apiQuery });
      }
    };
  }

  /**
   * Returns a function for clients to call to update an instance's state
   * (scoped to this instance's management variables)
   */
  private generateUpdateQueryState() {
    return (
      slice: Partial<State>,
      updateOptions: {
        debounceTime?: number;
        bypassUiUrlUpdate?: boolean;
        bypassApiUrlUpdate?: boolean;
      } = {},
    ) => {
      const {
        debounceTime = 200,
        bypassApiUrlUpdate,
        bypassUiUrlUpdate,
      } = updateOptions;
      if (debounceTime > 0) {
        this.ignoreForDebounce = true;
        if (this.debounceTimer) {
          clearTimeout(this.debounceTimer);
          this.debounceTimer = null;
        }
        this.debounceTimer = setTimeout(() => {
          this.ignoreForDebounce = false;
          this.onStateChange!(
            this.ignoredForDebounceNext as State,
            this.ignoredForDebouncePrev as State,
          );
        }, debounceTime);
      }

      if (bypassApiUrlUpdate) {
        this.bypassNextApiUrlUpdate = true;
      }
      if (bypassUiUrlUpdate) {
        this.bypassNextUiUrlUpdate = true;
      }

      this.queryState!.value = {
        ...this.queryState!.value,
        ...slice,
      };
    };
  }

  /**
   * Apply configuration to state to generate a URL query string that can be sent to the API
   */
  public static toApiUrlQueryString<Row, State extends DataTablePageState<Row>>(
    state: State,
    configuration: QueryStateParametersConfiguration<State>,
    options: DataTableSynchronizationOptions<State> = {},
  ): string {
    // If the pageIndexStartingValue is set to 1, do not modify the page state;
    // otherwise, set the page state to 0.
    const translated =
      options.pageIndexStartingValue === 1
        ? state
        : { ...state, page: state.page - 1 };

    const apiParams = QueryState.toApiUrlQuery(
      translated,
      configuration,
      options,
    );

    return stringify(apiParams, {
      arrayFormat: 'brackets',
      encodeValuesOnly: true,
      sort: (a, b) => a.localeCompare(b),
    });
  }

  /**
   * Apply configuration to state to generate a URLquery string that can be written to the browser's URL
   */
  public static toUiUrlQueryString<Row, State extends DataTablePageState<Row>>(
    state: State,
    configuration: QueryStateParametersConfiguration<State>,
    options: QueryStateManagementOptions<State> = {},
  ): string {
    const uiParams = QueryState.toUiUrlQuery(state, configuration, options);

    // TODO: https://github.com/ljharb/qs/issues/387 (remove comma hack once library bug is fixed)
    const encoded = Object.entries(uiParams).reduce((acc, [key, value]) => {
      if (Array.isArray(value)) {
        acc[key] = value.map((item) =>
          item && typeof item === 'string' && /,/g.test(item)
            ? item.replace(/,/g, '%2C')
            : item,
        );
      } else {
        acc[key] = value;
      }
      return acc;
    }, {});

    return stringify(encoded, {
      arrayFormat: 'comma',
      encodeValuesOnly: true,
    });
  }

  /**
   * Synchronize (ALL) -- the UI URL, browser storage, vuetify data table state, and API URLs.
   * - Construct refs for tableState (to pass to vuetify's data-table component) and queryState (to hold all state)
   *   - tableState is contained by queryState
   * - Setup watchers to know when tableState / queryState changes
   * - Update the browser URL and browser storage when state changes
   * - Optionally generate an API query string when state changes
   * @returns { tableState: Ref<DataTableConfiguration>; queryState: Ref<State> };
   * @param route the current route from Vue's Router
   * @param router Vue Router
   * @param configuration an object describing properties expected in a URL query and default values for each
   * property in case they are not present in the URL or browser storage
   * @param options
   *   - `synchronizeApiOnly`: bypass all Browser URL and Browser Storage logic
   *   - `storageConfig`: which store to use (likely localStorage or sessionStorage) and key to use
   *   - `mergeStore`: if true: merge new state with existing state in storage.  if false: overwrite
   *   - `callback`: function to execute when changes are detected and after everything is synchronized
   *   - `onBeforeUiUrlGeneration`: function that can change parts of the state object
   *     (without mutating base state) before the UI URL is generated
   *   - `onBeforeApiUrlGeneration`: function that can change parts of the state object
   *     (without mutating base state) before the API URL is generated
   */
  public static synchronize<Row, State extends DataTablePageState<Row>>(
    route: Route,
    router: VueRouter,
    configuration: QueryStateParametersConfiguration<State>,
    options: DataTableSynchronizationOptions<State>,
  ): {
    tableState: Ref<VuetifyDataTableConfiguration>;
    queryState: Ref<State>;
    updateQueryState: (
      slice: Partial<State>,
      updateOptions?: {
        debounceTime?: number;
        bypassUiUrlUpdate?: boolean;
        bypassApiUrlUpdate?: boolean;
      },
    ) => void;
  } {
    const { synchronizeApiOnly } = options;
    const instance = new DataTableState(configuration);

    const queryStateRaw = synchronizeApiOnly
      ? QueryState.fromConfigurationDefaults(configuration)
      : QueryState.fromUrlAndStorage(configuration, route.fullPath, options);
    const tableStateRaw =
      VuetifyDataTableConfiguration.fromQueryState(queryStateRaw);
    instance.queryState = ref(queryStateRaw) as Ref<State>;
    instance.tableState = ref(tableStateRaw);

    instance.onStateChange = instance.generateOnStateChange(
      options,
      router,
      route,
    );
    const updateQueryState = instance.generateUpdateQueryState();

    instance.watchTableState();
    watch(instance.queryState, instance.onStateChange, { immediate: true });

    return {
      tableState: instance.tableState,
      queryState: instance.queryState as Ref<State>,
      updateQueryState,
    };
  }
}
