/* eslint-disable no-restricted-syntax */
/* eslint-disable no-bitwise */
/* eslint-disable no-plusplus */
/**
 * Forked from:
 * https://github.com/Effect-TS/core/blob/master/packages/system/src/Structural/HasHash/index.ts
 *
 * This is used to hash anything.
 *
 * IGNORE THIS FILE
 *
 * The code in here is pretty complex and should generally not be modified. It was forked from `@effect-ts`
 * because we didn't need all of `@effect-ts` and this removes some of the unnecessary hashing ability, for
 * instance the ability to hash functions.
 */

export const HashURI = Symbol.for('@ax/has-hash');

export interface HasHash {
  readonly [HashURI]: number;
}

export function hasHash(u: unknown): u is HasHash {
  return typeof u === 'object' && u !== null && HashURI in u;
}

export function optimize(n: number) {
  return (n & 0xbfffffff) | ((n >>> 1) & 0x40000000);
}

export function hash(arg: unknown): number {
  return optimize(_hash(arg));
}

export function hashArray(arr: readonly unknown[]): number {
  return optimize(_hashArray(arr));
}

export function combineHash(a: number, b: number): number {
  return optimize(_combineHash(a, b));
}

export function hashObject(value: object): number {
  return optimize(_hashObject(value));
}

export function hashMiscRef(o: Object): number {
  return optimize(_hashMiscRef(o));
}

export function hashIterator(it: Iterator<unknown>): number {
  return optimize(_hashIterator(it));
}

export function hashPlainObject(o: object): number {
  return optimize(_hashPlainObject(o));
}

export function hashNumber(n: number): number {
  return optimize(_hashNumber(n));
}

export function hashString(str: string): number {
  return optimize(_hashString(str));
}

function isZero(value: unknown): boolean {
  return value === null || value === undefined || value === false;
}

const CACHE = new WeakMap<Object, number>();

function _hash(arg: unknown): number {
  if (isZero(arg)) {
    return 0;
  }
  switch (typeof arg) {
    case 'number':
      return _hashNumber(arg);
    case 'string':
      return _hashString(arg);
    case 'function':
      return _hashMiscRef(arg);
    case 'object': {
      if (arg === null) {
        return 0;
      }
      return Array.isArray(arg) ? _hashArray(arg) : _hashObject(arg);
    }
    case 'boolean':
      return arg === true ? 1 : 0;
    case 'symbol':
      return _hashString(String(arg));
    case 'bigint':
      return _hashString(arg.toString(10));
    default: {
      return 0;
    }
  }
}

export function _hashArray(arr: readonly unknown[]): number {
  let h = 6151;
  for (const value of arr) {
    h = _combineHash(h, _hash(value));
  }
  return h;
}

export function _combineHash(a: number, b: number): number {
  return (a * 53) ^ b;
}

export function _hashObject(value: object): number {
  if (hasHash(value)) {
    return value[HashURI];
  }
  let h = CACHE.get(value);
  if (h) {
    return h;
  }
  h = hashPlainObject(value);
  CACHE.set(value, h);
  return h;
}

let miscHashId = 0;

export function _hashMiscRef(o: Object): number {
  let h = CACHE.get(o);
  if (h) {
    return h;
  }
  h = miscHashId++;
  CACHE.set(o, h);
  return h;
}

export function _hashIterator(it: Iterator<unknown>): number {
  let h = 6151;
  let current: IteratorResult<unknown> = it.next();
  while (current.done !== true) {
    h = _combineHash(h, hash(current.value));
    current = it.next();
  }
  return h;
}

export function _hashPlainObject(o: object): number {
  const keys = Object.keys(o).sort();
  let h = 12289;
  for (const key of keys) {
    h = _combineHash(h, _hashString(key));
    h = _combineHash(h, hash(o[key]));
  }
  return h;
}

export function _hashNumber(n: number): number {
  // eslint-disable-next-line no-self-compare
  if (n !== n || n === Infinity) {
    return 0;
  }
  let h = n | 0;
  if (h !== n) {
    h ^= n * 0xffffffff;
  }
  while (n > 0xffffffff) {
    // eslint-disable-next-line no-multi-assign, no-param-reassign
    h ^= n /= 0xffffffff;
  }
  return n;
}

export function _hashString(str: string): number {
  let h = 5381;
  let i = str.length;
  while (i) {
    h = (h * 33) ^ str.charCodeAt(--i);
  }
  return h;
}
