import { UnknownRecord } from '@ax/object-utils';

export enum LegacyPlanName {
  basic = 'BASIC', // For self-serve customers, this does not co-relate with the 'patch' subscription
  full = 'FULL', // For self-serve customers, this is used for both 'patch' and 'manage' subscriptions
  tier3 = 'TIER3', // Will only be available to legacy_billing customers to start with
}

export enum LegacyPlanNameLowerCase {
  basic = 'basic',
  full = 'full',
  tier3 = 'tier3',
}

export enum PlanName {
  patch = 'patch', // Displayed to end users as 'Basic' after Tier 3 launches
  manage = 'manage', // Displayed to end users as 'Standard' after Tier 3 launches
  tier3 = 'tier3', // Displayed to end users as 'Complete' after Tier 3 launches
}

export type NormalizedPlanName = `${PlanName}` | `${LegacyPlanNameLowerCase}`;

export const TierRank = {
  [PlanName.patch]: 1,
  [PlanName.manage]: 2,
  [PlanName.tier3]: 3,
};

export enum PlanTerm {
  monthly = 'monthly',
  yearly = 'yearly',
}

export enum RateInterval {
  month = 'month',
  year = 'year',
}
export interface Plan {
  plan?: NormalizedPlanName;
  term?: `${PlanTerm}`;
  title?: string;
  subtitle?: string;
  features?: string[];
  active?: boolean;
  deviceCount?: number;
  cost?: Cents;
  saving?: number;
  nextPaymentDate?: string;
}

export interface SubscriptionPayload {
  coupon?: string | null;
  plan?: NormalizedPlanName;
  quantity: number;
  term?: `${PlanTerm}`;
}

export interface LegacyRates {
  basic_annual_base_rate: string;

  basic_annual_overage_rate?: string;

  basic_monthly_base_rate?: string;

  basic_monthly_overage_rate?: string;

  full_annual_base_rate: string;

  full_annual_overage_rate?: string;

  full_monthly_base_rate?: string;

  full_monthly_overage_rate?: string;

  tier3_annual_base_rate?: string;

  tier3_annual_overage_rate?: string;

  tier3_monthly_base_rate?: string;

  tier3_monthly_overage_rate?: string;

  name?: string;
}

export class Rate {
  active?: boolean;

  amount?: number;

  currency?: string;

  interval?: `${RateInterval}`;

  interval_count?: number;

  constructor(init: Rate) {
    Object.assign(this, init);
  }
}

export class RateGroup {
  monthly?: Rate;

  yearly?: Rate;

  constructor(init: RateGroup) {
    Object.assign(this, init);
  }
}

export interface Rates {
  manage: RateGroup;

  patch: RateGroup;

  tier3?: RateGroup;
}

export function isLegacyRates(
  rates: Rates | LegacyRates | undefined,
): rates is LegacyRates {
  return !!(
    rates && (rates as LegacyRates).full_annual_base_rate !== undefined
  );
}

export function isNonLegacyRates(
  rates: Rates | LegacyRates | undefined,
): rates is Rates {
  return !!(
    rates &&
    (rates as Rates).patch !== undefined &&
    (rates as Rates).manage !== undefined
  );
}

export class Subscription {
  auto_renew?: boolean;

  created_at?: string;

  ends_at?: string;

  id?: number;

  organization_id?: number;

  plan?: NormalizedPlanName;

  primary_subscription_id?: string;

  quantity?: number;

  secondary_subscription_id?: string;

  secondary_subscription_item_id?: string;

  term?: `${PlanTerm}`;

  updated_at?: string;

  coupon?: string | null;

  constructor(init: Subscription) {
    Object.assign(this, init);
  }
}

export class BillingAddress {
  orgId?: number;

  name?: string;

  addr1?: string;

  addr2?: string;

  city?: string;

  state?: string;

  country?: string;

  zipcode?: string;

  constructor(init: BillingAddress) {
    Object.assign(this, init);
  }
}

export interface BillingAddressFieldset {
  companyName: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state?: string;
  country: string;
  zipcode?: string;
}

class OrgParent {
  id?: number;

  name?: string;

  constructor(init: OrgParent) {
    Object.assign(this, init);
  }
}

export class CreditCard {
  brand?: string;

  number?: string;

  constructor(init: CreditCard) {
    Object.assign(this, init);
  }
}

export class Invoice {
  cc_last?: string;

  date?: string;

  id?: string;

  invoice_type?: string;

  organization_id?: number;

  paid?: boolean;

  paid_date?: string;

  pdf?: string;

  status?: string;

  stripe_charge_id?: string;

  total?: number;

  constructor(init: Partial<Invoice>) {
    Object.assign(this, init);
  }
}

export class BillingOrganization {
  access_key = '';

  addr1?: string;

  addr2?: string;

  bill_overages?: boolean;

  billing_email?: string;

  billing_interval?: `${RateInterval}`;

  billing_interval_count?: number;

  billing_name?: string;

  billing_phone?: string;

  device_count_with_children?: number;

  device_count?: number;

  device_limit?: number;

  id?: number;

  is_at_parent_limit?: boolean;

  parent?: OrgParent;

  // TODO: enumify
  cc_brand?: string;

  cc_exp?: string;

  cc_last?: string;

  cc_name?: string;

  city?: string;

  country?: string;

  create_time?: string;

  legacy_billing?: boolean;

  metadata?: UnknownRecord;

  name?: string;

  next_bill_time?: string;

  parent_id?: number;

  rate_id?: number;

  server_limit?: number;

  state?: string;

  stripe_cust?: string;

  sub_create_time?: string;

  sub_end_time?: string;

  sub_plan?: `${LegacyPlanName}`;

  sub_systems?: number;

  trial_end_time?: string | null;

  trial_expired?: boolean;

  zipcode?: string;

  zone_id?: string;

  constructor(init: Partial<BillingOrganization>) {
    Object.assign(this, init);
  }
}

type Cents = number;
