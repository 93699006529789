import './init';
import Vue from 'vue';
import VueNotification from 'vue-notification';
import Vuelidate from 'vuelidate';
import { sync } from 'vuex-router-sync';

import 'what-input';

import { consoleEnv } from '@ax/console-env';
import { env } from '@ax/env';
import { initErrorReporter } from '@ax/error-reporter';
import { SegmentAnalytics } from '@ax/data-services-tracking/analytics';
import { BespokeHTTPClient } from '@ax/data-services-common/client';
import { getConfig } from '@ax/data-services-config';
import BaselineLayout from '@/layouts/BaselineLayout.vue';
import AppLayout from '@/layouts/AppLayout.vue';
import { AppStore } from '@/store';
import App from './App.vue';

import { router } from './router';

import './plugins/feature-flag';
import vuetify from './plugins/vuetify';
import httpClient from './services/http-client';

import {
  setupInterceptors,
  setInterceptorRouter,
} from './services/http-interceptors';
import i18n from './i18n';

getConfig()
  .then((response) => {
    if (env.NODE_ENV === 'development' && consoleEnv.SEGMENT_DEV_TOKEN) {
      // https://console.dev.automox-dev.com/ and localhost aren’t given the segment key
      // so we provide it via environment var for lower environments
      SegmentAnalytics.load(consoleEnv.SEGMENT_DEV_TOKEN);
    } else if (response.segment.key) {
      SegmentAnalytics.load(response.segment.key);
    }

    const datadogConfig = response.datadog?.['console-rum-application'];

    if (
      (datadogConfig?.client_token && datadogConfig?.application_id) ||
      (env.NODE_ENV === 'development' &&
        consoleEnv.DATADOG_ENABLED &&
        consoleEnv.DATADOG_CONSOLE_CLIENT_TOKEN &&
        consoleEnv.DATADOG_CONSOLE_APP_ID)
    ) {
      initErrorReporter({
        applicationId: `${
          consoleEnv.DATADOG_CONSOLE_APP_ID || datadogConfig?.application_id
        }`,
        clientToken: `${
          consoleEnv.DATADOG_CONSOLE_CLIENT_TOKEN || datadogConfig?.client_token
        }`,
        service: 'console',
        version: consoleEnv.RELEASE_VERSION,
      });
    }
  })
  .catch(() => false);

Vue.component('app-layout', AppLayout);
Vue.component('baseline-layout', BaselineLayout);

Vue.use(VueNotification);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
Vue.use(Vuelidate as any);

Vue.config.productionTip = false;

sync(AppStore, router, { moduleName: 'route' });

setInterceptorRouter(router);
setupInterceptors(AppStore.dispatch, [httpClient, BespokeHTTPClient]);

const app = new Vue({
  router,
  store: AppStore,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

declare global {
  interface Window {
    app?: Vue;
    Cypress?: object;
    releaseVersion: string;
  }
}

window.releaseVersion = consoleEnv.RELEASE_VERSION;

if (window.Cypress) {
  window.app = app;
}
