/**
 * An error representing the failure to load the config.
 */
export class ConfigLoadingError {
  readonly type = 'ConfigLoadingError';

  readonly message = 'An error occurred while attempting to load the config.';

  constructor(readonly error: unknown) {}
}
