import { AxiosResponse } from 'axios';
import { stringifyUrlQuery } from '@/utils/util';

import { DeviceGroup } from '@/models/device-group';
import { DeviceGroupPayload } from '@/types/device-group';
import httpClient from './http-client';

const DEVICE_GROUPS_ENDPOINT = '/servergroups';

export function getDeviceGroups(
  orgId: string | number,
): Promise<DeviceGroup[]> {
  return httpClient
    .get(`${DEVICE_GROUPS_ENDPOINT}?o=${orgId}`)
    .then((response) => response.data);
}

export function postAssignDeviceGroup(
  orgId: string | number,
  deviceGroupId: number,
  deviceIds: string[] | number[],
) {
  return httpClient
    .post(`${DEVICE_GROUPS_ENDPOINT}/${deviceGroupId}/servers?o=${orgId}`, {
      batch: deviceIds,
    })
    .then((response) => response.data);
}

export function getDeviceGroup(
  orgId: string | number,
  groupId: string | number,
): Promise<AxiosResponse> {
  return httpClient.get(
    `${DEVICE_GROUPS_ENDPOINT}/${groupId}?${stringifyUrlQuery({
      o: orgId,
      internal: 1,
    })}`,
  );
}

export function createDeviceGroup(
  orgId: string | number,
  payload: DeviceGroupPayload,
): Promise<DeviceGroup> {
  return httpClient
    .post(
      `${DEVICE_GROUPS_ENDPOINT}?${stringifyUrlQuery({
        o: orgId,
        internal: 1,
      })}`,
      payload,
    )
    .then(({ data }) => data);
}

export function updateDeviceGroup(group: DeviceGroup): Promise<AxiosResponse> {
  const { id, organization_id } = group;

  return httpClient.put(
    `${DEVICE_GROUPS_ENDPOINT}/${id}?${stringifyUrlQuery({
      o: organization_id,
      internal: 1,
    })}`,
    group,
  );
}

export function deleteDeviceGroup(group: DeviceGroup): Promise<AxiosResponse> {
  const { id, organization_id } = group;

  return httpClient.delete(
    `${DEVICE_GROUPS_ENDPOINT}/${id}?${stringifyUrlQuery({
      o: organization_id,
      internal: 1,
    })}`,
  );
}
