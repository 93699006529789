import { constant } from '@ax/function-utils';
import { AnyQuery } from '../query/definition';
import { fold, QueryStatusFromQuery } from '../query-status';

/**
 * We are using classes to signal `Send` and `Skip` decisions instead of strings
 * below because we might add additional decision types in the future
 * which may require additional information.
 */

/**
 * Used to signal that we want to send
 * a request over the network
 */
class Send {
  readonly type = 'Send';
}

/**
 * Static `send`
 */
export const send = new Send();

/**
 * Used to signal that we want to skip
 * sending a request over the newoek
 */
class Skip {
  readonly type = 'Skip';
}

/**
 * Static `skip`.
 */
export const skip = new Skip();

export type NetworkDecision = Skip | Send;

/**
 * Check that a `NetworkDecision` is a `send`
 */
export function isSend(decision: NetworkDecision): decision is Send {
  return decision.type === 'Send';
}

/**
 * Check that a `NetworkDecision` is a skip
 */
export function isSkip(decision: NetworkDecision): decision is Skip {
  return decision.type === 'Skip';
}

/**
 * A `NetworkPolicy<A>` makes a determination as to whether a query of type `A`
 * should be sent over the network or skipped. This decision is made based on
 * the current status of the query and the query itself.
 */
export interface NetworkPolicy<A extends AnyQuery = AnyQuery> {
  (status: QueryStatusFromQuery<A>, query: A): NetworkDecision;
}

/**
 * Always skip the network request.
 */
export const AlwaysSkip = constant(skip);

/**
 * Always send the network request.
 */
export const AlwaysSend = constant(send);

/**
 * A `NetworkPolicy` that will only send network requests if a successful
 * result has not been received
 */
export const CacheFirst: NetworkPolicy = fold({
  onEmpty: AlwaysSend,
  onFailure: AlwaysSend,
  onRefreshing: AlwaysSkip,
  onLoading: AlwaysSkip,
  onSuccess: AlwaysSkip,
});

/**
 * A `NetworkPolicy` that will stop any new requests if ones are already
 * in flight but will still send a request to refresh a `success` state.
 */
export const CacheAndNetwork: NetworkPolicy = fold({
  onEmpty: AlwaysSend,
  onFailure: AlwaysSend,
  onRefreshing: AlwaysSkip,
  onLoading: AlwaysSkip,
  onSuccess: AlwaysSend,
});

/**
 * Creates a time to live `NetworkPolicy`. If a `QueryStatus` is in a success state,
 * and that state was created at less than `time` ago, we can skip the query.
 * @param time
 * @param currentTime
 * @returns a `NetworkPolicy` respecting the ttl constraint
 */
export function ttl(
  time: number,
  currentTime = () => Date.now(),
): NetworkPolicy {
  return fold({
    onEmpty: AlwaysSend,
    onFailure: AlwaysSend,
    onRefreshing: AlwaysSkip,
    onLoading: AlwaysSkip,
    onSuccess: (status) =>
      currentTime() - time <= status.createdAt ? skip : send,
  });
}

/**
 * Combines two `NetworkPolicy<A>`s into a single `NetworkPolicy<A>`. If either
 * policy results in a `send`, the policy will also result in a send
 * @param policyA
 * @param policyB
 * @returns `NetworkPolicy<A>`
 */
export function concat_<A extends AnyQuery>(
  policyA: NetworkPolicy<A>,
  policyB: NetworkPolicy<A>,
): NetworkPolicy<A> {
  return (status, query) =>
    policyA(status, query).type === 'Send' ||
    policyB(status, query).type === 'Send'
      ? send
      : skip;
}

/**
 * curried version of `concat_`
 */
export function concat<A extends AnyQuery>(policyB: NetworkPolicy<A>) {
  return (policyA: NetworkPolicy<A>) => concat_(policyA, policyB);
}

/**
 * Merges many policies for the same query into one `NetworkPolicy<A>`
 * @param policies a non-empty array of `NetworkPolicy<A>`
 * @returns
 */
export function merge<
  A extends AnyQuery,
  Policies extends readonly [
    NetworkPolicy<A>,
    NetworkPolicy<A>,
    ...NetworkPolicy<A>[],
  ],
>(...policies: Policies): NetworkPolicy<A> {
  return (status, query) =>
    policies.some((policy) => isSend(policy(status, query))) ? send : skip;
}
