/**
 * tap peeks into the result of a function with `tapWith` and
 * discards the result. This is useful for applying side effects
 * to the result of a function but then still returning the same
 * value.
 * @param tapWith `(arg: A) => B`
 * @returns `A` the argument initially supplied
 */
export function tap<A, B>(tapWith: (arg: A) => B): (arg: A) => A {
  return (arg: A) => {
    tapWith(arg);
    return arg;
  };
}

/**
 * tap peeks into the result of a function with `tapWith`
 * discards the result after it is awaited. This is useful for applying side effects
 * to the result of a function but then still returning the same
 * value.
 * @param tapWith `(arg: A) => Promise<B>`
 * @returns `A` the argument initially supplied
 */
export function tapAsync<A, B>(
  tapWith: (arg: A) => Promise<B>,
): (arg: A) => Promise<A> {
  return async (arg: A) => {
    await tapWith(arg);
    return arg;
  };
}
