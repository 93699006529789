import { AxiosInstance, AxiosResponse } from 'axios';
import { consoleEnv } from '@ax/console-env';
import { createClient } from '@ax/api-clients-common';

export const AUTH_ENDPOINT = '/auth';

let bespokeClient;

export const createAuthHttpClients = (): [AxiosInstance] => {
  if (!bespokeClient) {
    bespokeClient = createClient({
      baseURL: `${consoleEnv.BESPOKE_API_BASE_URL}${AUTH_ENDPOINT}`,
      loaderEnabled: true,
      notificationsEnabled: true,
      // Support cross-domain proxy requests during development.
      // This has no effect on the same-origin requests that we use in production.
      withCredentials: consoleEnv.API_IS_PROXY,
    });
  }
  return [bespokeClient];
};

export const getBespokeAuthHttpClient = (): AxiosInstance => {
  if (bespokeClient) {
    return bespokeClient;
  }
  throw new Error(
    'HTTP Client for Bespoke Auth endpoints has not been initialized.',
  );
};

// TODO stoplight URL
export function requestPasswordReset(): Promise<AxiosResponse> {
  return getBespokeAuthHttpClient().post('password-reset');
}
