import { Response, failure, AsyncFunctionN, FunctionN } from '@ax/type-utils';
import { Lazy } from './lazy';
import { sleepWith } from './sleep';

const TimeoutErrorBrand = 'TimeoutError';

type ToMessage<Args extends ReadonlyArray<unknown>> = FunctionN<Args, string>;

/**
 * A `TimeoutError` represents the result of a computation that
 * failed to complete in the allowed timeframe.
 */
export class TimeoutError {
  readonly type = TimeoutErrorBrand;

  readonly createdAt = Date.now();

  constructor(readonly message: string, readonly timeout: number) {}
}

export function isTimeoutError(e: unknown): e is TimeoutError {
  return (
    typeof e === 'object' &&
    e != null &&
    (e as { readonly type?: string }).type === TimeoutErrorBrand
  );
}

/**
 * `timeoutUnsafe` applies a timeout to a given async function. It is unsafe in that
 * it can reject the promise with a `TimeoutError`
 * @param fn a function that accepts args of type `Args` and returns a `Promise<A>`
 * @param time the timeout time for the function
 * @param message the message to supply to the Error
 * @returns the same function as inputted but wrapped with a timeout
 */
export function timeoutUnsafe_<Args extends ReadonlyArray<unknown>, A>(
  fn: AsyncFunctionN<Args, A>,
  time: number,
  message?: ToMessage<Args>,
): AsyncFunctionN<Args, A> {
  return (...args) => {
    return Promise.race([
      fn(...args),
      sleepWith(time, () => {
        throw new TimeoutError(
          message ? message(...args) : `The request timed out at: ${time}`,
          time,
        );
      }),
    ]);
  };
}

/**
 * curried version of `timeoutUnsafe_`
 */
export function timeoutUnsafe(time: number, message?: Lazy<string>) {
  return <Args extends ReadonlyArray<unknown>, A>(
    fn: AsyncFunctionN<Args, A>,
  ) => timeoutUnsafe_(fn, time, message);
}

/**
 * A safe version of `timeoutUnsafe`
 * @param fn a function that accepts args of type `Args` and returns a `Promise<Response<A, E>>`
 * @param time the timeout for the promise
 * @param message the message for the timeout error
 * @returns a function that accepts args of type `Args` and returns a `Promise<Response<A, E | TimeoutError>>`
 */
export function timeout_<Args extends ReadonlyArray<unknown>, A, E = never>(
  fn: AsyncFunctionN<Args, Response<A, E>>,
  time: number,
  message?: ToMessage<Args>,
): AsyncFunctionN<Args, Response<A, E | TimeoutError>> {
  return (...args) => {
    return Promise.race([
      fn(...args),
      sleepWith(time, () =>
        failure(
          new TimeoutError(
            message ? message(...args) : `The request timed out at: ${time}`,
            time,
          ),
        ),
      ),
    ]);
  };
}

/**
 * A curried version of `timeout_`
 */
export function timeout(time: number, message?: Lazy<string>) {
  return <Args extends ReadonlyArray<unknown>, A, E = never>(
    fn: AsyncFunctionN<Args, Response<A, E>>,
  ) => timeout_(fn, time, message);
}
