import { SegmentAnalytics } from '@ax/data-services-tracking/analytics';
import { errorReporter, redactUrl } from '@ax/error-reporter';
import {
  getItemInWebStorage,
  removeItemInWebStorage,
} from '@ax/web-storage-wrapper';
import { evaluateFlagsOnPageNavigation } from '@ax/feature-flag';
import { NavigationGuardNext, Route } from 'vue-router';
import { consoleI18n } from '@ax/console-i18n';
import { AppStore } from '@/store';
import { AUTH_LOGOUT } from '@/store/actions/auth';
import { UNSAVED_CHANGES, UNSAVED_CHANGES_GET } from '@/store/actions/app';
import { CONFIRM_UNSAVED_CHANGES } from '@/utils/constants';
import { isRelativeUrl, isTruthyLike } from '@/utils/validations';
import { setItemInStorage } from '@/utils/storage';
import {
  USER_GET_PROFILE_ID,
  USER_REQUEST,
  USER_REQUEST_IF_STALE,
} from '@/store/actions/user';
import { getIsAuthenticated } from '@/services/user.service';

export const LS_LAST_ROUTE = 'ax-last-route';

export function globalBeforeEach(
  to: Route,
  from: Route,
  next: NavigationGuardNext<Vue>,
) {
  if (to.query.trackSource) {
    SegmentAnalytics.track(to.query.trackSource as string, {
      fromUrl: from.fullPath,
      toUrl: to.fullPath,
    });
    delete to.query.trackSource;
  }

  if (to.name === '404') {
    /**
     * External URL Redirect
     *
     * Redirects all external url's to the 404 page to fix a vulnerability
     * where instances of the `frompage` url parameter could be used to
     * redirect a user to an external url.
     */
    if (!isRelativeUrl(to.path)) {
      return next({ path: '/404' });
    }

    errorReporter.addError(new Error('404: User landed on Vue 404 route'), {
      fullPath: redactUrl(to.fullPath),
    });
  }

  /**
   * UNSAVED CHANGES GUARD
   * This reads and sets app-level state regarding whether or not the user has unsaved changes
   * on some form.  It is used to block navigation to other routes in this Vue app until the user
   * confirms they intend to discard their changes via a browser popup.  Navigation will fail if
   * the user presses cancel on the popup.
   *
   * Security is an exception.  When the user intends to navigate to a security-sensitive route,
   * the UI will not attempt to confirm if they want to save changes.  Their changes will be lost
   * and they will be immediately logged out.
   *
   * See src/components/Forms/ConfirmCancel.vue for more on how the state is set.
   */

  if (
    /login/.test(to.path) ||
    /forgot-password/.test(to.path) ||
    /password-reset/.test(to.path)
  ) {
    AppStore.dispatch(AUTH_LOGOUT);
    AppStore.dispatch(UNSAVED_CHANGES, false);
    return next();
  }

  if (/accept-invite/.test(to.path)) {
    AppStore.dispatch(UNSAVED_CHANGES, false);
    return next();
  }

  if (from.path !== to.path && AppStore.getters[UNSAVED_CHANGES_GET] === true) {
    // eslint-disable-next-line no-alert
    const confirmation = window.confirm(CONFIRM_UNSAVED_CHANGES);
    if (!confirmation) {
      return next(false);
    }
    AppStore.dispatch(UNSAVED_CHANGES, false);
  }

  const orgId = to.query.org || to.query.o || '';

  const isPrivateRoute = to.matched.some((record) => !record.meta.public);
  if (isPrivateRoute && to.path !== from.path) {
    const handleRedirect = () => {
      const redirectStorageConfig = {
        key: 'redirect',
        store: localStorage,
      };
      const redirect = getItemInWebStorage(redirectStorageConfig);
      if (redirect) {
        removeItemInWebStorage(redirectStorageConfig);
        if (isRelativeUrl(redirect)) {
          next(redirect);
          return true;
        }
        return false;
      }
      return false;
    };

    const logoutAndReload = (sessionExpired = false) => {
      // Store requested route so AUTH_LOGOUT will redirect to it
      setItemInStorage(to.fullPath, {
        key: LS_LAST_ROUTE,
        store: localStorage,
      });
      // Trigger logout and hard reload
      AppStore.dispatch(AUTH_LOGOUT, { shouldRedirect: true, sessionExpired });

      // Terminate current navigation since AUTH_LOGOUT action will redirect asynchronously
      return next(false);
    };

    const requestUserThenNext = (forceFresh = false) => {
      return AppStore.dispatch(
        forceFresh ? USER_REQUEST : USER_REQUEST_IF_STALE,
        orgId,
      ).then(() => {
        // Evaluate feature flags before any route's beforeEnter is called
        evaluateFlagsOnPageNavigation(to, from);
        if (!handleRedirect()) {
          next();
        }
      });
    };

    const userDataNotCached = !AppStore.getters[USER_GET_PROFILE_ID];
    if (userDataNotCached) {
      // We haven't cached any user data yet
      // Skip extra isAuthenticated round-trip, since request to /api/users/self will happen anyway
      return requestUserThenNext(true).catch((error) => {
        // 4XX: User session is expired or non-existent
        // 5XX: Error calling backend, network or other issue
        return logoutAndReload(error?.status === 401);
      });
    }

    return getIsAuthenticated()
      .then((isAuthenticated) => {
        if (isAuthenticated) {
          // 204: User has valid session
          // Request only if stale, do not force fresh
          // Resolves immediately if data is cached
          return requestUserThenNext();
        }

        // 401: User session is expired or non-existent
        return logoutAndReload(true);
      })
      .catch(() => {
        // 4XX or 5XX: Error calling backend, network or other issue
        return logoutAndReload();
      });
  }

  if (/signup/.test(to.path)) {
    return getIsAuthenticated()
      .then((isAuthenticated) => {
        if (isAuthenticated) {
          // 204: User has valid session, doesn't need to signup
          return AppStore.dispatch(USER_REQUEST_IF_STALE, orgId).then(() => {
            // Evaluate feature flags before any route's beforeEnter is called
            evaluateFlagsOnPageNavigation(to, from);
            next({ path: '/dashboard' });
          });
        }
        return next(); // unauthenticated users can access signup page
      })
      .catch(() => next());
  }

  // Evaluate feature flags before any route's beforeEnter is called
  evaluateFlagsOnPageNavigation(to, from);
  return next();
}

/**
 * Updates the document's page title to the title from the route's metadata.
 * If the route does not have the title attribute specified, the parent
 * routes (if any) will be traversed until we find a title. If no title
 * is found anywhere in the chain we leave it as-is.
 *
 * @param to The route we navigated to.
 */
function updatePageTitle(to: Route) {
  // 'matched' will contain routes starting with the parent route and ending in
  // the route we're at. Slice so we don't modify the original, reverse it so that
  // we start at the deepest route, then try and find a title.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  if (nearestWithTitle) {
    document.title = consoleI18n.t('routeTitles.titleFormat', [
      nearestWithTitle.meta.title,
    ]);
  }
}

/**
 * Hook that is called after every successful navigation. Not called for
 * cancelled navigations in vue-router 3.x.
 *
 * @param to The route we went to.
 * @return Promise ONLY FOR TESTING or undefined. In reality this function is
 * called synchronously and any async operations are ignored, but to make
 * testing possible async operations should return a promise.
 */
export function globalAfterEach(to: Route): Promise<unknown> | undefined {
  const isPrivateRoute = to.matched.some((record) => !record.meta.public);

  if (isPrivateRoute && !to.meta?.discardAsLastRoute) {
    setItemInStorage(to.fullPath, { key: LS_LAST_ROUTE, store: localStorage });
  }

  SegmentAnalytics.page();

  updatePageTitle(to);

  if (isTruthyLike(to.query?.first_login, 'partial')) {
    return getIsAuthenticated()
      .then((isAuthenticated) => {
        if (isAuthenticated) {
          SegmentAnalytics.track('New Signup');
        }
      })
      .catch(() => {
        // Do nothing
      });
  }
}
