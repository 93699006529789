import { Nullable } from '@ax/type-utils';
import { PaginationMetadata } from '@/models/pagination';

export interface TaskPackageVersion {
  display_name: string;
  name: string;
  id: string;
  version: string;
  requires_reboot: boolean;
}

export interface TaskUserInfo {
  id: Nullable<number>;
  email: Nullable<string>;
  firstname: Nullable<string>;
  lastname: Nullable<string>;
}

export interface Task {
  id: number;
  organization_id: number;
  task_type: string;
  payload: {
    patch_id: string;
    severity: string;
    package_versions: TaskPackageVersion[];
  };
  source: string;
  notes: string;
  status: TaskStatuses;
  created_at: string;
  updated_at: string;
  created_by_user?: TaskUserInfo;
  last_updated_by_user?: TaskUserInfo;
  partner_user_id: Nullable<string>;
  completed_at?: Nullable<string>;
  jobs_total?: number;
  jobs_pending_count?: number;
  jobs_succeeded_count?: number;
  jobs_failed_count?: number;
  jobs_inprogress_count?: number;
  devices_unknown_count?: number;
}

export interface Job {
  id: number;
  hostname: string;
  custom_name: string;
  device_id: number;
  device_status: string;
  error: string | null;
  private_ips: string[];
  public_ips: string[];
  initiated_at: string | null;
  completed_at: string | null;
  patch_id: string;
  package_version_id: number;
  software: string;
  version: string;
}

export type TaskBatchStatus =
  | 'awaiting_approval'
  | 'rejected'
  | 'approved'
  | 'building'
  | 'error'
  | 'ready';

export type TaskBatchType = 'patch';

// TODO: It's gross that the single batch get differs so much from the list GET, fix in backend?
export interface TaskBatchSingle {
  id: number;
  organization_id: number;
  configuration_id: Nullable<string>;
  status: TaskBatchStatus;
  source: string;
  report_source?: string;
  created_by: TaskUserInfo;
  updated_by: TaskUserInfo;
  created_at: string;
  updated_at: string;
  task_count: number;
  unknown_host_count: number;
  impacted_device_count: number;
  issue_count: number;
  cve_count: number;
  unsupported_host_count: number;
  unknown_cve_count: number;
  patch_now_task_count: number;
  patch_with_worklet_task_count: number;
}

export interface TaskBatch {
  id: number;
  organization_id: number;
  configuration_id: Nullable<string>;
  status: TaskBatchStatus;
  source: string;
  report_source: string;
  created_by_user: TaskUserInfo;
  updated_by_user: TaskUserInfo;
  created_at: string;
  updated_at: string;
  total_tasks: number;
  unknown_host_count: number;
  impacted_device_count: number;
}

export interface TaskBatchIssue {
  id: number | null;
  source: string;
  ip_addrs_private: string[];
  device: string;
  hostname: string;
  issue: string;
  patch_id: string;
}

export enum TaskStatuses {
  Pending = 'pending',
  In_Progress = 'in_progress',
  Executed = 'executed',
  Canceled = 'canceled',
  Building = 'building',
  Rejected = 'rejected',
}

export enum TaskDeviceStatuses {
  Success = 'success',
  In_Progress = 'in_progress',
  Failed = 'error',
  Pending = 'pending',
  Timed_Out = 'timed_out',
  Canceled = 'canceled',
}

export enum TaskIssues {
  DuplicateHost = 'duplicate_host',
  UnknownHost = 'unknown_host',
  UnknownCVE = 'unknown_cve',
  CVENotApplicable = 'cve_not_applicable',
  UnsupportedHost = 'unsupported_host',
}

export interface VulnSyncFormatHeader {
  header: string;
  required: boolean;
  example: string;
}

export interface VulnSyncFormat {
  format: string;
  name: string;
  headers: VulnSyncFormatHeader[];
}

export type VulnSyncFormatsResponse = VulnSyncFormat[];

export interface TaskDetailsPackage {
  id: number;
  display_name: string;
  requires_reboot: boolean;
}

export interface TaskDetailsDevice {
  id: number;
  status: TaskDeviceStatuses;
  name: string;
  custom_name: string;
  ip_addrs_private: string[];
}

export interface TaskDetails {
  id: number;
  cve: string;
  type: string;
  summary: string;
  severity: string;
  requires_reboot: boolean;
  status: string;
  packages: TaskDetailsPackage[];
  devices: TaskDetailsDevice[];
}

export interface TaskDetailsResponse {
  metadata: PaginationMetadata;
  data: {
    id: number;
    organization_id: number;
    configuration_id: Nullable<string>;
    tasks: TaskDetails[];
  };
}

export enum TaskDetailsType {
  PatchNow = 'patch-now',
  PatchWithWorklet = 'patch-with-worklet',
}

export enum TaskAction {
  Cancel = 'cancel',
  Execute = 'execute',
}
