import { render, staticRenderFns } from "./DateField.vue?vue&type=template&id=17586fd2&scoped=true&"
import script from "./DateField.vue?vue&type=script&lang=ts&"
export * from "./DateField.vue?vue&type=script&lang=ts&"
import style0 from "./DateField.vue?vue&type=style&index=0&id=17586fd2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17586fd2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VDatePicker,VMenu})
