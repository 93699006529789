import { RouteConfig } from 'vue-router';

export const groupsRoutes: RouteConfig[] = [
  {
    path: '/create-group',
    redirect: '/manage/groups/create-group',
  },
  {
    path: '/manage/groups/create-group',
    name: 'createGroup',
    component: () => import('@/views/System/Group/GroupCreate.vue'),
    meta: {
      layout: 'app',
      title: 'Create Group',
      topLevelParent: 'Manage',
    },
  },
  {
    path: '/group-editor',
    redirect: (to) => ({
      path: '/manage/groups/:groupId',
      query: { ...to.query, gid: undefined },
      params: { groupId: to.query.gid.toString() },
    }),
  },
  {
    path: '/manage/groups/group-editor',
    redirect: (to) => ({
      path: '/manage/groups/:groupId',
      query: { ...to.query, gid: undefined },
      params: { groupId: to.query.gid.toString() },
    }),
  },
  {
    path: '/manage/groups/:groupId',
    name: 'groupEditor',
    component: () => import('@/views/System/Group/GroupEditor.vue'),
    meta: {
      layout: 'app',
      title: 'Group Editor',
      topLevelParent: 'Manage',
    },
    // TODO: remove this once manage is migrated from backbone to vue
    beforeEnter: (to, from, next) => {
      if (to.params.groupId) {
        next();
      } else {
        next({ name: 'createGroup', query: to.query });
      }
    },
  },
];
