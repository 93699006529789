/* eslint-disable max-len */
import { isValid } from 'date-fns';

export const DATE_INPUT_VALIDATOR = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;

export function parseNewlineSeparatedValues(
  text: string | null | undefined,
): string[] {
  if (!text) {
    return [];
  }
  return text
    .split('\n')
    .map((x) => x.trim())
    .filter((x) => x.length > 0);
}

export function findAdditionalValues<T>(
  choices: T[] = [],
  currentValues: T[],
  additionalValueCandidates: string[],
  itemObjectTextProp = 'text',
  itemObjectValueProp = 'value',
): T[] {
  const additionalValues: T[] = [];
  // choices might be objects
  if (
    choices.length &&
    Object.prototype.hasOwnProperty.call(choices[0], itemObjectTextProp) &&
    Object.prototype.hasOwnProperty.call(choices[0], itemObjectValueProp)
  ) {
    additionalValueCandidates.forEach((candidate) => {
      const matchingItem = choices.find(
        (item) => item[itemObjectTextProp] === candidate,
      );
      // If candidate is a valid choice and isn't already selected
      if (
        matchingItem &&
        !currentValues.find(
          (currVal) => currVal[itemObjectTextProp] === candidate,
        )
      ) {
        additionalValues.push(matchingItem);
      }
    });
  } else {
    additionalValueCandidates.forEach((candidate) => {
      /* eslint-disable prettier-vue/prettier */

      // If candidate isn't already selected
      if (!((currentValues as unknown) as string[]).includes(candidate)) {
        ((additionalValues as unknown) as string[]).push(candidate);
      }

      /* eslint-enable prettier-vue/prettier */
    });
  }

  return additionalValues;
}

export function isValidDateString(timestamp: string): boolean {
  return DATE_INPUT_VALIDATOR.test(timestamp) && isValid(new Date(timestamp));
}

/**
 * onElement
 *
 * Function that adds (and removes before the component is unmounted) an event listener on an HTML element, includes
 * the option to throttle the callback function
 *
 * @param onMounted The onMounted lifecycle event from vue
 * @param onBeforeUnmount The onBeforeUnmount lifecycle event from vue
 * @param el The HTML element to listen for events
 * @param eventType This is the type of event on the window (e.g. 'resize', 'scroll', 'click', etc)
 * @param callbackFunc A callback function to be executed when the event occurs
 * @param throttle Whether to throttle the function call, will delay function calls on continuous events (like scroll)
 * @param delay Time in milliseconds to wait between function calls while the event is occurring, default = 100
 * @param runLastEvent Whether to run the event one last time if the event stops during the delay phase.
 */
export function onElement(
  onMounted: Function,
  onBeforeUnmount: Function,
  el: EventTarget,
  eventType: string,
  callbackFunc: EventListener,
  throttle = false,
  delay = 100,
  runLastEvent = true,
) {
  let waiting = false;
  let finalCall: number;

  const delayedFunc = (event) => {
    if (waiting) {
      return;
    }

    waiting = true;
    clearTimeout(finalCall);
    callbackFunc(event);

    setTimeout(() => {
      waiting = false;
    }, delay);

    // final call of the function so we always run one last time
    if (runLastEvent) {
      finalCall = setTimeout(() => {
        callbackFunc(event);
      }, delay + 50);
    }
  };

  onMounted(() => {
    el.addEventListener(eventType, throttle ? delayedFunc : callbackFunc);
  });

  onBeforeUnmount(() => {
    el.removeEventListener(eventType, throttle ? delayedFunc : callbackFunc);
  });
}

/**
 * Function that returns all the focusable children of an element
 *
 * @param element The element to search within for focusable children
 * @returns {Array} An array of focusable elements
 */
export function getFocusableChildren(
  element: HTMLElement,
): NodeListOf<Element> {
  return element.querySelectorAll(
    'button:not([disabled]), [href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"]):not([disabled]), details:not([disabled]), summary:not(:disabled)',
  );
}

/**
 * Removes duplicate objects from an array based on a single key
 *
 * @param arr The original array containing duplicate objects
 * @param key The key to use to determine duplicates
 * @returns The original array with duplicates removed
 */
export function removeDuplicateArrayObjects<T>(arr: T[], key: string): T[] {
  return arr.filter((v, i, a) => a.findIndex((v2) => v2[key] === v[key]) === i);
}
