/* Dispatch Actions */
export const USER_REQUEST_IF_STALE = 'user/request_if_stale';
export const USER_REQUEST = 'user/request';
export const USER_RECEIVE = 'user/receive';
export const USER_UPDATE = 'user/update';
export const USER_REQUEST_PREFS = 'user/request_prefs';
export const USER_UPDATE_PREFS = 'user/update_prefs';
export const USER_REQUEST_ALL = 'user/request_all';
export const USER_UPDATE_OTHER = 'user/update_other';
export const USER_UNLOCK_OTHER = 'user/unlock_other';
export const USER_REMOVE_OTHER = 'user/remove_other';
export const USER_UPDATE_ORG = 'user/update_org';

/* Getters */
export const USER_IS_LOADING = 'user/isLoading';
export const USER_GET_PROFILE = 'user/getProfile';
export const USER_GET_PROFILE_ID = 'user/getProfileId';
export const USER_ACCOUNT_ID = 'user/accountId';
export const USER_GET_PREFERENCES = 'user/getPreferences';
export const USER_IS_LOADING_PREFS = 'user/isLoadingPrefs';
export const USER_GET_ALL = 'user/getUsers';
