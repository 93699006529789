import { HasOrgId, HasRbacRoles, User } from '../../models/user';
import { Role, Permission, RbacRole } from '../../models/rbac';
import { MappedPermission } from './permission-map';

function getPermissionId(
  permission: MappedPermission,
  permissions: readonly Permission[],
): number | undefined {
  return permissions.find(
    ({ name, type }) =>
      name === permission.name && type.name === permission.type,
  )?.id;
}

function getRoleIdInCurrentOrg(
  orgId: number,
  roles: readonly RbacRole[] = [],
): number | undefined {
  return roles.find((roleInOrg) => roleInOrg.organization_id === orgId)?.id;
}

export function canAccess(
  orgId: number,
  user: HasRbacRoles,
  permission: MappedPermission,
  permissions: readonly Permission[],
  roles: readonly Role[],
): boolean {
  const permissionId = getPermissionId(permission, permissions);
  const roleIdInCurrentOrg = getRoleIdInCurrentOrg(orgId, user.rbac_roles);
  if (roleIdInCurrentOrg === undefined || permissionId === undefined) {
    return false;
  }
  const role = roles.find(({ id }) => roleIdInCurrentOrg === id);
  return !!role?.permissions?.includes(permissionId);
}

export function zonesWithPermission<Org extends HasOrgId>(
  user: User<Org>,
  permission: MappedPermission,
  permissions: readonly Permission[],
  roles: readonly Role[],
): readonly Org[] {
  // Starting with a given permission, find the permission ID
  const permissionId = getPermissionId(permission, permissions);

  if (permissionId === undefined) {
    return [];
  }

  // Find all roles that have that permission ID
  const roleIds = roles
    .filter((role) => role.permissions?.includes(permissionId))
    .map((role) => role.id);

  // Find all zones where user has one of those roles
  if (user.rbac_roles) {
    const orgIds = user.rbac_roles
      .filter((role) => roleIds.includes(role.id))
      .map((roleWithOrgId) => roleWithOrgId.organization_id);

    return user.orgs.filter((org) => orgIds.includes(org.id));
  }
  return [];
}

export function withPermissions<T extends HasOrgId>(
  orgId: number,
  user: User<T>,
  allPermissions: readonly Permission[],
  allRoles: readonly Role[],
) {
  return {
    zonesWithPermission(permission: MappedPermission) {
      return zonesWithPermission(user, permission, allPermissions, allRoles);
    },
    canAccess(permission: MappedPermission) {
      return canAccess(orgId, user, permission, allPermissions, allRoles);
    },
  };
}
