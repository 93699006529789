import { Route, RouteConfig } from 'vue-router';
import { trackDeprecatedRoute } from '../router-utilities';
import { PolicyRouteNames } from './PolicyRouteNames';

export const policiesRoutes: RouteConfig[] = [
  {
    path: 'policies',
    name: PolicyRouteNames.PoliciesHome,
    component: () => import('@/views/System/PoliciesTab.vue'),
    meta: {
      layout: 'app',
      title: 'Policies',
    },
  },
  {
    path: 'policies/create',
    name: PolicyRouteNames.NewPolicyTypeSelect,
    component: () => import('@/views/System/Policy/PolicyTypeSelection.vue'),
    meta: {
      layout: 'app',
      title: 'Select Policy Type',
      topLevelParent: 'Manage',
    },
  },
  {
    path: 'policies/:policyId',
    name: PolicyRouteNames.PolicyForm,
    component: () => import('@/views/System/Policy/PolicyFormWrapper.vue'),
    meta: {
      layout: 'app',
      title: 'Policies',
    },
  },
];

function redirectDeprecatedPolicyEditorRoute(to: Route): {
  path: string;
  query: Route['query'];
} {
  const { query } = { ...to };
  trackDeprecatedRoute(to);
  if (query.pid) {
    const { pid } = query;
    delete query.pid;
    const redirect = { path: `/manage/policies/${pid}`, query };
    return redirect;
  }
  return { path: `/manage/policies/new`, query };
}

export const deprecatedPoliciesRoutes: RouteConfig[] = [
  {
    path: '/policy-patch-editor',
    name: 'policyPatchEditor',
    redirect: redirectDeprecatedPolicyEditorRoute,
  },
  {
    path: '/policy-required-software-editor',
    name: 'policyRequiredSoftwareEditor',
    redirect: redirectDeprecatedPolicyEditorRoute,
  },
  {
    path: '/policy-custom-editor',
    name: 'policyCustomEditor',
    redirect: redirectDeprecatedPolicyEditorRoute,
  },
];
