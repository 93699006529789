import { ref, reactive, UnwrapRefSimple } from '@vue/composition-api';

export interface ModalState {
  opened: boolean;
  open: () => void;
  close: () => void;
}

export interface ModalStateWithProps<P = unknown> extends ModalState {
  open: (propsData?: P) => void;
  props?: UnwrapRefSimple<P>;
}

export function useModal<P = unknown>(): ModalStateWithProps<P> {
  const opened = ref<boolean>(false);
  const props = ref<P | undefined>();

  function open(propsData?: P) {
    props.value = propsData;
    opened.value = true;
  }

  function close() {
    opened.value = false;
    props.value = undefined;
  }

  return reactive({
    opened,
    open,
    close,
    props,
  });
}

export function useModals<Key extends string>(
  modals: Key[],
): { [K in Key]: ModalState } {
  return modals.reduce(
    (obj, modalName) => ({
      ...obj,
      [modalName]: useModal(),
    }),
    {} as { [K in Key]: ModalState },
  );
}
