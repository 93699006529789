import * as Ref from '@ax/vue-utils/ref';
import * as N from '@ax/function-utils/nullable';
import { AlertBanner } from '@/models/alertBanner';
import {
  ApiClientError,
  AxiosResponseError,
} from '@ax/api-clients-common/models';
import { CacheFirst, makeQueryable, Query } from '@ax/cache-and-dedupe-vue';
import { defaultCatchHandler } from '@ax/data-services-common/queries';
import { constant } from '@ax/function-utils';
import { success } from '@ax/type-utils';
import { createGlobalState } from '@vueuse/core';
import httpClient from './http-client';

const ALERT_BANNERS_ENDPOINT = '/banners';

export class GetAlertBanners extends Query<
  {},
  readonly AlertBanner[],
  ApiClientError<AxiosResponseError>
> {
  readonly type = 'GetAlertBanners';
}

export function fetchAlertBanners() {
  return httpClient
    .get(`${ALERT_BANNERS_ENDPOINT}`)
    .then(({ data }) => success(data))
    .catch(defaultCatchHandler);
}

const { useQuery } = makeQueryable<GetAlertBanners>(() => fetchAlertBanners(), {
  networkPolicy: CacheFirst,
});

export const useAlertBanners = createGlobalState(() => {
  const { data } = useQuery(new GetAlertBanners({}));

  return Ref.map_(data, N.getOrElse(constant<readonly AlertBanner[]>([])));
});
