import { FlagConfig } from '@ax/feature-flag';

export enum FlagKeys {
  customWorkletNotifications,
  viewPolicyResultsPage,
  auth0Milestone1,
  auth0Milestone2,
  samlMigrationWarning,
  viewConfigurations,
  policyRefreshBeforeExecution,
  zoneReauthenticate,
  onboardingZeroState,
  maintenanceWindows,
  integrationAgentDeploy,
  automatedVulnerabilityRemediation,
  requireCodeToDisableTfa,
  remoteControl,
  clonePolicy,
  globalViewDevicesTable,
}

export const flagConfigs: { [K in FlagKeys]: FlagConfig } = {
  [FlagKeys.viewPolicyResultsPage]: {
    key: 'console-good-news-report',
    defaultValue: false,
  },
  [FlagKeys.customWorkletNotifications]: {
    key: 'console-custom-worklet-notifications',
    defaultValue: false,
  },
  [FlagKeys.auth0Milestone1]: {
    key: 'auth0-milestone-1',
    defaultValue: false,
  },
  [FlagKeys.auth0Milestone2]: {
    key: 'auth0-milestone-2',
    defaultValue: false,
  },
  [FlagKeys.samlMigrationWarning]: {
    key: 'saml-migration-warning-shown',
    defaultValue: false,
  },
  [FlagKeys.viewConfigurations]: {
    key: 'configurations-routes',
    defaultValue: false,
  },
  [FlagKeys.policyRefreshBeforeExecution]: {
    key: 'patchservice-refresh-before-policy-execution',
    defaultValue: false,
  },
  [FlagKeys.zoneReauthenticate]: {
    key: 'zone-reauthenticate',
    defaultValue: false,
  },
  [FlagKeys.onboardingZeroState]: {
    key: 'onboarding-zero-state',
    defaultValue: false,
  },
  [FlagKeys.maintenanceWindows]: {
    key: 'maintenance-windows-entry',
    defaultValue: false,
    redirectToIfOff: {
      from: '/manage/maintenance-windows',
      to: () => '/manage/policies',
    },
  },
  [FlagKeys.integrationAgentDeploy]: {
    key: 'integration-agent-deploy',
    defaultValue: false,
  },
  [FlagKeys.automatedVulnerabilityRemediation]: {
    key: 'integration-automated-vulnerability-remediation',
    defaultValue: true,
  },
  [FlagKeys.requireCodeToDisableTfa]: {
    key: 'require-code-to-disable-tfa',
    defaultValue: false,
  },
  [FlagKeys.remoteControl]: {
    key: 'remote-control',
    defaultValue: false,
  },
  [FlagKeys.clonePolicy]: {
    key: 'clone-policy',
    defaultValue: false,
  },
  [FlagKeys.globalViewDevicesTable]: {
    key: 'global-view-devices-table',
    defaultValue: false,
  },
};
