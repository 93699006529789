import { RouteConfig } from 'vue-router';
import { consoleI18n } from '@ax/console-i18n';
import { makeAVRRouteGuard } from '@/compositions/useTasks';

export const automatedVulnerabilityRemediationRoutes: RouteConfig[] = [
  {
    path: 'remediations',
    component: () => import('@/views/System/Tasks/RemediationsLanding.vue'),
    name: 'remediations',
    beforeEnter: makeAVRRouteGuard(true),
    meta: {
      layout: 'app',
      title: consoleI18n.t('routeTitles.remediations'),
    },
  },
  {
    path: 'remediations/:batchId',
    redirect: (to) => {
      return { name: 'remediationDetails', query: to.query };
    },
    component: () => import('@/views/System/Tasks/RemediationDetails.vue'),
    beforeEnter: makeAVRRouteGuard(true),
    meta: {
      layout: 'app',
      title: consoleI18n.t('routeTitles.remediationDetails'),
      handleOwnBreadcrumbs: true,
    },
    children: [
      {
        path: 'vulnerabilities',
        name: 'remediationDetails',
        component: () =>
          import(
            '@/views/System/Tasks/DetailLists/PatchableVulnerabilitiesList.vue'
          ),
        meta: {
          layout: 'app',
          title: consoleI18n.t('routeTitles.remediationDetails'),
          handleOwnBreadcrumbs: true,
        },
      },
      {
        path: 'solutions',
        name: 'remediationSolutions',
        component: () =>
          import(
            '@/views/System/Tasks/DetailLists/UnknownVulnerabilitiesList.vue'
          ),
        meta: {
          layout: 'app',
          title: consoleI18n.t('routeTitles.remediationSolutions'),
          handleOwnBreadcrumbs: true,
        },
      },
      {
        path: 'unknown-devices',
        name: 'remediationUnknownDevices',
        component: () =>
          import('@/views/System/Tasks/DetailLists/UnknownHostsList.vue'),
        meta: {
          layout: 'app',
          title: consoleI18n.t('routeTitles.remediationUnknownDevices'),
          handleOwnBreadcrumbs: true,
        },
      },
    ],
  },
];
