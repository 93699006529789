import { failure } from '@ax/type-utils';
import { identity, tap } from '@ax/function-utils';
import { QueryCache, QueryStatusCacheEntry } from '../cache';
import {
  QueryFetcher,
  QueryResponse,
  UnknownFetchError,
} from '../query-fetcher';
import { AnyQuery } from '../query';
import * as QS from '../query-status';

/**
 * Execute a query `A` with a `QueryFetcher<A>`
 * @param request The query to be executed
 * @param fetcher The fetcher that will execute the query
 * @returns `Promise<ResponseFromQuery<A>>`
 */
export function query_<A extends AnyQuery>(
  request: A,
  fetcher: QueryFetcher<A>,
): Promise<QueryResponse<A>> {
  return fetcher(request).catch((error) => {
    return failure(UnknownFetchError.make(error));
  });
}
/**
 * Curried version of `query_`
 */
export function query<A extends AnyQuery>(fetcher: QueryFetcher<A>) {
  return (request: A) => query_(request, fetcher);
}

/**
 * Execute a query `A` using the `QueryFetcher<A>` while keeping the entry's
 * status up to date.
 * @param request
 * @param fetcher
 * @param entry
 * @returns
 */
export function queryWithCacheEntry_<A extends AnyQuery>(
  request: A,
  fetcher: QueryFetcher<A>,
  entry: QueryStatusCacheEntry<A>,
): Promise<QueryResponse<A>> {
  const currentCacheEntry = entry;
  /**
   * Update the state of the status
   */
  currentCacheEntry.status = QS.fold_(currentCacheEntry.status, {
    onEmpty: (status) => QS.loading(status.maybeData),
    onLoading: identity,
    onRefreshing: identity,
    onFailure: (status) => QS.loading(status.maybeData),
    onSuccess: (status) => QS.refreshing(status.maybeData),
  });

  /**
   * Submit the network request
   */
  return query_(request, fetcher).then(
    tap((data) => {
      currentCacheEntry.status =
        data.type === 'Success'
          ? QS.success(data.success)
          : QS.failure(data.error, currentCacheEntry.status.maybeData);
    }),
  );
}

/**
 * A curried version of `queryWithEntry_`
 * @param fetcher
 * @param entry
 * @returns
 */
export function queryWithCacheEntry<A extends AnyQuery>(
  fetcher: QueryFetcher<A>,
  entry: QueryStatusCacheEntry<A>,
) {
  return (request: A) => queryWithCacheEntry_(request, fetcher, entry);
}

/**
 * Execute a query of type `A` and keep the cache up to date
 * @param request The query of type `A` to be executed
 * @param fetcher The interpreter for the query `A`
 * @param cache The cache to utilize
 * @returns a `Promise<ResponseFromQuery<A>>`
 */
export function queryWithCache_<A extends AnyQuery>(
  request: A,
  fetcher: QueryFetcher<A>,
  cache: QueryCache<A>,
) {
  return queryWithCacheEntry_(request, fetcher, cache.getOrCreate(request));
}

/**
 * Curried version of `queryWithCache`
 */
export function queryWithCache<A extends AnyQuery>(
  fetcher: QueryFetcher<A>,
  cache: QueryCache<A>,
) {
  return (request: A) => queryWithCache_(request, fetcher, cache);
}
