import { computed, watch, Ref, SetupContext } from '@vue/composition-api';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { OrganizationPrefs, Saml } from '@/models/organization';
import {
  ORG_REQUEST_PREFS,
  ORG_UPDATE_PREFS,
  ORG_GET_PREFERENCES,
  ORG_IS_LOADING,
} from '@/store/actions/organization';
import { USER_REQUEST } from '@/store/actions/user';
import {
  createOrg,
  getOrgSaml,
  postOrgSaml,
} from '@/services/organization.service';

export function useOrgPrefs(context: SetupContext) {
  const route = computed(() => context.root.$route);
  const loading = computed<boolean>(
    () => context.root.$store.getters[ORG_IS_LOADING],
  );
  const orgPreferences = computed<OrganizationPrefs | null>(
    () => context.root.$store.getters[ORG_GET_PREFERENCES],
  );

  watch(
    route,
    (next, prev) => {
      const nextOrgId = next.query.org || next.query.o;
      const prevOrgId = prev && (prev.query.org || prev.query.o);
      if (nextOrgId && nextOrgId !== prevOrgId) {
        fetchPreferences(nextOrgId as string);
      }
    },
    {
      immediate: true,
    },
  );

  function fetchPreferences(orgId: string) {
    context.root.$store.dispatch(ORG_REQUEST_PREFS, orgId);
  }

  function updateOrgPreferences(
    nextPrefs: OrganizationPrefs,
  ): Promise<AxiosResponse> {
    const orgId = route.value.query.org || route.value.query.o;
    if (orgId) {
      const prefs = { ...orgPreferences.value, ...nextPrefs };

      return context.root.$store.dispatch(ORG_UPDATE_PREFS, { orgId, prefs });
    }

    return Promise.reject(new Error(i18n.ts('org.provideError')));
  }

  return { loading, orgPreferences, updateOrgPreferences };
}

export function useOrgSaml(context: SetupContext) {
  const orgId = computed(
    () => context.root.$route.query.org || context.root.$route.query.o,
  ) as Ref<string>;

  function getSaml(): Promise<Saml> {
    if (orgId.value) {
      return getOrgSaml(orgId.value);
    }

    return Promise.reject(new Error(i18n.ts('org.provideError')));
  }

  function postSaml(payload: Saml): Promise<AxiosResponse> {
    if (orgId.value) {
      return postOrgSaml(orgId.value, payload).then((response) => {
        context.root.$store.dispatch(USER_REQUEST, orgId.value); // refresh user data with updated Org SAML
        return response;
      });
    }

    return Promise.reject(new Error(i18n.ts('org.provideError')));
  }

  return { getSaml, postSaml };
}

export function useOrgCreate(context: SetupContext) {
  const orgId = computed(
    () => context.root.$route.query.org || context.root.$route.query.o,
  ) as Ref<string>;

  function createOrganization(name: string) {
    if (orgId.value) {
      return createOrg(orgId.value, name).then(() => {
        return context.root.$store.dispatch(USER_REQUEST, orgId.value);
      });
    }

    return Promise.reject(new Error(i18n.ts('org.provideError')));
  }

  return { createOrganization };
}
