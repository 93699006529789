import { WorkletsResponse } from '@/models/worklet';
import { getWorklets } from '@/services/worklets.service';
import { RESET } from '@/store/actions';

const REQUEST = 'request';
const RECEIVED = 'received';
const ERROR = 'error';

const initialState = () => ({
  error: {},
  workletsResponse: {},
  status: 'loading',
});

const getters = {
  getWorkletsResponse: (state): WorkletsResponse => state.workletsResponse,
  isLoading: (state): boolean => state.status === 'loading',
};

const actions = {
  [REQUEST]: ({ commit }, query): Promise<WorkletsResponse | void> => {
    commit(REQUEST);

    return getWorklets(query).then((response) => {
      commit(RECEIVED, { workletsResponse: response });
      return new WorkletsResponse(response.results, response.size);
    });
  },
};

const mutations = {
  [REQUEST]: (state) => {
    state.status = 'loading';
  },
  [RECEIVED]: (state, workletsResponse: WorkletsResponse) => {
    state.workletsResponse = workletsResponse;
    state.status = 'success';
  },
  [ERROR]: (state, error) => {
    state.error = error;
    state.status = 'error';
  },
  [RESET]: (state) => {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations,
};
