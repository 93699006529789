/* eslint-disable no-bitwise */

import {
  differenceInHours,
  format,
  isAfter,
  isValid,
  parse,
  parseISO,
  set,
  startOfDay,
  subDays,
  endOfYesterday,
  startOfYesterday,
  endOfToday,
  startOfToday,
  isBefore,
} from 'date-fns';

import {
  formatUTCToZonedTime,
  userTimeZone,
  DATE_FORMAT,
  formatDate,
  daysExposed,
} from '@ax/date-time-utils';

import { isValidDateString } from '@ax/cosmos/utils/utils';
import { DateRanges } from '@/models/activity-log';

type BitwiseDateType = 'days' | 'weeks' | 'months';

/**
 * This is an odd non-compliant format that PHP uses.  See the link below.
 * https://www.php.net/manual/en/class.datetimeinterface.php#datetime.constants.iso8601
 */

const PHP_TIMESTAMP_FORMAT_STRING = "yyyy-MM-dd'T'HH:mm:ssxxxx";
export { DATE_FORMAT, formatDate, daysExposed };
export const SHORT_DATE_FORMAT = 'LL.dd';

export const DATE_TIME_FORMAT_BASE = 'yyyy-MM-dd hh:mm:ss';
const DATE_TIME_FORMAT = "yyyy-MM-dd h:mm a 'UTC'x";
const TIME_FORMAT = "h:mm a 'UTC'x";

export const BITWISE_DATE_MAP = {
  days: {
    length: 7,
    values: [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ],
  },
  weeks: {
    length: 5,
    values: ['1st', '2nd', '3rd', '4th', '5th'],
  },
  months: {
    length: 12,
    values: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
  },
};

// outputs UTC date/time string in yyyy-mm-dd hh:mm:ss format when passed a date-fns date
// e.g. getUTCDateTime(subDays(new Date(), 30))
export function getUTCDateTime(date: Date) {
  return date.toISOString().slice(0, 19).replace(/T/g, ' ');
}

export const formatCreateTime = (timestamp: string) => {
  const date = timestamp.split('.')[0];

  return format(parse(date, 'yyyy-MM-dd HH:mm:ss', new Date()), DATE_FORMAT);
};

export const formatDateTime = (timestamp: string) => {
  const isoDate = parseISO(timestamp);

  if (isValid(isoDate)) {
    return format(isoDate, DATE_TIME_FORMAT);
  }

  const nonISODate = parse(timestamp, 'M/d/yyyy h:mm:ss bbb', new Date());
  if (isValid(nonISODate)) {
    return format(nonISODate, DATE_TIME_FORMAT);
  }

  return null;
};

export const formatTime = (timestamp: string) => {
  if (timestamp) {
    const date = parseISO(timestamp);

    return format(date, TIME_FORMAT);
  }

  return null;
};

export function formatZonedTimeForServer(
  timestamp: string,
  timeZone: string = userTimeZone,
): string {
  return formatUTCToZonedTime(timestamp, timeZone, PHP_TIMESTAMP_FORMAT_STRING);
}

export function toDayEnd(timestamp: string): Date {
  return set(parseISO(timestamp), { hours: 23, minutes: 59, seconds: 59 });
}

export const hourDifference = (timestamp: string, baseDate = new Date()) => {
  const date = parseISO(timestamp);

  return differenceInHours(date, baseDate);
};

export const isDateAfter = (date: string, dateToCompare = new Date()) => {
  const compareDate = parseISO(date);

  return isAfter(compareDate, dateToCompare);
};

export const parseMilitaryTime = (time: string): string => {
  if (!time) {
    return '';
  }
  return format(parse(time, 'HH:mm', new Date()), 'h:mm aaa');
};

export const bitwiseDate = (
  bitwise: number,
  type: BitwiseDateType,
): string[] => {
  if (!bitwise) {
    return [];
  }

  const dateType = BITWISE_DATE_MAP[type];

  return [...Array(dateType.length)].reduce((dates, _, index) => {
    if ((bitwise & (1 << (index + 1))) !== 0) {
      return [...dates, dateType.values[index]];
    }

    return dates;
  }, []);
};

function startOf7DaysAgoUTC() {
  return getUTCDateTime(startOfDay(subDays(new Date(), 7)));
}

function startOf30DaysAgoUTC() {
  return getUTCDateTime(startOfDay(subDays(new Date(), 30)));
}

export function getStartRangeFromTerm(term: DateRanges) {
  switch (term) {
    case DateRanges.Yesterday:
      return getUTCDateTime(startOfYesterday());
    case DateRanges.Last7Days:
      return startOf7DaysAgoUTC();
    case DateRanges.Last30Days:
      return startOf30DaysAgoUTC();
    default:
      return getUTCDateTime(startOfToday());
  }
}

export function getEndRangeFromTerm(term: DateRanges) {
  switch (term) {
    case DateRanges.Yesterday:
      return getUTCDateTime(endOfYesterday());
    default:
      return getUTCDateTime(endOfToday());
  }
}

// pass a military-formatted time e.g. '23:00' and receive '11:00 PM'
export function formatMilitaryTime(time: string) {
  let splitTime = time
    .toString()
    .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (splitTime.length > 1) {
    splitTime = splitTime.slice(1); // Remove full string match value
    splitTime[5] = +splitTime[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
    splitTime[0] = String(+splitTime[0] % 12 || 12); // Adjust hours
  }
  return splitTime.join('');
}

export const noLaterThanToday = (value) => {
  return !value || isBefore(parseISO(value), endOfToday());
};

// validates now through exactly 90 days ago down to the minute
// accepts Date objects
export const last90Days = (value) => {
  const today = new Date();

  const invalidPastDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 90,
    today.getHours(),
    today.getMinutes() - 1,
  );

  const invalidFutureDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
    today.getHours(),
    today.getMinutes() + 1,
  );

  const isAfter90 = isAfter(value, invalidPastDate);
  const isNotFuture = isBefore(value, invalidFutureDate);
  return !value || (isAfter90 && isNotFuture);
};

// validates now through 90 days ago (inclusive of the entire 90th day)
// accepts 'yyyy-mm-dd' format from Vuetify datepicker
export const last90DaysInclusive = (value) => {
  const isAfter90Inclusive = isAfter(
    parseISO(value),
    startOfDay(subDays(new Date(), 91)),
  );
  const isNotFuture = isBefore(parseISO(value), new Date());
  return !value || (isAfter90Inclusive && isNotFuture);
};

export const dateValidator = (value) => {
  return !value || isValidDateString(value);
};

// pass a 12-hour-formatted time e.g. '11:00 PM' and receive '23:00'
export function format12HourTime(time: string) {
  const [numbers, modifier] = time.split(' ');
  // eslint-disable-next-line prefer-const
  let [hours, minutes] = numbers.split(':');

  if (hours === '12') {
    hours = '00';
  }

  if (Number(hours) < 10 && Number(hours) > 0) {
    hours = `0${hours}`;
  }

  if (modifier === 'PM') {
    hours = String(parseInt(hours, 10) + 12);
  }

  return `${hours}:${minutes}`;
}

/**
 *  Creates a Date object from Vuetify date/time picker strings
 * @param date e.g. '2011-10-10'
 * @param time e.g. '12:00 AM', '4:00 PM'
 */
export const trueDateFromStrings = (date, time) => {
  return parse(`${date} ${time}`, 'yyyy-MM-dd h:mm a', new Date());
};
