/* eslint-disable import/no-cycle */

import Vue from 'vue';
import Vuex from 'vuex';
import { createVuexStore, Module } from 'vuex-simple';

import { env } from '@ax/env';
import { consoleEnv } from '@ax/console-env';
import { createAccountHttpClients } from '@ax/api-clients-accounts';
import { User } from '@ax/data-services-user/models/user';
import { setupInterceptors } from '@/services/http-interceptors';
import { createAuthHttpClients } from '@/services/auth';

import agents from './modules/agents.module';
import app from './modules/app.module';
import auth from './modules/auth.module';
import deviceGroups from './modules/device-groups.module';
import loader from './modules/loader.module';
import organization from './modules/organization.module';
import preferences from './modules/preferences.module';
import reportsOverview from './modules/reports-overview.module';
import user from './modules/user.module';
import worklets from './modules/worklets.module';
import { SystemManagementStateModule } from './system-management.module';

import { USER_GET_PROFILE } from './actions/user';
import { PREFERENCES_SET_PARENT_ORGANIZATION } from './actions/preferences';

Vue.use(Vuex);
const debug = env.NODE_ENV !== 'production';

/**
 * @deprecated
 * add new modules to StateModules enum and register them in RootStore class below
 */
const modules = {
  agents,
  app,
  auth,
  deviceGroups,
  loader,
  organization,
  preferences,
  reportsOverview,
  user,
  worklets,
};

export enum StateModules {
  systemManagement = 'systemManagement',
  macOsAccount = 'macOsAccount',
}

class RootStore {
  @Module()
  [StateModules.systemManagement] = new SystemManagementStateModule();
}

const storeInstance = new RootStore();

export const AppStore = createVuexStore(storeInstance, {
  modules,
  strict: debug,
});

AppStore.watch(
  () => AppStore.getters[USER_GET_PROFILE],
  (userProfile: User) => {
    // Create clients and setup interceptors regardless of how the user state is set.
    if (userProfile.account_id) {
      // Setup HTTP Client for Account
      const accountClients = createAccountHttpClients(
        userProfile.account_id,
        consoleEnv,
        {
          loaderEnabled: true,
          notificationsEnabled: true,
          // Support cross-domain proxy requests during development.
          // This has no effect on the same-origin requests that we use in production.
          withCredentials: consoleEnv.API_IS_PROXY,
        },
      );
      if (accountClients) {
        setupInterceptors(AppStore.dispatch, accountClients);
      }

      // Setup HTTP Client for Auth
      const authClients = createAuthHttpClients();
      if (authClients) {
        setupInterceptors(AppStore.dispatch, authClients);
      }
    }

    // Set parent organization info
    if (userProfile.parent_org?.[0]?.id) {
      AppStore.dispatch(
        PREFERENCES_SET_PARENT_ORGANIZATION,
        userProfile.parent_org?.[0],
      );
    }
  },
);
