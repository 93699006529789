import { Lazy } from './lazy';

/**
 * Used to return a constant value
 * @param val the value to be constantly returned
 * @returns val
 */
export function constant<A>(val: A): Lazy<A> {
  return () => val;
}

export const constUndefined = constant(undefined);

export const constNull = constant(null);

export const constVoid: Lazy<void> = () => {
  // No-op, intentionally empty
};

export const constZero = constant(0);
