var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ax-form-field"},[_c('div',{staticClass:"ax-form-field-wrapper"},[_c('v-combobox',_vm._g(_vm._b({ref:"comboboxComponent",staticClass:"ax-combobox",class:{
        'has-focus-visible': _vm.hasFocusVisible,
        nested: _vm.nested,
      },attrs:{"id":_vm.id,"deletable-chips":_vm.chips,"label":_vm.required && _vm.label ? ("* " + _vm.label) : _vm.label,"search-input":_vm.searchModel,"small-chips":_vm.chips,"dense":"","outlined":"","persistent-hint":""},on:{"update:searchInput":function($event){_vm.searchModel=$event},"update:search-input":function($event){_vm.searchModel=$event},"blur":_vm.handleBlur,"focus":_vm.handleFocus,"input":_vm.handleInput,"mouseup":_vm.handleMouseup,"update:error":_vm.handleUpdateError},nativeOn:{"paste":function($event){return _vm.acceptPastedValue.apply(null, arguments)}},scopedSlots:_vm._u([(_vm.chips)?{key:"selection",fn:function(ref){
      var attrs = ref.attrs;
      var index = ref.index;
      var item = ref.item;
      var selected = ref.selected;
return [(
            Number.isInteger(_vm.visibleChips) &&
            index === 0 &&
            _vm.value.length > _vm.visibleChips
          )?_c('ax-chip',_vm._b({attrs:{"allow-text-wrap":_vm.allowChipTextWrapping,"title":_vm.value.slice(0, _vm.value.length - _vm.visibleChips)}},'ax-chip',attrs,false),[_vm._v(" (+"+_vm._s(_vm.value.length - _vm.visibleChips)+" others) ")]):_vm._e(),(
            _vm.visibleChips === undefined ||
            _vm.value.length < _vm.visibleChips ||
            index >= _vm.value.length - _vm.visibleChips
          )?_c('ax-chip',_vm._b({attrs:{"allow-text-wrap":_vm.allowChipTextWrapping,"input-value":selected,"close":""},on:{"click:close":function($event){return _vm.removeItem(index)}}},'ax-chip',attrs,false),[_c('span',[_vm._v(_vm._s(_vm.getItemText(item)))])]):_vm._e()]}}:null,_vm._l((_vm.$scopedSlots),function(_,slotName){return {key:slotName,fn:function(slotData){return [_vm._t(_vm.name,null,null,slotData)]}}})],null,true),model:{value:(_vm.localValue),callback:function ($$v) {_vm.localValue=$$v},expression:"localValue"}},'v-combobox',Object.assign({}, _vm.defaultAttrs,
        _vm.$props,
        {required: undefined}),false),_vm.$listeners),[_vm._l((_vm.$slots),function(_,slotName){return _vm._t(slotName,null,{"slot":slotName})})],2),(_vm.showHint && _vm.hint)?_c('div',{staticClass:"v-text-field__details"},[_c('div',{staticClass:"v-messages theme--dark"},[_vm._v(" "+_vm._s(_vm.hint)+" ")])]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }