import { MaybeLazy, unwrapMaybeLazy } from './maybe-lazy';

/**
 *
 * @param ms the length of time to sleep
 * @returns a `Promise<void>` that will resolve after `ms` milliseconds has elapsed
 */
export function sleep(ms: number): Promise<void> {
  if (ms <= 0) {
    return Promise.resolve();
  }

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

/**
 *
 * @param value The value to return after `sleep` has elapsed
 * @param ms The length of time to sleep in milliseconds
 * @returns
 */
export function sleepWith<A>(ms: number, value: MaybeLazy<A>): Promise<A> {
  if (ms <= 0) {
    try {
      return Promise.resolve(unwrapMaybeLazy(value));
    } catch (e) {
      return Promise.reject(e);
    }
  }

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        resolve(unwrapMaybeLazy(value));
      } catch (e) {
        reject(e);
      }
    }, ms);
  });
}
