import { MaybeLazy } from '@ax/function-utils';
import { empty, QueryStatusFromQuery } from '../query-status';
import { AnyQuery, ShapeOfSuccess } from '../query';

/**
 * A function capable of listening in on updates to a `QueryStatus<A>`
 */
export interface QueryStatusSubscriber<A extends AnyQuery> {
  (status: QueryStatusFromQuery<A>): void;
}

/**
 * A function used to unsubscribe from updates to a `QueryStatus`
 */
export type Unsubscribe = () => void;

/**
 * The cache entry for a given query `A`. The cache entry is capable
 * of being subscribed to so that updates to the entry will be telegraphed
 * to all subscribers.
 */
export class QueryStatusCacheEntry<A extends AnyQuery> {
  /**
   * The current status of the query
   */
  #currentStatus: QueryStatusFromQuery<A>;

  /**
   * The subscribers to notify when `currentStatus` changes
   */
  readonly #subscribers = new Set<QueryStatusSubscriber<A>>();

  constructor(maybeData?: MaybeLazy<ShapeOfSuccess<A> | undefined>) {
    this.#currentStatus = empty(maybeData);
  }

  /**
   * Get the current status of the query.
   */
  get status() {
    return this.#currentStatus;
  }

  /**
   * Update the current status and notify all subscribers
   */
  set status(newStatus: QueryStatusFromQuery<A>) {
    this.#currentStatus = newStatus;
    this.#subscribers.forEach((subscriber) => {
      subscriber(newStatus);
    });
  }

  /**
   * Subscribe to changes to the `QueryStatus<A>`. Subscribers are lazy, they will
   * only be evaluated on future updates to the status. If you need the current
   * status, just pull out directly via `this.status`
   * @param subscriber `QueryStatusSubscriber<A>`
   * @returns `Unsubscribe`
   */
  subscribe(subscriber: QueryStatusSubscriber<A>): Unsubscribe {
    this.#subscribers.add(subscriber);

    return () => {
      this.#subscribers.delete(subscriber);
    };
  }

  static make = <T extends AnyQuery>(
    maybeData?: MaybeLazy<ShapeOfSuccess<T> | undefined>,
  ) => new QueryStatusCacheEntry<T>(maybeData);

  static fromStatus = <T extends AnyQuery>(status: QueryStatusFromQuery<T>) => {
    const newEntry = new QueryStatusCacheEntry();
    newEntry.status = status;
    return newEntry;
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyQueryStatusCacheEntry = QueryStatusCacheEntry<any>;
