import { Route } from 'vue-router';

/**
 * Ignores duplicated query parameters, instead just using the first instance of each.
 * @param query The current route query.
 * @returns The route query with duplicates ignored.
 */
export function ignoreDuplicateQueryParams(
  query: Route['query'],
): Record<string, string | undefined> {
  return Object.fromEntries(
    Object.entries(query).map(([key, value]) => {
      if (Array.isArray(value)) {
        const [first] = value;
        return [key, first === null ? undefined : first];
      }
      return [key, value];
    }),
  );
}
