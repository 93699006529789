export enum AccountRbacRole {
  GlobalAdmin = 'global-admin',
  NoGlobalAccess = 'no-global-access',
}

export enum BuiltInZoneRbacRole {
  readOnly = 'read-only',
  billingAdmin = 'billing-admin',
  patchOperator = 'patch-operator',
  zoneOperator = 'zone-operator',
  patchAdmin = 'patch-admin', // legacy version of zone operator
  zoneAdmin = 'zone-admin',
}

export type ZoneRbacRole = BuiltInZoneRbacRole | string;

export const AccountRoleName: Readonly<{ [key in AccountRbacRole]: string }> =
  Object.freeze({
    [AccountRbacRole.GlobalAdmin]: 'Global Administrator',
    [AccountRbacRole.NoGlobalAccess]: 'No Global Access',
  });

export enum AccountUserStatus {
  pendingInvite = 'pending-invite',
  active = 'active',
  disabled = 'disabled',
}

export enum TfaType {
  email = 'email',
  google = 'google',
}

export enum AssignmentAction {
  add = 'add',
  change = 'change',
  remove = 'remove',
}

export interface AccountRbacRoleDTO {
  slug: AccountRbacRole | ZoneRbacRole;
  name: string;
  description: string;
  type: `${RoleType}`;
}

// A user from the point of view of an account
export interface AccountUser {
  id: UserUUID;
  user_id?: number;
  email: string;
  first_name?: string;
  last_name?: string;
  status: `${AccountUserStatus}`;
  is_verified: boolean;
  two_factor_authentication?: `${TfaType}` | null;
  tfa_enabled?: boolean;
  account_rbac_role: `${AccountRbacRole}`;
  created_at: DateString;
  updated_at: DateString;
}

export interface AccountUserWithZones extends AccountUser {
  zone_assignments: ZoneAssignmentExpanded[];
}

export interface AccountUserCreate {
  email: string;
  account_rbac_role: `${AccountRbacRole}`;
  zone_assignments: ZoneAssignment[];
}

export interface AccountUserUpdate {
  account_rbac_role: `${AccountRbacRole}`;
  zone_assignments?: ZoneAssignmentWithAction[];
}

// When a user is created, the API returns an AccountInvitation
export interface AccountInvitation {
  id: InvitationUUID;
  account_id: AccountUUID;
  account_rbac_role: AccountRbacRole;
  inviter: AccountUser;
  email: string;
  zone_assignments: ZoneAssignment[];
  created_at: DateString;
  updated_at: DateString;
}

// A user from the point of view of a zone
// (The zone already knows its own ID, so the user here does not have a zone_id property)
export interface ZoneUserOld extends AccountUser {
  zone_rbac_role: string;
}

export interface ZoneUser {
  user: AccountUser;
  rbac_role: string;
}

export interface Zone {
  id: ZoneUUID;
  organization_id?: number;
  account_id: AccountUUID;
  parent_id?: ZoneUUID | null;
  name: string;
  created_by: AccountUser;
  access_key: string;
  created_at: DateString;
  updated_at: DateString;
}

export interface ZoneWithCounts extends Zone {
  user_count: number;
  device_count: number;
  legacy_saml_enabled: boolean;
}

export interface ZoneCreate {
  name: string;
  user_assignments: UserAssignment[];
}

export interface ZoneUpdate {
  name: string;
  user_assignments?: UserAssignmentWithAction[];
}

// A user's relationship to a zone, from the user's point of view
export interface ZoneAssignment {
  name: string;
  rbac_role: ZoneRbacRole;
  zone_id: ZoneUUID;
}

export type ZoneAssignmentExpandedZone = Omit<Zone, 'created_by' | 'users'> & {
  created_by: string;
};

export interface ZoneAssignmentWithAction extends ZoneAssignment {
  action: `${AssignmentAction}`;
}

export interface ZoneAssignmentExpanded {
  rbac_role: ZoneRbacRole;
  zone: ZoneAssignmentExpandedZone;
}

// A zone's relationship to a user, from the zone's point of view
export interface UserAssignment {
  rbac_role: ZoneRbacRole;
  email: string;
}

export interface UserAssignmentWithAction extends UserAssignment {
  action: `${AssignmentAction}`;
}

export enum RoleType {
  account = 'account',
  zone = 'zone',
}

type DateString = string;
type UUID = string;
export type AccountUUID = UUID;
export type ZoneUUID = UUID;
export type UserUUID = UUID;
export type InvitationUUID = UUID;
