import VueI18n, { Path, Values, I18nOptions } from 'vue-i18n';

export type ProxyI18n = Omit<VueI18n, 't'> & {
  t: (key: Path, values?: Values) => string;
  // Maintain backwards compatibility with our custom 'ts' method
  ts: (key: Path, values?: Values) => string;
};

/**
 * Factory function to install vue-i18n in your app and create an instance of vue-i18n
 * with a given set of locale messages.  It returns the vue-i18n instance which must be
 * passed to your app's Vue initialization. This same instance can be used outside of
 * vue templates (e.g. i18n.t('key')).
 *
 * @param Vue A reference to Vue so that we can install the VueI18n plugin for your app
 * @param options I18nOptions object containing locale messages for all locales and other options
 * @returns Proxy of a vue-i18n instance with `t` method updated to always return a string
 */
export function I18nFactory(Vue, options: I18nOptions) {
  Vue.use(VueI18n);

  // Maintain backwards compatibility with our custom 'ts' method
  class VueI18nExtended extends VueI18n {
    ts(key: Path, values?: Values): string {
      return this.t(key, values).toString();
    }
  }

  const i18n = new VueI18nExtended({
    locale: options.locale || 'en-US',
    fallbackLocale: options.fallbackLocale || 'en-US',
    ...options,
  });

  // Ensure usages of i18n.t() always return a string
  const i18nProxy = new Proxy(i18n, {
    get(target, prop, ...args) {
      // Maintain backwards compatibility with our custom 'ts' method
      if (prop === 't' || prop === 'ts') {
        const origMethod = target.t;
        return function overrideT(...args) {
          const result = origMethod
            .apply(
              target,
              args as [key: string, locale: string, values?: Values],
            )
            .toString();
          return result;
        };
      }
      return Reflect.get(target, prop, ...args);
    },
  }) as ProxyI18n;

  return i18nProxy;
}
