import { Nullable } from '@ax/type-utils';
import { isNullable } from './is-nullable';
import { Lazy } from '../core';

/**
 * Use `getOrElse` and `getOrElse_` to convert a `Nullable<A>` into a concrete value `A | B`.
 * `getOrElse` and `getOrElse_` will call `onNullable` to return `B` if the value provided is nullable,
 * or it return `A`.
 *
 * @example
 * import { pipe } from '@ax/function-utils';
 * import { prop } from '@ax/object-utils';
 * import { getOrElse_, getOrElse, map, map_ } from '@ax/function-utils/nullable';
 * import { constant } from '@ax/function-utils/constant
 *
 * interface User {
 *  rbac_roles?: Role[]
 * }
 *
 * const user: User | undefined = undefined;
 *
 * const constEmptyArray = constant([]);
 *
 * const extractRbacRoles = prop('rbac_roles')
 *
 * // without pipe
 * const userRoles: Role[] = getOrElse_(map_(user, extractRbacRoles), constEmptyArray)
 *
 * // with pipe
 * const userRoles: Role[] = pipe(user, map(extractRbacRoles), getOrElse(constEmptyArray));
 *
 * @param self `Nullable<A>`
 * @param onNullable `Lazy<B>`value to generate when `self` is nullable
 * @returns `B | C`
 */
export function getOrElse_<A, B>(
  self: Nullable<A>,
  onNullable: Lazy<B>,
): A | B {
  return isNullable(self) ? onNullable() : self;
}

/**
 * Use `getOrElse` and `getOrElse_` to convert a `Nullable<A>` into a concrete value `A | B`.
 * `getOrElse` and `getOrElse_` will call `onNullable` to return `B` if the value provided is nullable,
 * or it return `A`.
 *
 * @example
 * import { pipe } from '@ax/function-utils';
 * import { prop } from '@ax/object-utils';
 * import { getOrElse_, getOrElse, map, map_ } from '@ax/function-utils/nullable';
 * import { constant } from '@ax/function-utils/constant
 *
 * interface User {
 *  rbac_roles?: Role[]
 * }
 *
 * const user: User | undefined = undefined;
 *
 * const constEmptyArray = constant([]);
 *
 * const extractRbacRoles = prop('rbac_roles')
 *
 * // without pipe
 * const userRoles: Role[] = getOrElse_(map_(user, extractRbacRoles), constEmptyArray)
 *
 * // with pipe
 * const userRoles: Role[] = pipe(user, map(extractRbacRoles), getOrElse(constEmptyArray));
 *
 * @param onNullable `Lazy<B>`value to generate when `self` is nullable
 * @returns `B | C`
 */
export function getOrElse<B>(onNullable: Lazy<B>) {
  return <A>(self: Nullable<A>) => getOrElse_(self, onNullable);
}
