import { errorReporter } from '@ax/error-reporter';
import { Ref, watch } from '@vue/composition-api';
import { tryOnScopeDispose } from '@vueuse/core';

export interface LoadingIndicatorTime {
  /**
   * The amount of time the loading indicator was visible
   */
  readonly timeVisible: number;
  /**
   * The amount of total time that has elapsed
   */
  readonly timeTotal: number;
}

/**
 * A function that handles submission of the `LoadingIndicatorTime`
 */
export interface LoadingIndicatorTimeReporter {
  (metric: LoadingIndicatorTime): void;
}

/**
 * A default reporter that submits the metric to Datadog, can be overridden for
 * testing
 */
export const defaultReporter: LoadingIndicatorTimeReporter = (metric) => {
  /**
   * Reporting via both `addAction` and `addTiming` to see which works better in DD.
   */

  errorReporter.addAction('loading_indicator_is_visible', {
    time_visible: metric.timeVisible,
    time_total: metric.timeTotal,
  });

  errorReporter.addTiming('loading_indicator_hidden');
};

/**
 * This effectively represents the dependencies of `setupLoadingTracker`.
 * The primary reason for extracting these out is for testing.
 */
export interface LoadingIndicatorTrackerOptions {
  readonly reporter?: LoadingIndicatorTimeReporter;
  readonly currentTime?: () => number;
}

/**
 * Will track the amount of time the `isLoading` ref is `true` and
 * reports the metric back to datadog by default.
 * @param isLoading `Ref<boolean>`
 * @param options
 */
export function setupLoadingTracker(
  isLoading: Ref<boolean>,
  options: Partial<LoadingIndicatorTrackerOptions> = {},
) {
  const { reporter = defaultReporter, currentTime = () => Date.now() } =
    options;
  /**
   * The time the loading indicator was last initialized
   */
  let lastStart: number | undefined;
  /**
   * Running total of how much time the indicator has been visible
   */
  let timeVisibleSinceLastSubmission = 0;
  /**
   * Time the last metric was submitted
   */
  let lastSubmitted = currentTime();

  /**
   * Submit the metric info
   */
  function submit() {
    const now = currentTime();

    if (isLoading.value && lastStart !== undefined) {
      /**
       * We need to add the time since the `lastStart` if we are in
       * the middle of a visibility window
       */
      timeVisibleSinceLastSubmission += now - lastStart;
      lastStart = now;
    }
    reporter({
      timeVisible: timeVisibleSinceLastSubmission,
      timeTotal: now - lastSubmitted,
    });

    lastSubmitted = now;
    timeVisibleSinceLastSubmission = 0;
  }

  /**
   * Every 60 seconds, submit how much time the loading indicator has
   * been visible.
   */
  const interval = setInterval(submit, 60_000);

  /**
   * Clean up interval when this is no longer running
   */
  tryOnScopeDispose(() => {
    clearInterval(interval);
  });

  watch(
    isLoading,
    (newVal, oldVal) => {
      if (newVal === oldVal) {
        /**
         * Nothing has changed so don't update anything
         */
        return;
      }
      /**
       * If the loading indicator goes from visible to hidden, update time spent
       * visible and reset `lastStart`
       */
      if (oldVal === true && newVal === false && lastStart !== undefined) {
        timeVisibleSinceLastSubmission += currentTime() - lastStart;
        lastStart = undefined;
        return;
      }
      /**
       * Newly set to is visible so reset `lastStart`
       */
      if (newVal === true) {
        lastStart = currentTime();
      }
    },
    {
      immediate: true,
      /**
       * We want this to run sync after `isLoading` changes
       */
      flush: 'sync',
    },
  );

  /**
   * When document unloads, submit most recent
   */
  window.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'hidden') {
      submit();
    }
  });
}
