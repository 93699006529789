/* Dispatch Actions */
export const PREFERENCES_SET_ORGANIZATION = 'preferences/set_organization';
export const PREFERENCES_SET_PARENT_ORGANIZATION =
  'preferences/set_parent_organization';
export const PREFERENCES_SET_ROLE = 'preferences/set_role';

/** Getters */
export const PREFERENCES_GET_ORGANIZATION = 'preferences/getOrganization';
export const PREFERENCES_GET_PARENT_ORGANIZATION =
  'preferences/getParentOrganization';
export const PREFERENCES_GET_ROLE = 'preferences/getRole';
