import { RouteConfig } from 'vue-router';
import { makeAVRRouteGuard } from '@/compositions/useTasks';

export const vulnerabilitySyncRoutes: RouteConfig[] = [
  {
    path: 'tasks',
    component: () => import('@/views/System/Tasks/Legacy/TasksNav.vue'),
    meta: {
      layout: 'app',
      title: 'Tasks',
    },
    beforeEnter: makeAVRRouteGuard(false),
    children: [
      {
        path: '',
        name: 'tasksList',
        component: () => import('@/views/System/Tasks/Legacy/TasksList.vue'),
        meta: {
          layout: 'app',
          title: 'All Tasks',
        },
      },
      {
        path: 'batches',
        name: 'batches',
        component: () => import('@/views/System/Tasks/Legacy/TasksBatches.vue'),
        meta: {
          layout: 'app',
          title: 'Task Batches',
        },
      },
      {
        path: 'batches/:batchId',
        component: () => import('@/views/System/Tasks/Legacy/BatchesNav.vue'),
        meta: {
          layout: 'app',
          title: 'Batches',
        },
        children: [
          {
            path: '',
            name: 'batchDetails',
            component: () =>
              import('@/views/System/Tasks/Legacy/BatchDetails.vue'),
            meta: {
              layout: 'app',
              title: 'Batch Details',
            },
          },
          {
            path: 'potential-issues',
            name: 'potentialIssues',
            component: () =>
              import('@/views/System/Tasks/Legacy/BatchPotentialIssues.vue'),
            meta: {
              layout: 'app',
              title: 'Batch Potential Issues',
            },
          },
        ],
      },
    ],
  },
  {
    path: 'tasks/:taskId',
    name: 'taskDetails',
    component: () => import('@/views/System/Tasks/Legacy/TaskDetails.vue'),
    meta: {
      layout: 'app',
      title: 'Task Details',
    },
    beforeEnter: makeAVRRouteGuard(false),
  },
];
