import { Nullable } from '@ax/type-utils';
import { isNullable } from './is-nullable';
import { Lazy } from '../core';

/**
 * Use `fold` and `fold_` to convert a `Nullable<A>` into a concrete value `B | C`.
 * `fold` and `fold_` will call `onNullable` to return `B` if the value provided is nullable,
 * or it will use the non-nullable value and supply it to `onNonNullable` to generate `C`. In
 * most cases, `B` and `C` will be the same type.
 *
 * @example
 * import { pipe } from '@ax/function-utils';
 * import { fold_, fold } from '@ax/function-utils/nullable';
 * import { constant } from '@ax/function-utils/constant
 *
 * interface User {
 *  rbac_roles: Role[]
 * }
 *
 * const user: User | undefined = undefined;
 *
 * const constEmptyArray = constant([]);
 *
 * const extractRbacRoles = prop('rbac_roles')
 *
 * // without pipe
 * const userRoles: Role[] = fold_(user, constEmptyArray, extractRbacRoles);
 *
 * // with pipe
 * const userRoles: Role[] = pipe(user, fold(constEmptyArray, extractRbacRoles));
 *
 * @param self `Nullable<A>`
 * @param onNullable `Lazy<B>`value to generate when `self` is nullable
 * @param onNonNullable `A -> C` function to map `A` to `C` when `self is non-nullable
 * @returns `B | C`
 */
export function fold_<A, B, C>(
  self: Nullable<A>,
  onNullable: Lazy<B>,
  onNonNullable: (a: A) => C,
): B | C {
  return isNullable(self) ? onNullable() : onNonNullable(self);
}

/**
 * Use `fold` and `fold_` to convert a `Nullable<A>` into a concrete value `B | C`.
 * `fold` and `fold_` will call `onNullable` to return `B` if the value provided is nullable,
 * or it will use the non-nullable value and supply it to `onNonNullable` to generate `C`. In
 * most cases, `B` and `C` will be the same type.
 *
 * @example
 * import { pipe } from '@ax/function-utils';
 * import { fold_, fold } from '@ax/function-utils/nullable';
 * import { constant } from '@ax/function-utils/constant
 *
 * interface User {
 *  rbac_roles: Role[]
 * }
 *
 * const user: User | undefined = undefined;
 *
 * const constEmptyArray = constant([]);
 *
 * const extractRbacRoles = prop('rbac_roles')
 *
 * // without pipe
 * const userRoles: Role[] = fold_(user, constEmptyArray, extractRbacRoles);
 *
 * // with pipe
 * const userRoles: Role[] = pipe(user, fold(constEmptyArray, extractRbacRoles));
 *
 * @param onNullable `Lazy<B>`value to generate when `self` is nullable
 * @param onNonNullable `A -> C` function to map `A` to `C` when `self is non-nullable
 */
export function fold<A, B, C>(onNullable: Lazy<B>, onNonNullable: (a: A) => C) {
  return (self: Nullable<A>) => fold_(self, onNullable, onNonNullable);
}
