import { SetupContext, Ref, computed } from '@vue/composition-api';
import { User } from '@ax/data-services-user/models/user';
import { USER_GET_PROFILE } from '@/store/actions/user';
import { getCurrentUser } from '@/compositions/useUser';

/**
 * Checks if the user is granted access to global account management views
 * based on user role and existence of account id.
 * Should always be used post-login when the user is set.
 *
 * For use within components
 *
 * @param {SetupContext} context
 * @returns Ref that is true iff the user is granted access
 */
export function useAccountManagementAccess(
  context: SetupContext,
): Ref<boolean> {
  const user = computed<User>(
    () => context.root.$store.getters[USER_GET_PROFILE],
  );
  const isGlobalAdmin = computed(() => user.value.is_global_admin);
  const hasAccount = computed(() => !!user.value.account_id);
  return computed(() => {
    return !!(isGlobalAdmin.value && hasAccount.value);
  });
}

/**
 * Checks if the user is granted access to global account management views
 * based on user role and existence of account id.
 * Should always be used post-login when the user is set.
 *
 * For use outside of components
 *
 * @returns Promise that resolves true if the user is granted access
 */
export function getAccountManagementAccess(): Promise<boolean> {
  return getCurrentUser().then(
    (user) => user.is_global_admin && !!user.account_id,
  );
}
