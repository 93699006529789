import { Ref, shallowRef, watch } from '@vue/composition-api';

/**
 * Use `local` where you want a local copy of a ref that will still receive
 * updates from a `source`. This can be useful with forms where you might
 * receive an initial state but want to update the value locally. Then
 * when the form is submitted, the source might get updated and you want the local
 * copy to reflect that update.
 *
 * @example
 * import { local } from '@ax/vue-utils/ref'
 *
 * // inside setup
 * // name can be safely modified locally, and when the `name` prop
 * // updates the `name` ref will receive that update
 * const name = local(toRef(props, 'name'));
 *
 * @param source The source `Ref<A>`
 * @returns a local copy `Ref<A>`
 */
export function local<A>(source: Ref<A>): Ref<A> {
  const internal = shallowRef(source.value);
  watch(source, (newVal, oldVal) => {
    /**
     * It is easy to accidentally create a reactive cycle with this utility.
     * For instance, if you emit an updated local value to a parent, and then
     * have this local ref react to new props coming in, you might have a
     * cycle. The below equality check should break the cycle.
     */
    if (newVal !== oldVal) {
      internal.value = newVal;
    }
  });
  return internal;
}
