import { computed } from '@vue/composition-api';
import { PlanName, TierRank } from '@ax/data-services-account/models/billing';
import {
  ParentOrganization,
  Organization,
} from '@ax/data-services-zone/models';
import { Zone } from '@ax/api-clients-accounts/models';
import {
  PREFERENCES_GET_ORGANIZATION,
  PREFERENCES_GET_PARENT_ORGANIZATION,
} from '@/store/actions/preferences';
import { USER_GET_PROFILE } from '@/store/actions/user';
import { AppStore } from '@/store';

export function orgHasTierOfAtLeast(
  org: Organization | ParentOrganization,
  planName: PlanName,
) {
  if (!org || !planName) {
    return false;
  }

  return !!(
    (org.trial_end_time && !org.trial_expired) ||
    (!org.trial_end_time && TierRank[org.plan] >= TierRank[planName])
  );
}

export function useOrganizationTier() {
  const currentOrg = computed<Organization>(
    () => AppStore.getters[PREFERENCES_GET_ORGANIZATION],
  );
  const parentOrg = computed<ParentOrganization>(
    () => AppStore.getters[PREFERENCES_GET_PARENT_ORGANIZATION],
  );

  // When an account is below Tier 3 and only has a single org/zone
  // then the account is restricted to only ever having that one org/zone
  // (unless they upgrade to Tier 3).
  // Some workflows are streamlined for these single-org-restricted accounts.
  const isSingleOrgRestricted = computed<boolean>(() => {
    const numberOfOrgs = AppStore.getters[USER_GET_PROFILE].orgs.length;
    return !!(
      !orgHasTierOfAtLeast(parentOrg.value, PlanName.tier3) &&
      numberOfOrgs === 1
    );
  });

  function currentOrgHasTierOfAtLeast(planName: PlanName) {
    return computed(() => orgHasTierOfAtLeast(currentOrg.value, planName));
  }

  function parentOrgHasTierOfAtLeast(planName: PlanName) {
    return computed(() => orgHasTierOfAtLeast(parentOrg.value, planName));
  }

  // When the feature flag is turned on for all these functions can be changed from a function to a ComputedRef
  function isCurrentOrgDisabled() {
    return computed<boolean>(() => {
      return !!(
        currentOrg.value?.parent_id &&
        !parentOrgHasTierOfAtLeast(PlanName.tier3).value
      );
    });
  }

  function isZoneDisabled(zone: Zone | undefined) {
    return computed<boolean>(() => {
      return !!(
        zone?.parent_id && !parentOrgHasTierOfAtLeast(PlanName.tier3).value
      );
    });
  }

  return {
    currentOrgHasTierOfAtLeast,
    parentOrgHasTierOfAtLeast,
    isSingleOrgRestricted,
    isCurrentOrgDisabled,
    isZoneDisabled,
  };
}
