import { computed, ref, Ref, SetupContext } from '@vue/composition-api';
import {
  useFeatureFlag,
  useFeatureFlags,
  getFlagValue,
} from '@ax/feature-flag';
import { PermissionMap } from '@ax/data-services-authorization/business-logic/permissions';
import { Organization } from '@ax/data-services-zone/models/organization';
import { User } from '@ax/data-services-user/models/user';
import { usePermissionsForUserAndOrg } from '@ax/data-services-authorization/compositions/usePermissions';

import { createGlobalState } from '@vueuse/core';
import { FlagKeys } from '@/models/feature-flags';
import { AppStore } from '@/store';
import { AUTH_LOGOUT } from '@/store/actions/auth';

export default function useAuthentication() {
  function logout() {
    AppStore.dispatch(AUTH_LOGOUT, { shouldRedirect: true });
  }

  return { logout };
}

function isAdminOfOrg(user: Ref<User>, orgId: Organization['id']) {
  return (
    user.value.is_global_admin ||
    usePermissionsForUserAndOrg(orgId, user).useCanAccess(
      PermissionMap.Organization.Manage,
    ).value
  );
}

function konamiEntered() {
  const SEQUENCE = [
    'ArrowUp',
    'ArrowUp',
    'ArrowDown',
    'ArrowDown',
    'ArrowLeft',
    'ArrowRight',
    'ArrowLeft',
    'ArrowRight',
    'KeyB',
    'KeyA',
  ];
  let position = 0;
  return new Promise<void>((resolve) => {
    function onKeyDown({ code }: KeyboardEvent) {
      if (code === SEQUENCE[position]) {
        position += 1;
        console.info('ENTERED:', code);

        if (position === SEQUENCE.length) {
          console.info('KONAMI ACTIVATED!');
          sessionStorage.setItem('konami-activated', 'yes!');
          console.info(
            "TO DEACTIVATE: remove 'konami-activated' from sessionStorage",
          );
          document.removeEventListener('keydown', onKeyDown);
          resolve();
        } else {
          console.info(SEQUENCE.length - position, 'MORE TO ACTIVATE KONAMI');
        }
      } else if (position !== 0) {
        position = 0;
        console.info(code, 'INVALID - KONAMI RESET');
      }
    }

    document.addEventListener('keydown', onKeyDown);
  });
}

const useKonami = createGlobalState(() => {
  const konami = ref(sessionStorage.getItem('konami-activated') === 'yes!');
  if (localStorage.getItem('konami-ready') !== null) {
    konamiEntered().then(() => {
      konami.value = true;
    });
  }
  return konami;
});

export function useAdminWithLegacySAML(user: Ref<User>) {
  const konami = useKonami();
  return {
    isAdminWithLegacySAMLEnabled: computed<boolean>(() =>
      user.value.orgs
        ?.filter(({ saml }) => saml.enabled || konami.value)
        .some(({ id }) => isAdminOfOrg(user, id)),
    ),
  };
}

export function useAuth0Milestone1Flag(context: SetupContext) {
  return useFeatureFlag(context.root.$router, FlagKeys.auth0Milestone1);
}

export function useAuth0Milestone2Flag(context: SetupContext) {
  return useFeatureFlag(context.root.$router, FlagKeys.auth0Milestone2);
}

export function useSAMLMigrationWarningFlag(context: SetupContext) {
  return useFeatureFlag(context.root.$router, FlagKeys.samlMigrationWarning);
}

export function useAuth0Flags(context: SetupContext) {
  return useFeatureFlags(context.root.$router, [
    FlagKeys.auth0Milestone1,
    FlagKeys.auth0Milestone2,
    FlagKeys.samlMigrationWarning,
  ]);
}

export function getAuth0Milestone1Flag() {
  return getFlagValue(FlagKeys.auth0Milestone1);
}

export function getAuth0Milestone2Flag() {
  return getFlagValue(FlagKeys.auth0Milestone2);
}
