/**
 * Legacy env using process.env. This is only used in console build script. Once this is migrated
 * to Vite build, this file can be removed.
 */

import { getEnv } from './getEnv';
import { envConfig } from './envConfig';

// Replace "MODE" with "NODE_ENV"
envConfig.NODE_ENV.env = 'NODE_ENV';

export const env = getEnv(envConfig, process.env);
