import { Copyable, UnknownRecord } from '@ax/object-utils';
import * as H from '../hash';

/**
 * The symbol where we will hide the success type information. This
 * symbol is not exposed outside this file so it can't be accessed
 */
const ShapeOfSuccessURI = Symbol.for('@ax/query/success');

type ShapeOfSuccessURI = typeof ShapeOfSuccessURI;

/**
 * If `A` extends `Query<any, T, any>`, return `T`, otherwise
 * never
 */
export type ShapeOfSuccess<A> = [A] extends [
  { [K in ShapeOfSuccessURI]: () => infer T },
]
  ? T
  : never;

/**
 * The symbol where we will hide the error type information. This
 * symbol is not exposed outside this file so it can't be accessed at runtime.
 */
const ShapeOfErrorURI = Symbol.for('@ax/query/error');

type ShapeOfErrorURI = typeof ShapeOfErrorURI;

/**
 * If `A` extends `Query<any, any, E>`, return `E`, otherwise
 * never
 */
export type ShapeOfError<A> = [A] extends [
  { [K in ShapeOfErrorURI]: () => infer E },
]
  ? E
  : never;
/**
 * A `Query<Params, Succ, Err>` is a description of a request that
 * requires parameters of type `Params`, can succeed with type `Succ`,
 * and can fail with type `Err`.
 *
 * A `Query` is consistently hashable. We utilize the hash to create
 * a `Query` cache to eliminate as many network requests as possible.
 */

// @ts-expect-error because Params isn't statically known, typescript complains here
export abstract class Query<Params extends UnknownRecord, Succ, Err>
  extends Copyable<Params>
  implements H.HasHash
{
  /**
   * The `type` of a `Query` is used to differentiate two queries that might
   * have the same parameters but are otherwise not the same. For instance,
   * they might have different success types.
   */
  abstract readonly type: string;

  /**
   * This is the success type of the `Query`. We are utilizing a phantom
   * type to attach the type information to the `Query` but this will
   * not be available at runtime.
   */
  readonly [ShapeOfSuccessURI]!: () => Succ;

  /**
   * This is the failure type of the `Query`. We are utilizing a phantom
   * type to attach the type information to the `Query` but this will
   * not be available at runtime.
   *
   * This is a function because we want to preserve the covariance of `E`.
   * Basically, when we combine queries, we want the error types to be
   * combined with `|` to create a union of errors.
   */
  readonly [ShapeOfErrorURI]!: () => Err;

  /**
   * store the hash privately so we only calculate it once per Query
   */
  #hash?: number;

  /**
   * Hash the `Query`
   */
  get [H.HashURI](): number {
    if (this.#hash) {
      return this.#hash;
    }
    let h = H.hashString(this.type);

    Object.values(this._args).forEach((value) => {
      h = H.combineHash(h, H.hash(value));
    });

    this.#hash = h;
    return h;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyQuery = Query<any, any, any>;
