import { provide, inject, computed, ComputedRef } from '@vue/composition-api';
import { Store } from 'vuex';

/**
 * This is the symbol used for injecting the vuex store to child components.
 */
export const StoreSymbol = Symbol.for('@ax/console/store');

/**
 * Provide the store to all child components, should be
 * utilized in App.vue or some other root component
 * @param store `Store<A>`
 */
export function provideStore<A = unknown>(store: Store<A>) {
  provide(StoreSymbol, store);
}

/**
 * use to get access to the store without accessing `context`
 * @returns `Store<A>`
 */
export function useStore<A = unknown>() {
  const store = inject<Store<A>>(StoreSymbol);
  /**
   * The store should always be provided, but in case someone deletes it in App.vue ,
   * an error will be thrown
   */
  if (store === undefined) {
    throw new Error('The store must be provided in a parent component');
  }
  return store;
}

/**
 * A helper to extract `getters` out of a store
 * @param getter the `getter` to extract out of the store
 * @returns `ComputedRef<A>`
 */
export function useGetter<A = unknown>(getter: string): ComputedRef<A> {
  const store = useStore();

  return computed(() => store.getters[getter]);
}
