import { stringifyUrlQuery } from '@/utils/util';
import { Agents } from '@/models/agents';
import httpClient from './http-client';

const AGENTS_ENDPOINT = '/info';

export function getAgents(orgId: string | number): Promise<Agents> {
  return httpClient
    .get(`${AGENTS_ENDPOINT}?${stringifyUrlQuery({ o: orgId, internal: 1 })}`)
    .then(({ data }) => data);
}
