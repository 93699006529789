import { render, staticRenderFns } from "./Combobox.vue?vue&type=template&id=f332dc0c&scoped=true&"
import script from "./Combobox.vue?vue&type=script&lang=ts&"
export * from "./Combobox.vue?vue&type=script&lang=ts&"
import style0 from "./Combobox.vue?vue&type=style&index=0&id=f332dc0c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f332dc0c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCombobox } from 'vuetify/lib/components/VCombobox';
installComponents(component, {VCombobox})
