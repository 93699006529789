import {
  Response,
  EnforceNonEmptyRecord,
  success,
  failure,
  FunctionN,
  AsyncFunctionN,
} from '@ax/type-utils';
import { constVoid } from './constant';
import { tap } from './tap';

/**
 * A hook that will be called on a recurring interval while a
 * request is in flight.
 */
export interface IntervalHook {
  readonly interval: number;
  readonly hook: () => void;
}

/**
 * A hook that will be called at most once at the specified timeout.
 */
export interface TimeoutHook {
  readonly timeout: number;
  readonly hook: () => void;
}

/**
 * The available hooks to attach to a request.
 */
export interface Hooks<Args extends ReadonlyArray<unknown>, A, E> {
  /**
   * Will be called at the start of each invocation
   */

  readonly onStart?: FunctionN<Args, void>;

  /**
   * Will be called when a successful response is received
   */
  readonly onSuccess?: (val: A, args: Args) => void;

  /**
   * Will be called when a failure response is received
   */
  readonly onFailure?: (err: E, args: Args) => void;

  /**
   * Will be called when any response is received
   */
  readonly onResponse?: (response: Response<A, E>, args: Args) => void;

  /**
   * Hooks to be repeatedly called on the specified intervals
   */
  readonly onIntervals?: readonly IntervalHook[];

  /**
   * Hooks to be called at most once at the specified timeouts
   */
  readonly onTimeouts?: readonly TimeoutHook[];
}

/**
 * Setup all the intervals specified and return the `Timeout`s to
 * be cleared once the function returns
 */
function createIntervalHooks(hooks: readonly IntervalHook[]) {
  return hooks.map((intervalHook) =>
    setInterval(intervalHook.hook, intervalHook.interval),
  );
}

/**
 * Setup all the timeout hooks returning the `Timeout`s to be cleared
 * once the function returns
 */
function createTimeoutHooks(hooks: readonly TimeoutHook[]) {
  return hooks.map((intervalHook) =>
    setTimeout(intervalHook.hook, intervalHook.timeout),
  );
}

/**
 * Attach hooks to a function so you can peek into various stages of
 * its execution. Since this is `unsafe`, the errors are thrown instead
 * of returned.
 * @param fn the function to attach hooks to
 * @param hooks the hooks to attach to the function
 * @returns a `fn` that accepts the same args and returns the same value as `fn`
 */
export function withHooksUnsafe<
  Args extends ReadonlyArray<unknown>,
  A,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  H extends Hooks<Args, A, any>,
>(
  fn: AsyncFunctionN<Args, A>,
  hooks: EnforceNonEmptyRecord<H>,
): AsyncFunctionN<Args, A> {
  const {
    onStart = constVoid,
    onSuccess = constVoid,
    onFailure = constVoid,
    onResponse = constVoid,
    onIntervals = [],
    onTimeouts = [],
  } = hooks;

  return (...args) => {
    const intervalTimers = createIntervalHooks(onIntervals);
    const timeoutTimers = createTimeoutHooks(onTimeouts);
    onStart(...args);
    return fn(...args)
      .then(
        tap((response) => {
          onResponse(success(response), args);
          onSuccess(response, args);
        }),
      )
      .catch((e) => {
        onFailure(e, args);
        onResponse(failure(e), args);
        throw e;
      })
      .finally(() => {
        intervalTimers.forEach(clearInterval);
        timeoutTimers.forEach(clearTimeout);
      });
  };
}

/**
 * Attach hooks to a function so you can peek into various stages of
 * its execution.
 * @param fn the function to attach hooks to
 * @param hooks the hooks to attach to the function
 * @returns a `fn` that accepts the same args and returns the same value as `fn`
 */
export function withHooks<
  Args extends ReadonlyArray<unknown>,
  A,
  E,
  H extends Hooks<Args, A, E>,
>(
  fn: AsyncFunctionN<Args, Response<A, E>>,
  hooks: EnforceNonEmptyRecord<H>,
): AsyncFunctionN<Args, Response<A, E>> {
  const {
    onStart = constVoid,
    onSuccess = constVoid,
    onFailure = constVoid,
    onResponse = constVoid,
    onIntervals = [],
    onTimeouts = [],
  } = hooks;

  return (...args) => {
    const intervalTimers = createIntervalHooks(onIntervals);
    const timeoutTimers = createTimeoutHooks(onTimeouts);
    onStart(...args);
    return fn(...args).then(
      tap((response) => {
        onResponse(response, args);
        if (response.type === 'Success') {
          onSuccess(response.success, args);
        } else {
          onFailure(response.error, args);
        }
        intervalTimers.forEach(clearInterval);
        timeoutTimers.forEach(clearTimeout);
      }),
    );
  };
}
