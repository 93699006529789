import { Lazy } from './lazy';

export type MaybeLazy<A> = A | Lazy<A>;

function isLazy<A>(val: MaybeLazy<A>): val is Lazy<A> {
  return typeof val === 'function';
}

/**
 * use to unwrap a `MaybeLazy<A>` into an `A`
 * @param a `A | Lazy<A>`
 * @returns `A`
 */
export function unwrapMaybeLazy<A>(a: MaybeLazy<A>): A {
  return isLazy(a) ? a() : a;
}
