import { AccountUUID } from '@ax/api-clients-accounts/models';
import { Query, UnknownFetchError } from '@ax/cache-and-dedupe-vue';
import { UnknownRecord } from '@ax/object-utils';
import {
  ApiClientError,
  AxiosResponseError,
} from '@ax/api-clients-common/models';
import { failure, success } from '@ax/type-utils';
import { AxiosResponse } from 'axios';

/**
 * Extend this class to create a query that has `AxiosError` as the
 * default error type.
 */
export abstract class AxiosQuery<
  Params extends UnknownRecord,
  Succ,
  Err = ApiClientError<AxiosResponseError>,
> extends Query<Params, Succ, Err> {}

/**
 * Extend this class to create a query that uses an `accountId` and
 * has `AxiosError` as the default error type.
 */
export abstract class QueryByAccountId<
  Succ,
  Err = ApiClientError<AxiosResponseError>,
> extends AxiosQuery<{ readonly accountId: AccountUUID }, Succ, Err> {}

/**
 * Extend this class to create a query that uses an `orgId` and has
 * `AxiosError` as the default error type.
 */
export abstract class QueryByOrgId<
  Succ,
  Err = ApiClientError<AxiosResponseError>,
> extends AxiosQuery<{ readonly orgId: number }, Succ, Err> {}

/**
 * Extend this class to create a query that uses a `zoneId` and has
 * `AxiosError` as the default error type.
 */
export abstract class QueryByZoneId<
  Succ,
  Err = ApiClientError<AxiosResponseError>,
> extends AxiosQuery<{ readonly zoneId: number }, Succ, Err> {}

/**
 * Extend this class to create a query that utilizes a query string as its
 * only parameter and has `AxiosError` as the default error type.
 */
export abstract class QueryByString<
  Succ,
  Err = ApiClientError<AxiosResponseError>,
> extends AxiosQuery<{ readonly query: string }, Succ, Err> {}

/**
 * This is a default function to use to catch errors thrown during an
 * axios request.
 * @param error the error to be caught in a `.catch(...)` statement
 * @returns `Failure<ApiClientError<AxiosResponseError> | UnknownFetchError>`
 */
export function defaultCatchHandler(error: AxiosResponseError | undefined) {
  return failure(
    error === undefined
      ? new UnknownFetchError(error)
      : new ApiClientError(error),
  );
}

/**
 * Extract the `data` out of a `AxiosResponse<A>` and wrap it in a `Success`
 * @param response `AxiosResponse<A>`
 * @returns `Success<A>`
 */
export function responseToSuccess<A>(response: AxiosResponse<A>) {
  return success(response.data);
}
