import { AxiosResponse } from 'axios';

import { OrganizationPrefs } from '@/models/organization';
import { getOrgPrefs, postOrgPrefs } from '@/services/organization.service';
import { RESET } from '@/store/actions';

const REQUEST_PREFS = 'request_prefs';
const RECEIVED_PREFS = 'received_prefs';
const UPDATE_PREFS = 'update_prefs';
const UPDATED_PREFS = 'updated_prefs';
const ERROR = 'error';

const initialState = () => ({
  preferences: null,
  status: 0,
});

const getters = {
  getPreferences: (state): OrganizationPrefs => state.preferences,
  isLoading: (state): boolean => state.status > 0,
};

const actions = {
  [REQUEST_PREFS]: (
    { commit },
    orgId: string,
  ): Promise<AxiosResponse | OrganizationPrefs> => {
    commit(REQUEST_PREFS);

    return getOrgPrefs(orgId)
      .then((response) => {
        commit(RECEIVED_PREFS, response);

        return response;
      })
      .catch((error) => {
        commit(ERROR, error);

        return Promise.reject(error);
      });
  },
  [UPDATE_PREFS]: (
    { commit },
    { orgId, prefs }: { orgId: string | number; prefs: OrganizationPrefs },
  ): Promise<AxiosResponse> => {
    commit(UPDATE_PREFS);

    return postOrgPrefs(orgId, prefs)
      .then((response) => {
        commit(UPDATED_PREFS, prefs);

        return response;
      })
      .catch((error) => {
        commit(ERROR, error);

        return Promise.reject(error);
      });
  },
};

const mutations = {
  [REQUEST_PREFS]: (state) => {
    state.status += 1;
  },
  [RECEIVED_PREFS]: (state, preferences: OrganizationPrefs) => {
    state.preferences = preferences;
    state.status -= 1;
  },
  [UPDATE_PREFS]: (state) => {
    state.status += 1;
  },
  [UPDATED_PREFS]: (state, preferences: OrganizationPrefs) => {
    state.preferences = preferences;
    state.status -= 1;
  },
  [ERROR]: (state, error) => {
    state.error = error;
    state.status -= 1;
  },
  [RESET]: (state) => {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations,
};
