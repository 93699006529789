import { NavigationGuardNext, Route, RouteConfig } from 'vue-router';
import { consoleI18n } from '@ax/console-i18n';
import { FlagKeys } from '@/models/feature-flags';
import { guardRouteWithFlag } from '../router-utilities';

function guardWithReportsFallback(
  to: Route,
  next: NavigationGuardNext<Vue>,
  flag: FlagKeys,
) {
  guardRouteWithFlag(to, next, flag, {
    path: '/reports',
    query: { o: to.query.o },
  });
}

export const policyResultsRoutes: RouteConfig[] = [
  {
    path: '/reports/policy-results',
    name: 'policyResults',
    beforeEnter: (to, _, next) => {
      guardWithReportsFallback(to, next, FlagKeys.viewPolicyResultsPage);
    },
    component: () => import('@/views/Reports/PolicyResults/PolicyResults.vue'),
    meta: {
      layout: 'app',
      title: consoleI18n.t('routeTitles.policyResults'),
      topLevelParent: 'Reports',
    },
  },
  {
    path: '/reports/policy-results/:policyUuid',
    name: 'policyResultDetails',
    beforeEnter: (to, _, next) => {
      guardWithReportsFallback(to, next, FlagKeys.viewPolicyResultsPage);
    },
    component: () =>
      import('@/views/Reports/PolicyResults/PolicyResultDetails/PolicyRun.vue'),
    meta: {
      layout: 'app',
      topLevelParent: 'Reports',
    },
    children: [
      {
        path: '/reports/policy-results/:policyUuid',
        name: 'policyRunResultTab',
        component: () =>
          import(
            '@/views/Reports/PolicyResults/PolicyResultDetails/PolicyRunResultTab.vue'
          ),
        meta: {
          layout: 'app',
          title: consoleI18n.t('routeTitles.policyRunResults'),
        },
      },
      {
        path: '/reports/policy-results/:policyUuid/activity-log',
        name: 'policyRunActivityLogTab',
        component: () =>
          import(
            '@/views/Reports/PolicyResults/PolicyResultDetails/PolicyRunActivityLogTab.vue'
          ),
        meta: {
          layout: 'app',
          title: consoleI18n.t('routeTitles.policyResultDetailsActivityLog'),
        },
      },
    ],
  },
];
