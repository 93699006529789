import { AxiosResponse, AxiosPromise } from 'axios';
import { consoleEnv } from '@ax/console-env';
import { User, UserPrefs } from '@ax/data-services-user/models/user';
import { stringifyUrlQuery } from '@/utils/util';

import httpClient from './http-client';

export interface InviteUserRequest {
  email: string;
  rbac_role: string;
  o: string | number;
}

interface InviteUserResponse {
  email: string;
  invite_token: string;
  inviter_firstname: string;
  inviter_lastname: string;
  org_name: string;
}

const USER_ENDPOINT = '/users/self';

export const getIsAuthenticated = (): Promise<boolean> => {
  return httpClient
    .get(`/users/is-authenticated`)
    .then(() => true)
    .catch((error) => {
      if (error?.status === 401) {
        return false;
      }
      return Promise.reject(error);
    });
};

export const getUser = (): Promise<User> => {
  return httpClient.get(USER_ENDPOINT).then((response) => response.data);
};

export const updateUser = (
  orgId: string | number,
  user: User,
): Promise<AxiosResponse> => {
  return httpClient.put(
    `/users/${user.id}?${stringifyUrlQuery({ o: orgId, internal: 1 })}`,
    user,
  );
};

export const deleteUser = (
  orgId: string | number,
  userId: string | number,
): Promise<AxiosResponse> => {
  return httpClient.delete(
    `/users/${userId}?${stringifyUrlQuery({ o: orgId, internal: 1 })}`,
  );
};

export const getUserPrefs = (orgId: string | number): Promise<UserPrefs> => {
  return httpClient
    .get(
      `${USER_ENDPOINT}/prefs?${stringifyUrlQuery({ o: orgId, internal: 1 })}`,
    )
    .then((response) => response.data);
};

export const postUserPrefs = (
  orgId: string | number,
  payload: UserPrefs,
): Promise<AxiosResponse> => {
  return httpClient.post(
    `${USER_ENDPOINT}/prefs?${stringifyUrlQuery({ o: orgId, internal: 1 })}`,
    payload,
  );
};

export const getUsers = (orgId: string | number): Promise<User[]> => {
  return httpClient
    .get(`/users?${stringifyUrlQuery({ o: orgId, internal: 1 })}`)
    .then((response) => response.data);
};

export const unlockUserAccount = (
  orgId: string | number,
  userid: string | number,
): Promise<AxiosPromise> => {
  return httpClient
    .post(`/users/unlockaccount?o=${orgId}`, { userid })
    .then((response) => response.data);
};

export const inviteUser = (
  payload: InviteUserRequest,
): Promise<InviteUserResponse> => {
  return httpClient({
    method: 'POST',
    baseURL: consoleEnv.BASE_URL,
    url: '/inviteUser',
    headers: {
      'Content-Type': 'application/json',
    },
    data: payload,
  }).then((response) => response.data);
};
