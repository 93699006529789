import { computed } from '@vue/composition-api';
import { createSharedComposable } from '@vueuse/core';
import { useRoute } from './use-route';

function useRouteQueryState() {
  const route = useRoute();

  return computed(() => route.query);
}

/**
 * Use to get a `Ref<QueryParams>` for the current route.
 */
export const useRouteQuery = createSharedComposable(useRouteQueryState);
