/**
 * importing `vue-notification` augments the `vue/types/vue` module
 * so that `notify` is correctly added to the `Vue` constructor
 */
import 'vue-notification';
import Vue from 'vue';
import { AxiosResponseError } from '@ax/api-clients-common/models';
import { parseHttpErrors } from '@ax/api-clients-common';
import { consoleI18n } from '@ax/console-i18n';

export function showSuccessNotification(message: string) {
  Vue.notify({
    group: 'snackbar',
    text: message,
    type: '{ "card": "success" }',
  });
}

export function showSuccessNotificationLazy(message: string) {
  return () => showSuccessNotification(message);
}

export function showWarningNotification(message: string, duration?: number) {
  Vue.notify({
    group: 'snackbar',
    text: message,
    type: '{ "card": "warning" }',
    ...(duration ? { duration } : {}),
  });
}

export function showWarningNotificationLazy(
  message: string,
  duration?: number,
) {
  return () => showWarningNotification(message, duration);
}

export function showErrorNotification(
  text: string = consoleI18n.t('general.notifications.genericError'),
) {
  Vue.notify({
    group: 'snackbar',
    text,
    type: '{ "card": "error" }',
  });
}

export function showStandardHttpErrorNotification(
  error?: AxiosResponseError,
): void {
  showErrorNotification(
    parseHttpErrors(
      consoleI18n.t('general.notifications.genericError'),
      consoleI18n.t('general.notifications.errorPrefix'),
      error,
    ),
  );
}

export function clearNotifications() {
  Vue.notify({
    group: 'snackbar',
    clean: true,
  });
}
