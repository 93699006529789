import { computed, Ref, watch } from '@vue/composition-api';
import { updateWhen } from '@ax/vue-utils/ref';
import { QueryStatusErrorFromQuery, QueryStatusFromQuery } from '../core';
import { AnyQuery, ShapeOfSuccess } from '../query';

export interface ReactiveQueryResponse<A extends AnyQuery>
  extends PromiseLike<QueryStatusFromQuery<A>> {
  /**
   * The current status of the query `A`
   */
  readonly status: Readonly<Ref<QueryStatusFromQuery<A>>>;

  /**
   * A `Ref<boolean>` indicating the query is in a loading state
   */
  readonly isLoading: Readonly<Ref<boolean>>;

  /**
   * A `Ref<boolean>` indicating the query is in a failure state and
   * has errors
   */
  readonly isFailure: Readonly<Ref<boolean>>;

  /**
   * A ref of the query's error
   */
  readonly error: Readonly<Ref<QueryStatusErrorFromQuery<A> | undefined>>;

  /**
   * A `Ref<boolean>` indicating the query is in a refreshing state
   */
  readonly isRefreshing: Readonly<Ref<boolean>>;

  /**
   * A `Ref<boolean>` indicating the query is in a success state
   */
  readonly isSuccess: Readonly<Ref<boolean>>;

  /**
   * A `Ref` of the data for the query. It is either the success type of the query
   * or undefined
   */
  readonly data: Readonly<Ref<ShapeOfSuccess<A> | undefined>>;

  /**
   * A `Ref<Date>` of when the status of the query was last updated
   */
  readonly lastUpdated: Readonly<Ref<Date>>;
}

function isNotEqual<A>(newValue: A, currentValue: A | undefined) {
  return newValue !== currentValue;
}

const updateWhenIsNotEqual = updateWhen(isNotEqual);

/**
 * This is a utility function to convert a `Ref<QueryStatus>` into
 * a `ReactiveQueryResponse<A>`
 * @param status `Ref<QueryStatusFromQuery<A>>`
 * @returns `ReactiveQueryResponse<A>`
 */
export function makeReactiveQueryResponse<A extends AnyQuery>(
  status: Ref<QueryStatusFromQuery<A>>,
): ReactiveQueryResponse<A> {
  const listeners = new Set<(status: QueryStatusFromQuery<A>) => void>();

  watch(status, (newStatus) => {
    /**
     * We want to put the resolvers into a new micro task
     * so that they resolve after vue runs all its effects.
     */
    Promise.resolve().then(() => {
      listeners.forEach((f) => f(newStatus));
      listeners.clear();
    });
  });

  return {
    status,
    data: updateWhenIsNotEqual(computed(() => status.value.data)),
    isLoading: computed(() => status.value.type === 'Loading'),
    isFailure: computed(() => status.value.type === 'Failure'),
    isSuccess: computed(() => status.value.type === 'Success'),
    error: updateWhenIsNotEqual(
      computed(() => {
        const currentStatus = status.value;
        return currentStatus.type === 'Failure'
          ? currentStatus.error
          : undefined;
      }),
    ),
    isRefreshing: computed(() => status.value.type === 'Refreshing'),
    lastUpdated: computed(() => new Date(status.value.createdAt)),
    /**
     * This is largely used for testing purposes and makes it easy to await new
     * statuses
     */
    then: <T>(
      onNewStatus: (status: QueryStatusFromQuery<A>) => T | PromiseLike<T>,
    ) =>
      new Promise<T>((resolve) => {
        listeners.add((newStatus) => {
          resolve(onNewStatus(newStatus));
        });
      }),
  };
}
