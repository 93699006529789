import { Worklet, WorkletsResponse } from '@/models/worklet';
import httpClient from './http-client';

const WORKLETS_ENDPOINT = '/worklet-catalog';

export function getWorklets(query: string): Promise<WorkletsResponse> {
  return httpClient.get(`${WORKLETS_ENDPOINT}?${query}`).then((response) => {
    return new WorkletsResponse(response.data.results, response.data.size);
  });
}

export function getWorklet(workletId: string | number): Promise<Worklet> {
  const url = `${WORKLETS_ENDPOINT}/${workletId}`;
  return httpClient.get(url).then((response) => {
    return response.data;
  });
}
