import { QueryStatusFromQuery, isSuccess, isRefreshing } from '../core';
import { AnyQuery } from '../query';

export interface QueryModifier<A extends AnyQuery> {
  (query: A, status: QueryStatusFromQuery<A>): A;
}

/**
 * will modify the query so that `showLoader` is false when there is data in the
 * cache which is guaranteed to be the case when it is in a `Success` or `Refreshing`
 * state.
 * @param query the query to potentially modify
 * @param status the current status of the query
 * @returns query
 */
export function disableLoaderWhenCacheHasData<A extends AnyQuery>(
  query: A,
  status: QueryStatusFromQuery<A>,
): A {
  if (Object.hasOwnProperty.call(query, 'showLoader')) {
    return query;
  }
  return isSuccess(status) || isRefreshing(status)
    ? query.copy({ showLoader: false })
    : query;
}
