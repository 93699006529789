import { computed, watch, Ref, SetupContext } from '@vue/composition-api';
import { AxiosPromise } from 'axios';

import { Organization } from '@ax/data-services-zone/models/organization';
import { User, UserPrefs } from '@ax/data-services-user/models/user';
import i18n from '@/i18n';
import { AppStore } from '@/store';
import {
  USER_REQUEST_IF_STALE,
  USER_REQUEST,
  USER_UPDATE,
  USER_REQUEST_PREFS,
  USER_UPDATE_PREFS,
  USER_REQUEST_ALL,
  USER_UPDATE_OTHER,
  USER_REMOVE_OTHER,
  USER_UNLOCK_OTHER,
  USER_IS_LOADING,
  USER_IS_LOADING_PREFS,
  USER_GET_PROFILE,
  USER_GET_PREFERENCES,
  USER_GET_ALL,
} from '@/store/actions/user';
import { orderByAlpha, orderObjectsByAlpha } from '@/utils/util';

let currentUser: Ref<User>;

let leanCurrentUser: User | null = null;
let userPromise: Promise<User> | null = null;

function fetchUser(orgId?: string, forceFresh = false): Promise<User> {
  if (forceFresh) {
    leanCurrentUser = null;
    userPromise = null;
  }
  if (leanCurrentUser) {
    return Promise.resolve(leanCurrentUser);
  }
  if (!userPromise) {
    userPromise = AppStore.dispatch(
      forceFresh ? USER_REQUEST : USER_REQUEST_IF_STALE,
      orgId,
    );
  }
  userPromise.then((user) => {
    leanCurrentUser = user;
  });
  return userPromise;
}

/**
 * Use this function to get the current user.  It should only make an HTTP request if the user
 * has not been fetched yet or if the user has been intentionally unset in memory.
 * @param dispatch
 * @param orgId
 * @param forceFresh
 * @returns {Promise<User>}
 */
export function getCurrentUser(
  orgId?: string,
  forceFresh = false,
): Promise<User> {
  return fetchUser(orgId, forceFresh);
}

export function useUser(context: SetupContext) {
  const route = computed(() => context.root.$route);
  const loading = computed<boolean>(
    () => context.root.$store.getters[USER_IS_LOADING],
  );
  const user = computed<User>(
    () => context.root.$store.getters[USER_GET_PROFILE],
  );
  const fullName = computed<string>(() => {
    if (user.value) {
      const { firstname = '', lastname = '' } = user.value;

      return `${firstname} ${lastname}`.trim();
    }

    return '';
  });
  const organizations = computed<Organization[]>(() =>
    user.value ? orderObjectsByAlpha([...user.value.orgs!], 'name') : [],
  );

  const tags = computed<string[]>(() =>
    user.value && user.value.tags ? orderByAlpha([...user.value.tags]) : [],
  );
  currentUser = computed(() => user.value);

  watch(
    route,
    (next, prev) => {
      const nextOrgId = next.query.org || next.query.o;
      const prevOrgId = prev && (prev.query.org || prev.query.o);
      if (
        nextOrgId &&
        nextOrgId !== prevOrgId &&
        !user.value &&
        !loading.value
      ) {
        userPromise = null;
        fetchUser(nextOrgId as string, true);
      }
    },
    {
      immediate: true,
    },
  );

  function updateUser(form): Promise<AxiosPromise> {
    const orgId = route.value.query.org || route.value.query.o;
    if (orgId) {
      const {
        firstName,
        lastName,
        email,
        oldPassword,
        newPassword,
        repeatPassword,
      } = form;
      const nextUser = {
        ...user.value,
        firstname: firstName,
        lastname: lastName,
        email,
        password: oldPassword || null,
        password1: newPassword || null,
        password2: repeatPassword || null,
      };

      return context.root.$store.dispatch(USER_UPDATE, {
        orgId,
        user: nextUser,
      });
    }

    return Promise.reject(new Error(i18n.ts('org.provideError')));
  }

  return {
    loading,
    user,
    fullName,
    organizations,
    tags,
    updateUser,
  };
}

export function useUserPrefs(context: SetupContext) {
  const route = computed(() => context.root.$route);
  const loading = computed<boolean>(
    () => context.root.$store.getters[USER_IS_LOADING_PREFS],
  );
  const userPreferences = computed<UserPrefs>(
    () => context.root.$store.getters[USER_GET_PREFERENCES],
  );

  watch(
    route,
    (next, prev) => {
      const nextOrgId = next.query.org || next.query.o;
      const prevOrgId = prev && (prev.query.org || prev.query.o);
      if (nextOrgId && nextOrgId !== prevOrgId) {
        fetchPreferences(nextOrgId as string);
      }
    },
    {
      immediate: true,
    },
  );

  function fetchPreferences(orgId: string) {
    if (!userPreferences.value) {
      context.root.$store.dispatch(USER_REQUEST_PREFS, orgId);
    }
  }

  function updateUserPreferences(nextPrefs: UserPrefs): Promise<AxiosPromise> {
    const orgId = route.value.query.org || route.value.query.o;
    if (orgId) {
      const prefs = { ...userPreferences.value, ...nextPrefs };

      return context.root.$store.dispatch(USER_UPDATE_PREFS, { orgId, prefs });
    }

    return Promise.reject(new Error(i18n.ts('org.provideError')));
  }

  return { loading, userPreferences, updateUserPreferences };
}

export function useUsers(context: SetupContext) {
  const route = computed(() => context.root.$route);
  const loading = computed<boolean>(
    () => context.root.$store.getters[USER_IS_LOADING_PREFS],
  );
  const allUsers = computed<User[]>(
    () => context.root.$store.getters[USER_GET_ALL],
  );
  const users = computed<User[]>(() => {
    if (allUsers.value && currentUser.value) {
      return allUsers.value.filter((user) => user.id !== currentUser.value.id);
    }

    return [];
  });

  watch(
    route,
    (next, prev) => {
      const nextOrgId = next.query.org || next.query.o;
      const prevOrgId = prev && (prev.query.org || prev.query.o);
      if (nextOrgId && nextOrgId !== prevOrgId) {
        fetchUsers(nextOrgId as string);
      }
    },
    {
      immediate: true,
    },
  );

  function fetchUsers(orgId: string) {
    context.root.$store.dispatch(USER_REQUEST_ALL, orgId);
  }

  function updateUser(user: User): Promise<AxiosPromise> {
    const orgId = route.value.query.org || route.value.query.o;
    if (orgId) {
      return context.root.$store.dispatch(USER_UPDATE_OTHER, { orgId, user });
    }

    return Promise.reject(new Error(i18n.ts('org.provideError')));
  }

  function unlockUser(userId: string | number): Promise<AxiosPromise> {
    const orgId = route.value.query.org || route.value.query.o;
    if (orgId) {
      return context.root.$store.dispatch(USER_UNLOCK_OTHER, { orgId, userId });
    }

    return Promise.reject(new Error(i18n.ts('org.provideError')));
  }

  return { loading, allUsers, users, updateUser, unlockUser, fetchUsers };
}

export function deleteUsers(
  context: SetupContext,
  orgId: string | number,
  users: User[],
): Promise<AxiosPromise[]> {
  const userIds = users.map((user): number => user.id!);
  const removeUserPromises = userIds.map((id) =>
    context.root.$store.dispatch(USER_REMOVE_OTHER, { orgId, userId: id }),
  );

  return Promise.all(removeUserPromises);
}
