import { Response, isSuccess, success } from '@ax/type-utils';

/**
 * Use map to transform a response with success `A` into a success `B`, otherwise
 * return the `Failure<E>`
 *
 * @param to a function that maps `A` to `B`
 * @returns a `Response<B, E>`
 */
export function map<A, B>(to: (input: A) => B) {
  return <E>(response: Response<A, E>): Response<B, E> =>
    isSuccess(response) ? success(to(response.success)) : response;
}
