import { RouteConfig } from 'vue-router';
import { consoleI18n } from '@ax/console-i18n';
import { FlagKeys } from '@/models/feature-flags';
import { guardRouteWithFlag } from '../router-utilities';

export const configurationsRoutes: RouteConfig[] = [
  {
    path: '/manage/get-started-mac-os',
    name: 'macOsGetStarted',
    beforeEnter: async (to, from, next) => {
      guardRouteWithFlag(to, next, FlagKeys.viewConfigurations, {
        path: '/manage',
        query: { o: to.query.o },
      });
    },
    component: () => import('@/views/Configurations/GetStarted.vue'),
    meta: {
      layout: 'app',
      title: consoleI18n.t('routeTitles.macOsGetStarted'),
    },
  },
];

export const macOsSettingsRoutes: RouteConfig[] = [
  {
    path: 'apns',
    name: 'apns-settings',
    beforeEnter: async (to, from, next) => {
      guardRouteWithFlag(to, next, FlagKeys.viewConfigurations, {
        path: '/settings',
        query: { o: to.query.o },
      });
    },
    component: () => import('@ax/mac-os/components/Settings/APNSTab.vue'),
    meta: {
      layout: 'app',
      title: consoleI18n.t('configurations.apnsSettings:phrase'),
    },
  },
];
