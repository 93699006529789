import { Predicate } from './predicate';

/**
 * Use `not_` and `not` to invert a `Predicate<A>` so that it returns `true` when
 * the `Predicate<A>`returns `false`.
 *
 * @example
 * import { isNullable } from '@ax/function-utils/nullable'
 * import { not, not_ } from '@ax/function-utils/not'
 * import { map_ } from '@ax/vue-utils/ref';
 *
 * const user = shallowRef<User>()
 *
 * const isNonNullable = not(isNullable);
 *
 * const isUserDefined = map_(user, isNonNullable);
 *
 * const isUserDefined = computed(() => not_(user.value, isNullable));
 *
 * @param value `A`
 * @param predicate `Predicate<A>`
 * @returns boolean
 */
export function not_<A>(value: A, predicate: Predicate<A>): boolean {
  return !predicate(value);
}

/**
 * Curried version of `not_`. You can also think of `not` as just inverting a `Predicate<A>`
 *
 * Use `not_` and `not` to invert a `Predicate<A>` so that it returns `true` when
 * the `Predicate<A>`returns `false`.
 *
 * @example
 * import { isNullable } from '@ax/function-utils/nullable'
 * import { not, not_ } from '@ax/function-utils/not'
 * import { map_ } from '@ax/vue-utils/ref';
 *
 * const user = shallowRef<User>()
 *
 * const isNonNullable = not(isNullable);
 *
 * const isUserDefined = map_(user, isNonNullable);
 *
 * const isUserDefined = computed(() => not_(user.value, isNullable));
 *
 * @param value `A`
 * @param predicate `Predicate<A>`
 * @returns boolean
 */
export function not<A>(predicate: Predicate<A>) {
  return (value: A) => not_(value, predicate);
}
