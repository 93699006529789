import { isSuccess, Response } from '@ax/type-utils';
import { QueryStatus, success, failure } from './definition';

/**
 * A utility to convert a `Response` into a `QueryStatus`
 * @param response `Response<Succ, Err>
 * @returns `QueryStatus<Succ, Err>`
 */
export function fromResponse<Succ, Err>(
  response: Response<Succ, Err>,
): QueryStatus<Succ, Err> {
  return isSuccess(response)
    ? success(response.success)
    : failure(response.error);
}
