enum PermissionType {
  Account = 'Account',
  AccountInvitation = 'Account Invitation',
  Approval = 'Approval',
  Billing = 'Billing',
  CustomPolicy = 'Custom Policy',
  Endpoint = 'Endpoint',
  Organization = 'Organization',
  Package = 'Package',
  PatchPolicy = 'Patch Policy',
  Report = 'Report',
  RequiredSoftwarePolicy = 'Required Software Policy',
  Role = 'Role',
  SAML = 'Saml',
  ServerGroup = 'Server Group',
  Software = 'Software',
  Task = 'Task',
  TFA = 'TFA',
  Users = 'Users',
}

enum PermissionActionName {
  Create = 'create',
  Delete = 'delete',
  Modify = 'modify',
  Execute = 'execute',
  Control = 'control',
  Read = 'read',
  Add = 'add',
  Manage = 'manage',
  Invite = 'invite',
}

const PatchPolicy = {
  Create: {
    name: PermissionActionName.Create,
    type: PermissionType.PatchPolicy,
  },
  Delete: {
    name: PermissionActionName.Delete,
    type: PermissionType.PatchPolicy,
  },
  Modify: {
    name: PermissionActionName.Modify,
    type: PermissionType.PatchPolicy,
  },
  Execute: {
    name: PermissionActionName.Execute,
    type: PermissionType.PatchPolicy,
  },
  Read: {
    name: PermissionActionName.Read,
    type: PermissionType.PatchPolicy,
  },
};

const CustomPolicy = {
  Create: {
    name: PermissionActionName.Create,
    type: PermissionType.CustomPolicy,
  },
  Delete: {
    name: PermissionActionName.Delete,
    type: PermissionType.CustomPolicy,
  },
  Modify: {
    name: PermissionActionName.Modify,
    type: PermissionType.CustomPolicy,
  },
  Execute: {
    name: PermissionActionName.Execute,
    type: PermissionType.CustomPolicy,
  },
  Read: {
    name: PermissionActionName.Read,
    type: PermissionType.CustomPolicy,
  },
};

const RequiredSoftwarePolicy = {
  Create: {
    name: PermissionActionName.Create,
    type: PermissionType.RequiredSoftwarePolicy,
  },
  Delete: {
    name: PermissionActionName.Delete,
    type: PermissionType.RequiredSoftwarePolicy,
  },
  Modify: {
    name: PermissionActionName.Modify,
    type: PermissionType.RequiredSoftwarePolicy,
  },
  Execute: {
    name: PermissionActionName.Execute,
    type: PermissionType.RequiredSoftwarePolicy,
  },
  Read: {
    name: PermissionActionName.Read,
    type: PermissionType.RequiredSoftwarePolicy,
  },
};

const Endpoint = {
  Add: {
    name: PermissionActionName.Add,
    type: PermissionType.Endpoint,
  },
  Delete: {
    name: PermissionActionName.Delete,
    type: PermissionType.Endpoint,
  },
  Manage: {
    name: PermissionActionName.Manage,
    type: PermissionType.Endpoint,
  },
  Read: {
    name: PermissionActionName.Read,
    type: PermissionType.Endpoint,
  },
  Control: {
    name: PermissionActionName.Control,
    type: PermissionType.Endpoint,
  },
};

const ServerGroup = {
  Create: {
    name: PermissionActionName.Create,
    type: PermissionType.ServerGroup,
  },
  Delete: {
    name: PermissionActionName.Delete,
    type: PermissionType.ServerGroup,
  },
  Modify: {
    name: PermissionActionName.Modify,
    type: PermissionType.ServerGroup,
  },
  Read: {
    name: PermissionActionName.Read,
    type: PermissionType.ServerGroup,
  },
};

const Report = {
  Read: {
    name: PermissionActionName.Read,
    type: PermissionType.Report,
  },
};

const Role = {
  Create: {
    name: PermissionActionName.Create,
    type: PermissionType.Role,
  },
  Delete: {
    name: PermissionActionName.Delete,
    type: PermissionType.Role,
  },
  Modify: {
    name: PermissionActionName.Modify,
    type: PermissionType.Role,
  },
  Read: {
    name: PermissionActionName.Read,
    type: PermissionType.Role,
  },
};

const Billing = {
  Modify: {
    name: PermissionActionName.Modify,
    type: PermissionType.Billing,
  },
  Read: {
    name: PermissionActionName.Read,
    type: PermissionType.Billing,
  },
};

const Users = {
  Invite: {
    name: PermissionActionName.Invite,
    type: PermissionType.Users,
  },
  Delete: {
    name: PermissionActionName.Delete,
    type: PermissionType.Users,
  },
  Modify: {
    name: PermissionActionName.Modify,
    type: PermissionType.Users,
  },
  Read: {
    name: PermissionActionName.Read,
    type: PermissionType.Users,
  },
};

const Organization = {
  Manage: {
    name: PermissionActionName.Manage,
    type: PermissionType.Organization,
  },
  Create: {
    name: PermissionActionName.Create,
    type: PermissionType.Organization,
  },
  Read: {
    name: PermissionActionName.Read,
    type: PermissionType.Organization,
  },
};

const Package = {
  Manage: {
    name: PermissionActionName.Manage,
    type: PermissionType.Package,
  },
  Read: {
    name: PermissionActionName.Read,
    type: PermissionType.Package,
  },
};

const Software = {
  Read: {
    name: PermissionActionName.Read,
    type: PermissionType.Software,
  },
};

const Approval = {
  Create: {
    name: PermissionActionName.Create,
    type: PermissionType.Approval,
  },
};

const SAML = {
  Read: {
    name: PermissionActionName.Read,
    type: PermissionType.SAML,
  },
  Manage: {
    name: PermissionActionName.Manage,
    type: PermissionType.SAML,
  },
};

const TFA = {
  Create: {
    name: PermissionActionName.Create,
    type: PermissionType.TFA,
  },
  Modify: {
    name: PermissionActionName.Modify,
    type: PermissionType.TFA,
  },
  Delete: {
    name: PermissionActionName.Delete,
    type: PermissionType.TFA,
  },
  Read: {
    name: PermissionActionName.Read,
    type: PermissionType.TFA,
  },
};

const Task = {
  Create: {
    name: PermissionActionName.Create,
    type: PermissionType.Task,
  },
  Modify: {
    name: PermissionActionName.Modify,
    type: PermissionType.Task,
  },
  Execute: {
    name: PermissionActionName.Execute,
    type: PermissionType.Task,
  },
  Read: {
    name: PermissionActionName.Read,
    type: PermissionType.Task,
  },
};

const Account = {
  Read: {
    name: PermissionActionName.Read,
    type: PermissionType.Account,
  },
  Modify: {
    name: PermissionActionName.Modify,
    type: PermissionType.Account,
  },
  Delete: {
    name: PermissionActionName.Delete,
    type: PermissionType.Account,
  },
};

const AccountInvitation = {
  Create: {
    name: PermissionActionName.Create,
    type: PermissionType.AccountInvitation,
  },
  Read: {
    name: PermissionActionName.Read,
    type: PermissionType.AccountInvitation,
  },
  Modify: {
    name: PermissionActionName.Modify,
    type: PermissionType.AccountInvitation,
  },
  Delete: {
    name: PermissionActionName.Delete,
    type: PermissionType.AccountInvitation,
  },
};

export interface MappedPermission {
  name: PermissionActionName;
  type: PermissionType;
}

export const PermissionMap = {
  PatchPolicy,
  CustomPolicy,
  RequiredSoftwarePolicy,
  Endpoint,
  ServerGroup,
  Report,
  Role,
  Billing,
  Users,
  Organization,
  Package,
  Software,
  Approval,
  SAML,
  TFA,
  Task,
  Account,
  AccountInvitation,
};
