import { Nullable } from '@ax/type-utils';
import { isNullable } from '../function-utils/nullable/is-nullable';

export function timespan(
  startTime: Nullable<Date>,
  stopTime: Nullable<Date>,
): string {
  if (isNullable(startTime) || isNullable(stopTime)) {
    return '0';
  }
  const timeDifference = stopTime.getTime() - startTime.getTime();
  return !Number.isNaN(new Date(timeDifference).getTime())
    ? new Date(timeDifference).toISOString().slice(11, 19)
    : '0';
}
