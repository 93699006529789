import {
  isRef,
  Ref,
  shallowRef,
  unref,
  watch,
  watchEffect,
} from '@vue/composition-api';
import { MaybeRef, tryOnScopeDispose } from '@vueuse/core';
import {
  constVoid,
  Hooks,
  isTimeoutError,
  timeout_,
  TimeoutError,
  withHooks,
} from '@ax/function-utils';
import { queryWithCacheEntry_ } from '@ax/cache-and-dedupe-core/operations';
import { hash } from '@ax/cache-and-dedupe-core/hash';
import { Nullable } from '@ax/type-utils';
import { isNullable } from '@ax/function-utils/nullable';
import {
  QueryResponse,
  QueryFetcher,
  QueryCache,
  QueryStatusCacheEntry,
  CacheAndNetwork,
  isSend,
  NetworkPolicy,
  QueryStatusErrorFromQuery,
  QueryStatusFromQuery,
  empty,
} from '../core';
import { AnyQuery, ShapeOfSuccess } from '../query';
import {
  makeReactiveQueryResponse,
  ReactiveQueryResponse,
} from './make-reactive-query-response';
import { disableLoaderWhenCacheHasData, QueryModifier } from './modify-query';

const DEFAULT_TIMEOUT = 60_000;

/**
 * Hooks for an instance of `Queryable<A>` so you can listen in whenever
 * requests are made or errors are received.
 */
export interface QueryableHooks<A extends AnyQuery>
  extends Hooks<[A], ShapeOfSuccess<A>, QueryStatusErrorFromQuery<A>> {
  readonly onTimeout?: (error: TimeoutError) => void;
}

export interface QueryableConfig<A extends AnyQuery> {
  /**
   * The cache with which queries will be stored. A default one
   * with size 10 will be created if one isn't provided
   */
  readonly cache?: QueryCache<A>;

  /**
   * The policy used to make decisions whether to `send` or `skip`
   * network requests. The default policy is `CacheAndNetwork` which
   * means requests will be sent if one is not already in flight.
   */
  readonly networkPolicy?: NetworkPolicy<A>;

  /**
   * The length of time in ms before a query is timed out. A default
   * timeout of 60_000 will be applied if none is provided.
   */
  readonly timeout?: number;

  /**
   * The error message to provide when a query times out
   */
  readonly timeoutMessage?: (query: A) => string;

  /**
   * Hooks for listening in on requests
   */
  readonly hooks?: QueryableHooks<A>;

  /**
   * Optionally modify the query before it is sent over the network.
   */
  readonly modifyQuery?: QueryModifier<A>;
}

export interface UseQueryOptions<A extends AnyQuery> {
  /**
   * Repeatedly submit the query
   */
  readonly pollInterval?: MaybeRef<number>;
  /**
   * Override the `NetworkPolicy` for this single request.
   */
  readonly networkPolicy?: NetworkPolicy<A>;
}

export interface Queryable<A extends AnyQuery> {
  /**
   * `useQuery` is for making declarative, reactive requests for data. You
   * pass in a `Ref<A | undefined> | A` and get back a `ReactiveQueryResponse<A>` which will
   * update whenever `Ref<A>` updates or when new network data is received.
   */
  readonly useQuery: (
    query: A | Ref<Nullable<A>>,
    options?: UseQueryOptions<A>,
  ) => ReactiveQueryResponse<A>;

  /**
   * `runQuery` will forcefully run a given query `A` bypassing any network policy
   * and won't return reactive data. This is useful when you want to resubmit a query
   * after a mutation in order to ensure read after write consistency or if you don't need
   * the reactive data returned from `use`. `runQuery` is still attached to the cache so it will
   * update the data inside it and keep all derived state in sync.
   */
  readonly runQuery: (query: A) => Promise<QueryResponse<A>>;

  /**
   * The underlying cache, essentially acts as a `Map<A, QueryStatus<A>>`.
   */
  readonly cache: QueryCache<A>;
}

function attachTimeoutToFetcher<A extends AnyQuery>(
  fetcher: QueryFetcher<A>,
  timeoutTime: number,
  message?: (query: A) => string,
) {
  return timeout_(
    fetcher,
    timeoutTime < 1 ? DEFAULT_TIMEOUT : timeoutTime,
    message,
  );
}

function attachHooksToFetcher<A extends AnyQuery>(
  fetcher: QueryFetcher<A>,
  hooks?: QueryableHooks<A>,
) {
  return hooks
    ? withHooks(fetcher, {
        ...hooks,
        onFailure: (error, args) => {
          if (hooks.onTimeout && isTimeoutError(error)) {
            hooks.onTimeout(error);
          }
          if (hooks.onFailure) {
            hooks.onFailure(error, args);
          }
        },
      })
    : fetcher;
}

function modifyFetcher<A extends AnyQuery>(
  fetcher: QueryFetcher<A>,
  timeoutTime: number,
  timeoutMessage?: (query: A) => string,
  hooks?: QueryableHooks<A>,
) {
  return attachHooksToFetcher(
    attachTimeoutToFetcher(fetcher, timeoutTime, timeoutMessage),
    hooks,
  );
}

/**
 * `makeQueryable` takes in a `QueryFetcher<A>` capable of handling queries
 * of type `A` and returns a `Queryable<A>`. `Queryable<A>` provides two functions: `useQuery`
 * and `runQuery` in addition to the underlying cache. `useQuery` is used for making declarative, reactive
 * requests for data. A consumer of `Queryable<A>` can call `useQuery` with a `Ref<A>` and get back
 * reactive data that can easily be used in the UI. `runQuery` is used to force run a query `A`. In
 * certain situations, you don't need the reactive helpers returned from `useQuery` or you need to
 * forcefully run a query after a mutation has been made which is what `runQuery` is for. `runQuery` is still
 * attached to the same underlying cache as `useQuery` so it will keep all derived reactive state in
 * sync.
 * @param fetcher `(query: A) => Promise<Response<ShapeOfSuccess<A>, ShapeOfError<A>>>`
 * @param config `QueryableConfig<A>`
 * @returns `Queryable<A>`
 */
export function makeQueryable<A extends AnyQuery>(
  fetcher: QueryFetcher<A>,
  config: QueryableConfig<A> = {},
): Queryable<A> {
  const {
    cache = new QueryCache({ size: 10 }),
    networkPolicy = CacheAndNetwork,
    timeout: timeoutTime = DEFAULT_TIMEOUT,
    timeoutMessage,
    hooks,
    modifyQuery = disableLoaderWhenCacheHasData,
  } = config;

  const fetchWithHooksAndTimeout = modifyFetcher(
    fetcher,
    timeoutTime < 1 ? DEFAULT_TIMEOUT : timeoutTime,
    timeoutMessage,
    hooks,
  );

  /**
   * Make the network request associated with the Query and update
   * the status of the associated ref.
   */
  function runQuery(query: A, entry = cache.getOrCreate(query)) {
    return queryWithCacheEntry_(
      modifyQuery(query, entry.status),
      fetchWithHooksAndTimeout,
      entry,
    );
  }

  function useQueryRef(query: Ref<Nullable<A>>, options: UseQueryOptions<A>) {
    let cacheEntry = isNullable(query.value)
      ? undefined
      : cache.getOrCreate(query.value);

    /**
     * This will be used to unsubscribe from cache entry changes, initially
     * set to a no-op function constVoid
     */
    let unsubscribe = constVoid;

    let timer: ReturnType<typeof setInterval>;

    const statusRef: Ref<QueryStatusFromQuery<A>> = shallowRef(
      cacheEntry ? cacheEntry.status : empty(),
    );

    const _networkPolicy = options.networkPolicy ?? networkPolicy;

    /**
     * The subscriber to attach to cache entry changes
     */
    function onNewStatus(newStatus: QueryStatusFromQuery<A>) {
      statusRef.value = newStatus;
    }

    /**
     * Handle undefined query
     */
    function onEmpty() {
      unsubscribe();
      unsubscribe = constVoid;
      statusRef.value = empty();
    }

    /**
     * Check network policy and potentially run query
     */
    function checkNetworkPolicyThenRun(
      currentQuery: A,
      status: QueryStatusFromQuery<A>,
      entry: QueryStatusCacheEntry<A>,
    ) {
      if (isSend(_networkPolicy(status, currentQuery))) {
        runQuery(currentQuery, entry);
      }
    }

    function clearOutstandingTimer() {
      if (timer) {
        clearInterval(timer);
      }
    }

    /**
     * Set up polling
     */
    watchEffect(
      () => {
        clearOutstandingTimer();
        const pollInterval = unref(options.pollInterval);
        if (pollInterval !== undefined && pollInterval > 0) {
          timer = setInterval(() => {
            const currentQuery = query.value;
            const currentEntry = cacheEntry;
            if (currentQuery && currentEntry) {
              checkNetworkPolicyThenRun(
                currentQuery,
                statusRef.value,
                currentEntry,
              );
            }
          }, pollInterval);
        }
      },
      {
        flush: 'sync',
      },
    );

    function onNewQuery(newQuery: A) {
      unsubscribe();
      cacheEntry = cache.getOrCreate(newQuery);
      statusRef.value = cacheEntry.status;
      unsubscribe = cacheEntry.subscribe(onNewStatus);
      checkNetworkPolicyThenRun(newQuery, cacheEntry.status, cacheEntry);
    }

    /**
     * Watch for changes in the query and run any potential side effects
     */
    watch(
      query,
      (newQuery, oldQuery) => {
        if (isNullable(newQuery)) {
          onEmpty();
        } else if (hash(newQuery) !== hash(oldQuery)) {
          onNewQuery(newQuery);
        }
      },
      {
        flush: 'sync',
        immediate: true,
      },
    );

    /**
     * We want to make sure we delete the subscriber function
     */
    tryOnScopeDispose(() => {
      unsubscribe();
      clearOutstandingTimer();
    });

    return makeReactiveQueryResponse(statusRef);
  }

  function useQuery(
    query: A | Ref<Nullable<A>>,
    options: UseQueryOptions<A> = {},
  ): ReactiveQueryResponse<A> {
    return useQueryRef(isRef(query) ? query : shallowRef(query), options);
  }

  return {
    useQuery,
    cache,
    runQuery,
  };
}
