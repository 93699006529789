/**
 * Extract the value present in key `K` from object `A`
 */
export function prop_<A, K extends keyof A>(self: A, key: K): A[K] {
  return self[key];
}

/**
 * Curried version of prop_
 * @param key The key of an object you want to extract
 *
 */
export function prop<K extends PropertyKey>(
  key: K,
): <A extends Record<K, unknown>>(self: A) => A[K];
export function prop<A, K extends keyof A>(key: K): (self: A) => A[K];
export function prop<K extends PropertyKey>(key: K) {
  return <A extends Record<K, unknown>>(self: A): A[K] => prop_(self, key);
}
