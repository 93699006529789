/**
 * This is NOT the recommended way to import this utils
 * This is done only in this file, so that we don't have conflicting names from imports/exports
 * This file can eventually removed, when none of these functions are used anymore,
 * and everyone is pulling in the workspace version
 * */
import * as workspaceStorageUtils from '@ax/web-storage-wrapper';

/**
 * @deprecated instead use -> import { StorageConfig } from '@ax/web-storage-wrapper';
 */
export type QueryStateStorageConfig = workspaceStorageUtils.StorageConfig;

/**
 * @deprecated instead use -> import { Storable } from '@ax/web-storage-wrapper';
 */
export type Storable = workspaceStorageUtils.Storable;

/**
 * @deprecated instead use -> import { setItemInWebStorage } from '@ax/web-storage-wrapper'; setItemInWebStorage(foo);
 */
export function setItemInStorage(
  state: workspaceStorageUtils.Storable,
  storageConfig: workspaceStorageUtils.StorageConfig,
  merge = false,
) {
  workspaceStorageUtils.setItemInWebStorage(state, storageConfig, merge);
}

/**
 * @deprecated instead use -> import { getItemInWebStorage } from '@ax/web-storage-wrapper'; getItemInWebStorage(foo);
 */
export function getItemInStorage(
  storageConfig: workspaceStorageUtils.StorageConfig,
) {
  return workspaceStorageUtils.getItemInWebStorage(storageConfig);
}
