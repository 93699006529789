import { prop_ } from './prop';

/**
 * Extract the value present in key `K` from object `T` and map it to a
 * value of `A` with function `map`
 */
export function propMap_<A, K extends keyof A, B>(
  self: A,
  key: K,
  map: (value: A[K]) => B,
): B {
  return map(prop_(self, key));
}

/**
 * curried version of propMap_
 *
 * @param key The key of an object you want to extract
 * @param map The function you want to apply to the extracted value
 *
 */
export function propMap<K extends PropertyKey, A, B>(
  key: K,
  map: (value: A) => B,
): <T extends Record<K, A>>(self: T) => B;
export function propMap<A, K extends keyof A, B>(
  key: K,
  f: (value: A[K]) => B,
): (self: A) => B;
export function propMap<K extends PropertyKey, A, B>(
  key: K,
  f: (value: A) => B,
) {
  return (self: Record<K, A>) => propMap_(self, key, f);
}
