import {
  configureFeatureFlag,
  FeatureFlagConfig,
  FeatureFlagUser,
} from '@ax/feature-flag';

import { User } from '@ax/data-services-user/models/user';
import { Organization } from '@ax/data-services-zone/models/organization';
import { getConfig } from '@ax/data-services-config';
import { AppStore } from '@/store';
import { USER_GET_PROFILE } from '@/store/actions/user';
import { PREFERENCES_GET_ORGANIZATION } from '@/store/actions/preferences';
import { flagConfigs } from '@/models/feature-flags';

function getFFUser(axUser?: User, org?: Organization): FeatureFlagUser {
  if (!axUser || !axUser.id) {
    return { anonymous: true };
  }
  return {
    key: String(axUser.id),
    firstName: axUser.firstname,
    lastName: axUser.lastname,
    email: axUser.email,
    custom: {
      organizations: axUser.orgs?.map(({ id }) => id) as Array<number>,
      ...(org?.id ? { currentOrganization: org.id } : {}),
      account: axUser.account_id || '',
      // NOTE: only support timestamps in UNIX epoch format (i.e. trial_end_time)
      trial_end_time: org?.trial_end_time
        ? new Date(org.trial_end_time).getTime()
        : '',
      account_created_at: axUser.account_created_at
        ? new Date(axUser.account_created_at).getTime()
        : '',
    },
  };
}

const config: FeatureFlagConfig = {
  async getClientId() {
    const config = await getConfig();
    return config.launch_darkly.client_side_id;
  },

  getUser() {
    const user = AppStore.getters[USER_GET_PROFILE] as User;
    const org = AppStore.getters[PREFERENCES_GET_ORGANIZATION] as Organization;
    return getFFUser(user, org);
  },

  watchUser(cb: (user: FeatureFlagUser, oldUser?: FeatureFlagUser) => void) {
    AppStore.watch<[User, Organization]>(
      () => [
        AppStore.getters[USER_GET_PROFILE],
        AppStore.getters[PREFERENCES_GET_ORGANIZATION],
      ],
      ([newUser, newOrg], [oldUser, oldOrg]) => {
        // The User and Org are updated in Vuex on every navigation.
        const newFFUser = getFFUser(newUser, newOrg);
        const oldFFUser = getFFUser(oldUser, oldOrg);
        cb(newFFUser, oldFFUser);
      },
    );
  },

  flags: flagConfigs,
};

configureFeatureFlag(config);
